import React, {useEffect,useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles, withStyles } from '@mui/styles';
import {Grid, Box, Card, CardContent, CardHeader,
    Radio ,RadioGroup , Typography,
    FormGroup , FormControlLabel , FormControl , FormLabel, ToggleButton, ToggleButtonGroup 
} from '@mui/material';
import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/core/ButtonUnstyled';
import { styled } from '@mui/system';
import { useHistory } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import GifLoader from 'react-gif-loader';
import loadingGif from '../assets/loading.gif';
import { useTranslation } from 'react-i18next';

const THEME = createTheme({
    typography: {
     "fontFamily": `"PromptMedium","PromptSemiBold", "PromptRegular","PromptBold"`
    },
    palette: {
        primary: {
          main: '#7f51f5',
        }
      },
 });

const useStyles = makeStyles({
    body : {
        marginTop: '-3%'
    },
    headerTitle : {
        fontWeight: 'bold',
    },
    appbar: {
        alignItems: 'center',
    },
    textColor: {
        fontSize: '16px',
        color: '#7F51F5'
    },
    size12 :{
        fontSize : '13px'
    },
    redText: {
        color: 'red'
    }
});

const currentURL = window.location.href;
var splitUrl;
var splitUrlToken;

if(currentURL.includes("projectKey=")){
    splitUrl = currentURL.split('projectKey=');
    
    var projectKeySplit = splitUrl[1].split('&');
    var projectKey = projectKeySplit[0];
    
    localStorage.setItem("projectKeyForm", projectKey);
    
}

if(currentURL.includes("userId=")){
    splitUrlToken = currentURL.split('userId=');
    var tokenSplit = splitUrlToken[1].split('&');
    var  token = tokenSplit[0];
    localStorage.setItem("userId", token);
}

if(currentURL.includes("projectType")){
    let splitUrlProjectType = currentURL.split('projectType=');
    var tokenSplit = splitUrlProjectType[1].split('&');
    var  projectType = tokenSplit[0];
    localStorage.setItem("project_type", projectType);
}else {
    localStorage.setItem("project_type", 'isolation');
}

if(currentURL.includes("language")){
    let splitUrlProjectType = currentURL.split('language=');
    var tokenSplit = splitUrlProjectType[1].split('&');
    var  language = tokenSplit[0];
    localStorage.setItem("language", language);
}
else {
    localStorage.setItem("language", 'th');
}

    if(currentURL.includes("redirect")){
        let splitUrlProjectType = currentURL.split('redirect=');
        // console.log("redirect:", splitUrlProjectType )
        // var tokenSplit = splitUrlProjectType[1].split('redirect=');
        var  redirect = splitUrlProjectType[1];
        localStorage.setItem("redirect", redirect);
        console.log(localStorage.getItem("redirect"));
    }

    let redirectURL ;
    if(localStorage.getItem("redirect"))
    {
        redirectURL = localStorage.getItem("redirect");
    }else{
        redirectURL = 'https://form.pmhsolution.com/daily?projectKey='+ localStorage.getItem("projectKeyForm") + '&userId=' + localStorage.getItem("userId"); 
    }

    localStorage.setItem("redirectUrl", redirectURL);
    console.log('redirect URL:', localStorage.getItem('redirectUrl'));

// if(currentURL.includes("user_status")){
//     let splitUrlProjectType = currentURL.split('user_status=');
//     console.log("user_status:", splitUrlProjectType )
//     var tokenSplit = splitUrlProjectType[1].split('&');
//     var  userStatus = tokenSplit[0];
//     localStorage.setItem("userStatus", userStatus);
//     console.log(localStorage.getItem("userStatus"));
// }

// if(currentURL.includes("?")){
//     let splitURL = currentURL.split('?');
//     console.log("url split:", splitURL );
//     var urlParam = splitURL[1];
//     let redirectURL ;
//     if(localStorage.getItem("userStatus")){
//         if(localStorage.getItem("userStatus") === 'admin_rpst')
//         {
//             redirectURL = 'https://isolation.pmhsolution.com/isolation/staff?' + urlParam;
//         }else if(localStorage.getItem("userStatus") === 'dashboard'){
//             redirectURL = 'https://app.pmhsolution.com';
//         }else{
//             redirectURL = ' https://isolation.pmhsolution.com/?domain=' + localStorage.getItem("domain") + 
//             '&project_key=' + localStorage.getItem("projectKeyForm") +
//             "&project_type=" + localStorage.getItem("project_type") + '&project_name=' + localStorage.getItem("projectName");
//         }
//         localStorage.setItem("redirectUrl", redirectURL);
        
//     } 
//     console.log('redirect URL mental health form:', localStorage.getItem('redirectUrl'));
// }

if(localStorage.getItem("project") === 'vibharam'){
    localStorage.setItem('language', 'th');

}

if(localStorage.getItem('projectKeyForm') === "def309bb01e1f0848449d59b41d91c5a9f773erw"){
    localStorage.setItem("language", "en");
}

if(localStorage.getItem('projectKeyForm') === 'egoey4j6avjc5rv3jkxktthj6ovqkubmdhg2hrq6'){
    localStorage.setItem("language", "en");
    localStorage.setItem("totalPageDailyForm", "6");
}

if(localStorage.getItem('projectKeyForm') === 'a82b9f65c583921083e8ff0089ed9e44ed8eb8a3'){
    localStorage.setItem("language", 'th');
    localStorage.setItem("totalPageDailyForm", "6");
}

const CustomButtonRoot = styled('button')(`
  background-color: #7F51F5;
  padding: 15px 120px;
  border-radius: 500px;
  color: #fff;
  font-weight: 600;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  transition: all 200ms ease;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0);
  border: none;

  &:hover {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.active} {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
    box-shadow: 0 0 0 0 rgba(0, 127, 255, 0);
  }
`);

function CustomButton(props) {
    return <ButtonUnstyled {...props} component={CustomButtonRoot} />;
}   

function MentalHealthForm() {
    const classes = useStyles();
    const history = useHistory();
    const [assessmentTime, setAssessmentTime] = React.useState('1');
    const [currentSymptom, setCurretSymptom ] = React.useState('1');
    const [showPage, setShowPage ] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [alertText, setAlertText] = React.useState("");
    const [disableFirstTime, setDisableFirstTime] = React.useState(true);
    const [disableSecondTime, setDisableSecondTime] = React.useState(true);
    const [disableThirdTime, setDisableThirdTime] = React.useState(true);
    const { t, i18n } = useTranslation();
    const [lang, setLang] = React.useState(localStorage.getItem('language'));

    const getAlert = () =>{
        setLoading(true);
        const headers = {
            'Project-Key': localStorage.getItem("projectKeyForm"),
            // 'Authorization': "Bearer " + localStorage.getItem("tokenForm")
            };
        // axios.get('/v2/home-isolation/hospital/symptom/mental',{ headers })
        axios.get('https://api.space-trax.com/v2/home-isolation/web/' + localStorage.getItem("userId") + "/symptom/mental",{ headers })
        .then(response => {
            if(response.data.data){
                setLoading(false);
                
                if(response.data.data.alert !== ""){
                    setShowPage(false);
                    gotoAlert(response.data.data.alert);
                    
                }else{
                    if(!response.data.data.assesment_one){
                        setDisableFirstTime(false);
                    }
                    if(!response.data.data.assesment_two){
                        setDisableSecondTime(false);
                    }
                    if(!response.data.data.assesment_three){
                        setDisableThirdTime(false);
                    }

                    // if(!response.data.data.assesment_two && !response.data.data.assesment_three && response.data.data.assesment_one){
                    //     setAssessmentTime('1');
                    // }
                    if(!response.data.data.assesment_one && response.data.data.assesment_two) {
                        setAssessmentTime('2');
                    }
                    if(!response.data.data.assesment_one && !response.data.data.assesment_two && response.data.data.assesment_three){
                        setAssessmentTime('3');
                    }
                    
                    setShowPage(true);
                    // setShowPage(false);
                    // gotoAlert(response.data.data.alert);
                }
            }else{
                setLoading(false);
                // handleClickOpenError();
                console.log("error");
            }
        }).catch(error => {
            setLoading(false);
            // handleClickOpenError();
            console.log("error");        });
    }

    useEffect(() => {
        getAlert();
    }, []); 

    const handleChangeAssessment = (event) => {
        setAssessmentTime(event.target.value);
        
    };

    const handleChangeLanguage = (event) => {
        
        setLang(event.target.value);
        localStorage.setItem('language', event.target.value);
        i18n.changeLanguage(localStorage.getItem("language"));
    };

    const handleChangeSymptom = (event) => {
        setCurretSymptom(event.target.value);
       
    };

    const controlProps = (item) => ({
        checked: assessmentTime === item,
        onChange: handleChangeAssessment,
        value: item,
        name: 'color-radio-button',
        inputProps: { 'aria-label': item },
      });

    const controlPropsSymptoms = (item) => ({
        checked: currentSymptom === item,
        onChange: handleChangeSymptom,
        value: item,
        name: 'color-radio-button',
        inputProps: { 'aria-label': item },
    });

    const routeChange = () =>{ 
        let path = "";
        localStorage.setItem("assessmentTime", assessmentTime)
        if(currentSymptom === '1') {
            localStorage.setItem("mentalSymptom", "เศร้า ซึมเศร้า สิ้นหวัง");
        }else if(currentSymptom === '2'){
            localStorage.setItem("mentalSymptom", "ขาดความสนใจ หรือ ขาดความสุขในการทำสิ่งต่างๆ");
        }else {
            localStorage.setItem("mentalSymptom", "ไม่มีอาการดังกล่าว");
        }
        path = `/chooseMental`; 
        history.push(path);
        
    }

    const gotoAlert = (data) =>{ 
        let path = "";
        path = `/deny2ndAssessment`; 
        history.push(path, {alert: data});
    }

    return (
        <>
        {
            showPage ?
            <ThemeProvider theme={THEME}>
                <br/>
                
                <div className={classes.body}>
                    <div style={{float : 'right', marginRight: '18px'}}>
                        <ToggleButtonGroup
                            color="primary"
                            value={lang}
                            exclusive
                            onChange={handleChangeLanguage}
                            size="small"
                        >
                            <ToggleButton value="en">English</ToggleButton>
                            <ToggleButton value="th">ภาษาไทย</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <br/>
                    <Box sx={{ flexGrow: 1 }} pt={3}>
                        <Grid container pl={2} pr={2}>
                            
                            <Grid item  md={12} xs={12}>
                                <Card sx={{ minWidth: 275 }} variant="outlined" style={{borderRadius: '15px', borderColor: '#D2C4F8'}}>
                                    <CardContent style={{textAlign: 'center'}}>
                                        <span className={classes.textColor}> 
                                            <span className="font-face-pb">
                                            {t('mentalHealthTitle1')}
                                            </span>
                                        </span>
                                        <br/>
                                        <span className={classes.textColor}>
                                            <span className="font-face-pb">
                                                {t('mentalHealthTitle2')}
                                            </span>
                                        </span>
                                        <p className={classes.size12}>
                                        <span className="font-face-pr">
                                            {t('mentalHealthText1')}
                                        <br/>
                                            {t('mentalHealthText2')}
                                        </span>
                                        </p>
                                    </CardContent>
                                </Card>
                            </Grid>
                            
                        </Grid>     
                    </Box>
                    
                    <Box sx={{ flexGrow: 1 }} pt={3}>
                        <Grid container pl={2} pr={2}>
                            
                            <Grid item  md={12} xs={12}>
                                <Card sx={{ minWidth: 275 }} variant="outlined" style={{borderRadius: '15px', borderColor: '#D2C4F8'}}>
                                <CardHeader
                                    subheader={t('mentalHealthAssessmentTitle')}
                                    subheaderTypographyProps={{color:'#fff', fontSize: '12px' }}
                                    style={{ paddingLeft: '33px', backgroundColor: '#7F51F5',color: '#fff' }}
        
                                />
                                    <CardContent>
                                        <FormGroup style={{ paddingLeft: '20px' }}>
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    aria-label="period"
                                                    name="radio-buttons-group"
                                                    value={assessmentTime}
                                                    onChange={handleChangeAssessment}
                                                >
                                                    {
                                                        disableFirstTime ? <FormControlLabel
                                                        control={<Radio {...controlProps('1')}
                                                                    sx={{
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 18,
                                                                        },
                                                                        color: '#7F51F5',
                                                                        '&.Mui-checked': {
                                                                            color: '#7F51F5',
                                                                        },
                                                                    }}/>} 
                                                                    
                                                                    label={<Typography style={{fontSize: '12px'}}>{t('firstTime')}</Typography>}/> :
                                                                    <FormControlLabel
                                                                    disabled
                                                                    control={<Radio {...controlProps('1')}
                                                                                sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 18,
                                                                                    },
                                                                                    color: '#7F51F5',
                                                                                    '&.Mui-checked': {
                                                                                        color: '#7F51F5',
                                                                                    },
                                                                                }}/>} 
                                                                                
                                                                                label={<Typography style={{fontSize: '12px'}}>{t('firstTime')}</Typography>}/>
                                                    }
                                                    
                                                    {
                                                        disableSecondTime ? <FormControlLabel
                                                        control={<Radio 
                                                            {...controlProps('2')}
                                                            sx={{
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 18,
                                                                },
                                                                color: '#7F51F5',
                                                                '&.Mui-checked': {
                                                                    color: '#7F51F5',
                                                                },
                                                            }}
                                                        />} 
                                                        label={<Typography style={{fontSize: '12px'}}>{t('secondTime')}</Typography>}/>
                                                        :
                                                        <FormControlLabel
                                                            disabled
                                                            control={<Radio 
                                                                {...controlProps('2')}
                                                                sx={{
                                                                    '& .MuiSvgIcon-root': {
                                                                        fontSize: 18,
                                                                    },
                                                                    color: '#7F51F5',
                                                                    '&.Mui-checked': {
                                                                        color: '#7F51F5',
                                                                    },
                                                                }}
                                                            />} 
                                                            label={<Typography style={{fontSize: '12px'}}>{t('secondTime')}</Typography>}/>
                                                    }
                                                    
                                                    {
                                                        disableThirdTime ?
                                                        <FormControlLabel
                                                            control={<Radio 
                                                                {...controlProps('3')}
                                                                sx={{
                                                                    '& .MuiSvgIcon-root': {
                                                                        fontSize: 18,
                                                                    },
                                                                    color: '#7F51F5',
                                                                    '&.Mui-checked': {
                                                                        color: '#7F51F5',
                                                                    },
                                                                }}
                                                            />} 
                                                            label={<Typography style={{fontSize: '12px'}}>{t('thirdTime')}</Typography>}/>
                                                        :

                                                        <FormControlLabel
                                                            disabled
                                                            control={<Radio 
                                                                {...controlProps('3')}
                                                                sx={{
                                                                    '& .MuiSvgIcon-root': {
                                                                        fontSize: 18,
                                                                    },
                                                                    color: '#7F51F5',
                                                                    '&.Mui-checked': {
                                                                        color: '#7F51F5',
                                                                    },
                                                                }}
                                                            />} 
                                                            label={<Typography style={{fontSize: '12px'}}>{t('thirdTime')}</Typography>}/>
                                                    }
        
                                                    
                                                
                                                </RadioGroup>
                                            </FormControl>
                                        </FormGroup>
                                    </CardContent>
                                </Card>
                            </Grid>
                            
                        </Grid>     
                    </Box>
        
                    <Box sx={{ flexGrow: 1 }} pt={3}>
                        <Grid container pl={2} pr={2}>
                            
                            <Grid item  md={12} xs={12}>
                                <Card sx={{ minWidth: 275 }} variant="outlined" style={{borderRadius: '15px', borderColor: '#D2C4F8'}}>
                                <CardHeader
                                    subheader={t('symptomTitle')}
                                    subheaderTypographyProps={{color:'#fff', fontSize: '12px' }}
                                    style={{ paddingLeft:'33px', backgroundColor: '#7F51F5',color: '#fff' }}
        
                                />
                                    <CardContent>
                                        <FormGroup style={{ paddingLeft: '20px' }}>
                                            <FormControl component="fieldset">
                                                
                                                <RadioGroup
                                                    aria-label="symptom"
                                                    name="radio-symptom-group"
                                                    value={currentSymptom}
                                                    onChange={handleChangeSymptom}
                                                >
                                                    <FormControlLabel
                                                    control={<Radio {...controlPropsSymptoms('1')}
                                                                sx={{
                                                                    '& .MuiSvgIcon-root': {
                                                                        fontSize: 18,
                                                                    },
                                                                    color: '#7F51F5',
                                                                    '&.Mui-checked': {
                                                                        color: '#7F51F5',
                                                                    },
                                                                }}/>} 
                                                                
                                                                label={<Typography style={{fontSize: '12px'}}>{t('mentalHeatlhSymptom1')}</Typography>}/>
                                                    <FormControlLabel
                                                    control={<Radio 
                                                        {...controlPropsSymptoms('2')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 18,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}
                                                    />} 
                                                    label={<Typography style={{fontSize: '12px'}}>{t('mentalHealthSymptom2')} <br/> {t('mentalHealthSymptom21')}</Typography>}/>
        
                                                <FormControlLabel
                                                    control={<Radio 
                                                        {...controlPropsSymptoms('3')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 18,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}
                                                    />} 
                                                    label={<Typography style={{fontSize: '12px'}}>{t('mentalHealthSymptom3')}</Typography>}/>
                                                </RadioGroup>
                                                
                                            </FormControl>
                                        </FormGroup>
                                    </CardContent>
                                </Card>
                            </Grid>
                            
                        </Grid>     
                    </Box>
        
                    <Box sx={{ flexGrow: 1 }} pt={3}>
                        <Grid container pl={2} pr={2} direction="column"
                            alignItems="center"
                            justify="center">
                            
                                <Grid item  md={12} xs={12}>
                                    <div className={classes.appbar}>
                                        <CustomButton onClick={routeChange}>NEXT</CustomButton>
                                    </div>
                                </Grid>
                        </Grid>     
                    </Box>   

                    <Box sx={{ flexGrow: 1 }} pt={3}>
                        <Grid container pl={2} pr={2} direction="column">
                            
                                <Grid item  md={12} xs={12}>
                                    <div className={classes.appbar} style={{fontSize: '11px', color: 'grey' , fontWeight: 'bold', float: 'right'}}>
                                        { "Page |" + " 1/2" }
                                    </div>
                                </Grid>
                        </Grid>     
                    </Box>     
                </div>
            </ThemeProvider>

            : <>
                <div className={classes.body}>
                    <GifLoader className="margin0Auto"
                        loading={loading}
                        imageSrc={loadingGif}
                        imageStyle={{marginTop:'200px', width: "20%"}}
                        overlayBackground="rgba(0,0,0,0.1)"
                    />
                </div>
            </>
        } 
        </>
    )
}

export default MentalHealthForm
