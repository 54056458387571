import React, {Component, useState, useEffect} from 'react';
import { Modal, Button, Row, Col, Form } from'react-bootstrap';
import './custom.css';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import axios from 'axios';
import * as ImIcons from 'react-icons/im';
import xlsxFile from '../assets/Sample_File.xlsx';
import sampleFile from '../assets/Sample_File(hotel).xlsx';

export class UploadFileModal extends Component{
    constructor(props){
        super(props);
        this.wrapper = React.createRef();

        this.state = {
            snackbarOpen: false, 
            snackbarMsg: '',
            hospitalId : '',
            hospitalInfo:[],
            csv: null,
            isFilePicked: false,
            fileName: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);    
        this.handleFileChange = this.handleFileChange.bind(this);               
    }

    componentDidMount(){
        this.getHospitalList();
    }

    getHospitalList(){
        const headers = { 
            'Project-Key': localStorage.getItem("projectKey"),
            'Authorization': "Bearer " + localStorage.getItem("token")
        };

        axios.get('https://api.space-trax.com/v2/home-isolation/admin/hospital', { headers })
            .then(res => {
                this.setState({ hospitalInfo : res.data.data });
                console.log("hospital info list: ", this.state.hospitalInfo);
            })
    }

    snackbarClose = (event) => {
        this.setState({snackbarOpen:false});
    }

    handleChange = (event) =>{
        this.setState({ hospitalId : event.target.value })
        console.log('hospital id: ', this.state.hospitalId);    
    }

    handleFileChange = (event) => {
        let file = event.target.files;
        console.log("files: ", file);
        this.setState({ fileName: event.target.files[0].name });
        this.setState({ csv: event.target.files[0] }); 
    }
    

    handleSubmit(event){
        event.preventDefault();
        console.log("click upload: ", this.state.csv);
        const formData = new FormData();
        formData.append("upload", this.state.csv);
        // formData.append("name", this.state.fileName);

        // API CALL
        if(this.state.hospitalId && this.state.hospitalId !== 'Select Hospital'){
            if(this.state.csv){
                fetch("https://api.space-trax.com/v2/home-isolation/admin/upload/" + this.state.hospitalId, {
                    method: "POST",
                    headers: { 
                        'Project-Key': localStorage.getItem("projectKey"),
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    },
                    body: formData,
                })
                .then((response) => response.json())
                .then((result) => {
                    console.log("result:", result);
                    if(result.code === 1){
                        this.setState({snackbarOpen: true, snackbarMsg : 'Successfully Uploaded'});
                        setTimeout(function(){ window.location.reload(false); }, 100);
                    }
                    else {
                        this.setState({snackbarOpen: true, snackbarMsg : 'Upload Failed! Internal server error'});
                    }
                })
                .catch((error) => {
                    console.log("error:", error);
                    this.setState({snackbarOpen: true, snackbarMsg : 'Upload Failed!'});
                });
            }else{
                this.setState({snackbarOpen: true, snackbarMsg : 'Please Choose File'});
            }
            
        }else {
            this.setState({snackbarOpen: true, snackbarMsg : 'Please Select Hospital'});
        }
        
    }

    closeModal(){
        // setTimeout(function(){ window.location.reload(false); }, 100);
        this.props.onHide();
        this.setState({ fileName: ''});
        this.setState({hospitalId: ''});
        this.setState({csv: null});
    }

    render(){
        if(!this.props.show){
            return null;
        }

        const { hospitalInfo,hospitalId,csv,fileName } = this.state; 
        let file = null;

        file = fileName 
            ? ( <span>File Selected - {fileName}</span>) 
            : ( <span></span> );
        let selectName = localStorage.getItem("projectType") === "isolation" ? "Select Hospital" : "Select Hospital";
        return(
            <div ref={this.wrapper}>
                <Snackbar
                    anchorOrigin= {{vertical:'top', horizontal:'right'}}
                    open = {this.state.snackbarOpen}
                    autoHideDuration = {3000}
                    severity="success"
                    onClose= {this.snackbarClose}
                    message = {<span id="message.id">{this.state.snackbarMsg}</span>}
                    action= {
                        [
                            <IconButton
                                key = "close"
                                arial-label = "Close"
                                color = "inherit" 
                                onClick = {this.snackbarClose} 
                            >
                                x
                            </IconButton>
                        ]
                    }
                />
                    <Modal
                        {...this.props}
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        animation={false}>
                            <Modal.Header>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Upload File
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {/* <div className="container"> */}
                                    <Row key={Math.random()}>
                                        <Col sm={12}>
                                            <Form onSubmit= {this.handleSubmit}>
                                                <Form.Group controlId="id">
                                                    <Row>
                                                        <Col lg={12} md={12} sm={12}>
                                                            <Form.Label className="labelPaddingTop">
                                                           
                                                                {
                                                                    localStorage.getItem("projectType") ===  'isolation' ?
                                                                    <> Hospital List </>
                                                                    :
                                                                    <>
                                                                        Hospital List
                                                                    </>
                                                                }
                                                            </Form.Label>
                                                            <Form.Control 
                                                                value = {hospitalId}
                                                                as="select"
                                                                onChange={this.handleChange}
                                                                >
                                                                <option>{selectName}</option>
                                                                {hospitalInfo.map((e, key)=>
                                                                    <option key={key} value={e.id}>{e.name_en}</option>
                                                                )}
                                                            </Form.Control>        
                                                        </Col>
                                                    </Row>

                                                    <Row className="labelPaddingTop">
                                                        <Col lg={12} md={12} sm={12}>
                                                        <Form.Label className="labelPaddingTop">
                                                                Choose File to Upload (
                                                                {
                                                                    localStorage.getItem("projectType") ===  'isolation' ?
                                                                    <a
                                                                        href={sampleFile}
                                                                        download="Sample_File.xlsx"
                                                                    >
                                                                        Download Sample File
                                                                    </a>
                                                                    :
                                                                    <a
                                                                        href={xlsxFile}
                                                                        download="Sample_File.xlsx"
                                                                    >
                                                                        Download Sample File
                                                                    </a>
                                                                }
                                                                )
                                                            
                                                         </Form.Label>
                                                        <br/>
                                                            <label className = "custom-file-upload">
                                                                <Form.Control
                                                                    type="file"
                                                                    name="file"
                                                                    accept=".xlsx" 
                                                                    onChange={(e) => this.handleFileChange(e)}
                                                                />
                                                                <ImIcons.ImUpload3 className="uploadIcon"/> Choose File
                                                            </label>        
                                                            
                                                            
                                                            <label htmlFor="file" className="l5">{file}</label>
        
                                                        </Col>
                                                        <br/>
                                                        <br/>
                                                    </Row>
                                                    

                                                </Form.Group>

                                                <Form.Group className="buttonFloatRight">
                                                    <Button  className="r14" variant="light" onClick={()=>this.closeModal()}>Cancel</Button>
                                                    <Button variant="success" 
                                                        type="submit">Upload</Button>
                                                </Form.Group>
                                            </Form>   
                                        </Col>
                                    </Row>
                                {/* </div> */}
                            </Modal.Body>
                    </Modal>

            </div>
            
        )
    }
}