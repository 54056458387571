import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: localStorage.getItem("language"),
  lng: localStorage.getItem("language"),
  resources: {
    en: {
      translations: require('./locales/en/translations.json')
    },
    th: {
      translations: require('./locales/thai/translations.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'th'];

export default i18n;