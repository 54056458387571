import React, { Component } from "react";
import "./Login.css";
import { Redirect } from "react-router-dom";
import OtpInput from 'react-otp-input';
import {Button, Row, Col, Container,Form} from 'react-bootstrap';
import ReactNotification from 'react-notifications-component';
import { store } from 'react-notifications-component';
import GifLoader from 'react-gif-loader';
import loadingGif from '../assets/loading.gif';
import 'react-notifications-component/dist/theme.css';
// import { ChooseProject } from "./ChooseProject";


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      islogged: false,
      loginParams: {
        user_id: "",
        user_password: ""
      },
      otp: '', 
      routeToHomepage: false,
      routeToChooseProjects: false, 
      secertCode : '',
      refCode: '',
      userName:'',
      loading: false
    };
    this.login = this.login.bind(this); 
  }

  handleFormChange = event => {
    let loginParamsNew = { ...this.state.loginParams };
    let val = event.target.value;
    loginParamsNew[event.target.name] = val;
    this.setState({
      loginParams: loginParamsNew
    });
  };

  login(event) {
    event.preventDefault();
    this.setState({loading: true});
    let user_id = this.state.loginParams.user_id;
    let user_password = this.state.loginParams.user_password;
    var details;
    details = {
        'username': user_id,
        'password': user_password
    };
    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch('https://api.space-trax.com/v2/auth/login', {
        method: 'POST',
        headers: { 
            'Accept' : '*/*',
            'Content-Type' : 'application/x-www-form-urlencoded'
        },
        body: formBody
    })
    .then(res => res.json())
    .then((result)=>
    {
        if(result.data){
            this.setState({loading: false});

            this.setState({secertCode: result.data.secret_code});
            this.setState({refCode: result.data.ref_code});
            this.setState({userName: result.data.userName});
            console.log("refCode:",this.state.refCode);

            localStorage.setItem("otpRoute", "true"); 
            this.setState({islogged: true});       
            console.log("success", result);

            store.addNotification({
                title: "Success!",
                message: "Otp code has been sent to " + result.data.username,
                type: "success",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    showIcon: true,
                  }
              });
            
        }else {
            this.setState({loading: false});

            store.addNotification({
                title: "Login failed",
                message: "UserName or Password is wrong",
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    showIcon: true,
                  }
              });
        }
        
    },
    (error)=>{
        this.setState({loading: false});
                
        store.addNotification({
            title: "Something went Wrong",
            message: "",
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                showIcon: true,
              }
        });
        console.log("error");
    })
    
  };

  verifyOtp = () => {
        console.log("otp:", this.state.otp);
         this.setState({loading: true});
        
        var details;
        details = {
            'secret_code': this.state.secertCode,
            'ref_code': this.state.refCode,
            'otp': this.state.otp
        };
        
        console.log("details:", details);
        var formBody = [];
        for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        console.log("details formbody:", formBody);

        fetch('https://api.space-trax.com/v2/auth/verify-login', {
            method: 'POST',
            headers: { 
                'Accept' : '*/*',
                'Content-Type' : 'application/x-www-form-urlencoded'
            },
            body: formBody
        })
        .then(res => res.json())
        .then((result)=>
        {
            this.setState({loading: false});
            
            if(result.data){
                store.addNotification({
                    title: "Success!",
                    message: "successfully logged in",
                    type: "success",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        showIcon: true,
                        }
                });
                localStorage.setItem("token",result.data.token); 
                this.getUserProfile();

            }else{
                this.setState({loading: false});
                
                store.addNotification({
                    title: "Wrong Otp",
                    message: "Please check your otp again",
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        showIcon: true,
                      }
                });
            }

        },
        (error)=>{
            this.setState({loading: false});
                
            store.addNotification({
                title: "Something went Wrong",
                message: "",
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    showIcon: true,
                  }
            });
            console.log("error");
        })
    };

    getUserProfile = () => {        
        fetch('https://api.space-trax.com/v2/auth/user', {
            method: 'GET',
            headers: { 
                'Accept' : '*/*',
                'Content-Type' : 'application/x-www-form-urlencoded',
                'Authorization': "Bearer " + localStorage.getItem("token"),
            },
        })
        .then(res => res.json())
        .then((result)=>
        {
            this.setState({loading: false});
            
            if(result.data){
                // console.log("projectKey",result.data.project[0].project_key )
                localStorage.setItem("projectKey",result.data.project[0].project_key); 
                localStorage.setItem("projectType", result.data.project[0].type);
                localStorage.setItem("projectName", result.data.project[0].name_th);
                if(localStorage.getItem("projectKey")){
                    // this.setState({routeToHomepage: true});
                    this.getProjects();
                }
            }else{

            }

        },
        (error)=>{
            this.setState({loading: false});
                
                store.addNotification({
                    title: "Something went Wrong",
                    message: "",
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        showIcon: true,
                      }
                });
            console.log("error");
        })
    };

    getProjects = () => {        
        fetch('https://api.space-trax.com/v2/dashboard/project', {
            method: 'GET',
            headers: { 
                'Accept' : '*/*',
                'Content-Type' : 'application/x-www-form-urlencoded',
                'Authorization': "Bearer " + localStorage.getItem("token"),
                'Project-Key': localStorage.getItem('projectKey')
            },
        })
        .then(res => res.json())
        .then((result)=>
        {
            console.log('result projects: ', result.data);
            this.setState({loading: false});
            
            if(result.data.length > 1){
                this.setState({routeToChooseProjects: true});
                this.setState({routeToHomepage: false});
            }else {
                this.setState({routeToHomepage: true});
            }

        },
        (error)=>{
            this.setState({loading: false});
                
                store.addNotification({
                    title: "Something went Wrong from getting projects ",
                    message: "",
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        showIcon: true,
                      }
                });
            console.log("error");
        })
    };

    handleChange = (otp) => this.setState({ otp });

  render() {
    let {islogged,otp, routeToHomepage, routeToChooseProjects} = this.state;
    if (this.state.routeToHomepage) {
      return <Redirect to="/homepage" />;
    }else if (routeToChooseProjects){
        return <Redirect to="/chooseProject"/>;
    }
    return (
    <div>
        <ReactNotification />

        <GifLoader className="margin0Auto"
            loading={this.state.loading}
            imageSrc={loadingGif}
            imageStyle={{marginTop:"10%", width: "10%"}}
            overlayBackground="rgba(0,0,0,0.1)"
        />

        {
            islogged ?
            <div className="d-flex align-items-center justify-content-center">
            <Container>
                {/* <h4 className="text-success mt-5 p-3 text-center rounded">Login</h4> */}
               
                <h4 className="text-success mt-5 p-3 text-center rounded">Please Enter OTP Code</h4>

                <Row>
                    <Col lg={7} md={10} sm={12} className="p-5 m-auto shadow-sm rounded-lg">
                        <br/>
                        <Form className="d-flex align-items-center justify-content-center">
                            <OtpInput
                                inputStyle= "inputStyle"
                                value={this.state.otp}
                                onChange={this.handleChange}
                                numInputs={6}
                                separator={<span>.</span>}
                                shouldAutoFocus
                            />
                            <br/>
                            
                        </Form>
                        <br/>
                        <br/>
                        <div className="d-grid gap-2">
                            <Button variant="success" onClick = {this.verifyOtp}>
                                Verify
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
            </div>
            :
            <div  className="d-flex align-items-center justify-content-center">
            
            <Container>
                <h4 className="text-success mt-5 p-3 text-center rounded">Patient Management</h4>
                <Row>
                    <Col lg={5} md={6} sm={12} className="p-5 m-auto shadow-sm rounded-lg">
                        <Form onSubmit={this.login}>
                            <Form.Group className="mb-3" controlId="formUserName">
                                <Form.Label>User Name</Form.Label>
                                <Form.Control type="text" 
                                    name="user_id"
                                    onChange={this.handleFormChange}
                                    placeholder="Enter User Name" />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password"
                                    name="user_password"
                                    onChange={this.handleFormChange}
                                    placeholder="Enter Password" />
                            </Form.Group>
                            <div className="d-grid gap-2">
                                <Button variant="success" type="submit">
                                    Submit
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
        
        }
    </div>

    );
  }
}
export default Login;