import React, {Component, useState, useEffect} from 'react';
import { Modal, Button, Row, Col, Form } from'react-bootstrap';
import './custom.css';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import ReactNotification from 'react-notifications-component';
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

export class UserFormModal extends Component{
    constructor(props){
        super(props);
        console.log("props:", props);
        this.wrapper = React.createRef();

        this.state = {
            snackbarOpen: false, 
            snackbarMsg: '',
            processStatus : ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this); 
               
    }

    snackbarClose = (event) => {
        this.setState({snackbarOpen:false});
    }

    handleCloseModal () {
    	this.setState({ show : false });
  	}

    editModalClose = () => this.setState({editModalShow:false});

    handleChange = (event) =>{
        this.setState({ processStatus: event.target.value })
    }

    

    handleSubmit(event){
        event.preventDefault();
        var details;
        if(localStorage.getItem("projectType") === 'isolation'){
            if(!this.state.processStatus){
                details = {
                    'address': event.target.address.value,
                    'identity_number': event.target.identityNo.value,
                    'name': event.target.userName.value ,
                    'phone_number': event.target.PhoneNo.value ,
                    'surname': event.target.surName.value,
                    'process_status': (this.props.userlist.process_status).toString()
                };    
            }else {
                details = {
                    'address': event.target.address.value,
                    'identity_number': event.target.identityNo.value,
                    'name': event.target.userName.value ,
                    'phone_number': event.target.PhoneNo.value ,
                    'surname': event.target.surName.value,
                    'process_status': this.state.processStatus
                };
            }
        }else{
            if(!this.state.processStatus){
                details = {
                    'address': event.target.address.value,
                    'identity_number': event.target.identityNo.value,
                    'imei': event.target.Imei.value,
                    'name': event.target.userName.value ,
                    'oximeter_id': event.target.oximeter.value ,
                    'phone_number': event.target.PhoneNo.value ,
                    'surname': event.target.surName.value,
                    'process_status': (this.props.userlist.process_status).toString()
                };    
            }else {
                details = {
                    'address': event.target.address.value,
                    'identity_number': event.target.identityNo.value,
                    'imei': event.target.Imei.value,
                    'name': event.target.userName.value ,
                    'oximeter_id': event.target.oximeter.value ,
                    'phone_number': event.target.PhoneNo.value ,
                    'surname': event.target.surName.value,
                    'process_status': this.state.processStatus
                };
            }
        }
        


        
        console.log("details:", details);
        var formBody = [];
        for (var property in details) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        
        

        fetch('https://api.space-trax.com/v2/home-isolation/admin/' + event.target.userId.value, {
            method: 'PATCH',
            headers: { 
                'Accept' : '*/*',
                'Content-Type' : 'application/x-www-form-urlencoded',
                'Project-Key': '4e503930cae7d88ab0e12607bfa2d96b32342149',
                'Authorization': "Bearer " + localStorage.getItem("token")
            },
            body: formBody
        })
        .then(res => res.json())
        .then((result)=>
        {   
            store.addNotification({
                title: "Success!",
                message: "Successfully Updated",
                type: "success",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    showIcon: true,
                    }
            });

            this.setState({snackbarOpen: true, snackbarMsg : 'Successfully Updated'});
            setTimeout(function(){ window.location.reload(false); }, 100);
        },
        (error)=>{
            store.addNotification({
                title: "Failed",
                message: "Update Failed",
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    showIcon: true,
                  }
            });
            this.setState({snackbarOpen: true, snackbarMsg : 'Update Failed!'});
        }

        )
    }

    render(){
        if(!this.props.show){
            return null;
        }

        let {processStatus} = this.state;
        // processStatus = this.props.userlist.process_status;
        // Object.assign(this.state, this.props.userlist);
        
        return(
            <div ref={this.wrapper}>
                <ReactNotification />
                {/* <Snackbar
                    anchorOrigin= {{vertical:'top', horizontal:'right'}}
                    open = {this.state.snackbarOpen}
                    autoHideDuration = {3000}
                    severity="success"
                    onClose= {this.snackbarClose}
                    message = {<span id="message.id">{this.state.snackbarMsg}</span>}
                    action= {
                        [
                            <IconButton
                                key = "close"
                                arial-label = "Close"
                                color = "inherit" 
                                onClick = {this.snackbarClose} 
                            >
                                x
                            </IconButton>
                        ]
                    }
                /> */}
                    <Modal
                            {...this.props}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            animation={false}
                        >
                            <Modal.Header>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Edit User Info
                            </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {/* <div className="container"> */}
                                    <Row key={Math.random()}>
                                        <Col sm={12}>
                                            <Form onSubmit= {this.handleSubmit}>
                                                <Form.Group controlId="id">
                                                    <Row>
                                                        {
                                                            localStorage.getItem("projectType") === 'isolation' ?
                                                            <> </>:
                                                            <>
                                                                <Col lg={6} md={12} sm={12}>
                                                                    <Form.Label className="labelTop">IMEI</Form.Label>
                                                                    <Form.Control 
                                                                        type="text"
                                                                        name="Imei"
                                                                        defaultValue = {this.props.userlist.imei}
                                                                        placeholder="please enter IMEI data"
                                                                        
                                                                    />
                                                                </Col>
                                                                <Col lg={6} md={12} sm={12}>
                                                                    <Form.Label className="labelTop">Oximeter ID</Form.Label>
                                                                    <Form.Control 
                                                                        type="text"
                                                                        name="oximeter"
                                                                        required
                                                                        defaultValue = {this.props.userlist.oximeter_id}
                                                                        placeholder="Please Enter OximeterID"
                                                                    />
                                                                </Col>
                                                            </>
                                                        }
                                                        

                                                        
                                                        
                                                    </Row>

                                                    <Row>
                                                        <Col lg={6} md={12} sm={12}>
                                                            <Form.Label className="labelPaddingTop">Name</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="userName"
                                                                required
                                                                defaultValue = {this.props.userlist.name}
                                                                placeholder="default value"
                                                            />
                                                        </Col>
                                                        <Col lg={6} md={12} sm={12}>
                                                            <Form.Label className="labelPaddingTop">SurName</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="surName"
                                                                required
                                                                defaultValue = {this.props.userlist.surname}
                                                                placeholder="default value"
                                                            />
                                                        </Col>
                                                    </Row>  

                                                     <Row>
                                                        <Col lg={6} md={12} sm={12}>
                                                            <Form.Label className="labelPaddingTop">Phone No.</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="PhoneNo"
                                                                required
                                                                defaultValue = {this.props.userlist.phone_number}
                                                                placeholder="Phone Number"
                                                            />
                                                        </Col>
                                                        <Col lg={6} md={12} sm={12}>
                                                            <Form.Label className="labelPaddingTop">
                                                                <b>
                                                                    Identity Number
                                                                </b>
                                                            </Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="identityNo"
                                                                required
                                                                defaultValue = {this.props.userlist.identify_number}
                                                                placeholder="Enter Id"
                                                            />
                                                        </Col>
                                                    </Row>

                                                    {
                                                        localStorage.getItem("projectType") === 'isolation' ?
                                                        <></>:
                                                        <Row>
                                                            <Col lg={6} md={12} sm={12}>
                                                                <Form.Label className="labelPaddingTop">Hospital Name (EN)</Form.Label>
                                                                <Form.Control 
                                                                    type="text"
                                                                    name="hospitalEn"
                                                                    readOnly
                                                                    defaultValue = {this.props.hospital.name_en}
                                                                    placeholder="please enter IMEI data"
                                                                />
                                                            </Col>

                                                            <Col lg={6} md={12} sm={12}>
                                                                <Form.Label className="labelPaddingTop">Hospital Name (Thai)</Form.Label>
                                                                <Form.Control 
                                                                    type="text"
                                                                    name="hospitalThai"
                                                                    readOnly
                                                                    defaultValue = {this.props.hospital.name_th}
                                                                    placeholder="please enter IMEI data"
                                                                />
                                                            </Col>
                                                            
                                                        </Row>
                                                    }
                                                    

                                                    <Row>
                                                        <Col lg={6} md={12} sm={12}>
                                                            <Form.Label className="labelPaddingTop">Ref Code</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="refCode"
                                                                required
                                                                readOnly
                                                                defaultValue = {this.props.userlist.ref_code}
                                                               
                                                            />
                                                        </Col>
                                                        <Col lg={6} md={12} sm={12}>
                                                            <Form.Label className="labelPaddingTop">
                                                                <b>
                                                                    Process Status
                                                                </b>
                                                            </Form.Label>
                                                            {(() => {
                                                            if (this.props.userlist.process_status === 1) {
                                                            return (
                                                                <Form.Control 
                                                                    type="text"
                                                                    name="process_status"
                                                                    required
                                                                    readOnly
                                                                    defaultValue = {'Pending'}
                                                                
                                                                />
                                                            )
                                                            } else {
                                                            return (
                                                                <Form.Control 
                                                                    type="text"
                                                                    name="process_status"
                                                                    required
                                                                    readOnly
                                                                    defaultValue = {'Sent'}
                                                                
                                                                />

                                                                
                                                            )}
                                                        })()}
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                         <Col lg={6} md={12} sm={12}>
                                                            <Form.Label className="labelPaddingTop">Address</Form.Label>
                                                                <Form.Control 
                                                                    as="textarea"
                                                                    name="address"
                                                                    rows="3"
                                                                    required
                                                                    defaultValue = {this.props.userlist.address}
                                                                    placeholder="Address"
                                                                />
                                                        </Col>
                                                        <Col lg={6} md={12} sm={12}>
                                                            
                                                            <Form.Control 
                                                                type="hidden"
                                                                name="userId"
                                                                required
                                                                readOnly
                                                                defaultValue = {this.props.userlist.id}
                                                                placeholder="Enter Id"
                                
                                                            />
                                                        </Col>

                                                    </Row>

                                                </Form.Group>

                                                <Form.Group className="buttonFloatRight">
                                                    <Button  className="r14" variant="light" onClick={this.props.onHide}>Cancel</Button>
                                                    <Button variant="success" 
                                                        type="submit">Submit</Button>
                                                </Form.Group>
                                            </Form>   
                                        </Col>
                                    </Row>
                                {/* </div> */}
                            </Modal.Body>
                    </Modal>

            </div>
            
        )
    }
}