import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles, withStyles } from '@mui/styles';
import {Grid, Box, Card, CardContent, CardHeader,
    Radio ,RadioGroup , Typography,
    FormGroup , FormControlLabel , FormControl , FormLabel,ToggleButton, ToggleButtonGroup 
} from '@mui/material';
import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/core/ButtonUnstyled';
import { styled } from '@mui/system';
import { useHistory } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { purple } from '@mui/material/colors';

const THEME = createTheme({
    typography: {
     "fontFamily": `"PromptMedium","PromptSemiBold", "PromptRegular","PromptBold"`
    },
    palette: {
        primary: {
          main: '#7f51f5',
        }
      },
 });

 

const useStyles = makeStyles({
    body : {
        marginTop: '-3%'
    },
    headerTitle : {
        fontWeight: 'bold',
    },
    appbar: {
        alignItems: 'center',
    },
    textColor: {
        fontSize: '18px',
        color: '#7F51F5'
    },
    size12 :{
        fontSize : '13px'
    },
    redText: {
        color: 'red'
    }
});

const currentURL = window.location.href;
var splitUrl;
var splitUrlToken;
if(currentURL.includes("projectKey=")){
    splitUrl = currentURL.split('projectKey=');
    var projectKeySplit = splitUrl[1].split('&');
    var projectKey = projectKeySplit[0];
    localStorage.setItem("projectKeyForm", projectKey);

    // if(projectKey === 'egoey4j6avjc5rv3jkxktthj6ovqkubmdhg2hrq6'){
    if(projectKey === '7tr84n2xhsvepht7t99wqc5k4db5yk5kpitekq3d'){
        localStorage.setItem("project", "vibharam" );
    }else {
        localStorage.setItem("project", "-");
    }
    
}else{
    localStorage.setItem("projectKeyForm", "1p0ofccowcprtjogw8d30fq2779xc2ega86k93gd");
}

localStorage.setItem("totalPageDailyForm", "7");

if(currentURL.includes("token=")){
    splitUrlToken = currentURL.split('token=');
    var tokenSplit = splitUrlToken[1].split('&');
    var  token = tokenSplit[0];
    localStorage.setItem("tokenForm", token);
}

if(currentURL.includes("projectType")){
    let splitUrlProjectType = currentURL.split('projectType=');
    var tokenSplit = splitUrlProjectType[1].split('&');
    var  projectType = tokenSplit[0];
    localStorage.setItem("project_type", projectType);
}else {
    localStorage.setItem("project_type", 'isolation');
}

if(currentURL.includes("language")){
    let splitUrlProjectType = currentURL.split('language=');
    var tokenSplit = splitUrlProjectType[1].split('&');
    var  language = tokenSplit[0];
    localStorage.setItem("language", language);
}
else {
    localStorage.setItem("language", 'th');
}

if(currentURL.includes("domain")){
    let splitUrlProjectType = currentURL.split('domain=');
    console.log("domain:", splitUrlProjectType )
    var tokenSplit = splitUrlProjectType[1].split('&');
    var  domain = tokenSplit[0];
    localStorage.setItem("domain", domain);
    console.log("domain", localStorage.getItem("domain"));
}

if(currentURL.includes("project_name")){
    let splitUrlProjectType = currentURL.split('project_name=');
    console.log("project_name:", splitUrlProjectType )
    var tokenSplit = splitUrlProjectType[1].split('&');
    var  project_name = tokenSplit[0];
    localStorage.setItem("projectName", project_name);
    console.log("projectName", localStorage.getItem("projectName"));
}


// if(currentURL.includes("user_status")){
//     let splitUrlProjectType = currentURL.split('user_status=');
//     console.log("user_status:", splitUrlProjectType )
//     var tokenSplit = splitUrlProjectType[1].split('&');
//     var  userStatus = tokenSplit[0];
//     localStorage.setItem("userStatus", userStatus);
//     console.log(localStorage.getItem("userStatus"));
// }

if(currentURL.includes("redirect")){
    let splitUrlProjectType = currentURL.split('redirect=');
    // console.log("redirect:", splitUrlProjectType )
    // var tokenSplit = splitUrlProjectType[1].split('redirect=');
    var  redirect = splitUrlProjectType[1];
    localStorage.setItem("redirect", redirect);
    console.log(localStorage.getItem("redirect"));
}

    let redirectURL ;
    if(localStorage.getItem("redirect"))
    {
        //&project_key=&project_type=&project_name=
        redirectURL = localStorage.getItem("redirect");
    }else{
        redirectURL = 'https://form.pmhsolution.com/daily?projectKey='+ localStorage.getItem("projectKeyForm") + '&token=' + localStorage.getItem("tokenForm"); 
    }

    localStorage.setItem("redirectUrl", redirectURL);
    console.log('redirect URL:', localStorage.getItem('redirectUrl'));


if(localStorage.getItem("project") === 'vibharam'){
    localStorage.setItem("language", 'th');
}

if(localStorage.getItem('projectKeyForm') === "def309bb01e1f0848449d59b41d91c5a9f773erw"){
    localStorage.setItem("language", "en");
}

if(localStorage.getItem('projectKeyForm') === 'egoey4j6avjc5rv3jkxktthj6ovqkubmdhg2hrq6'){
    localStorage.setItem("language", "en");
    localStorage.setItem("totalPageDailyForm", "6");
}

if(localStorage.getItem('projectKeyForm') === 'a82b9f65c583921083e8ff0089ed9e44ed8eb8a3'){
    localStorage.setItem("language", 'th');
    localStorage.setItem("totalPageDailyForm", "6");
}

// if(currentURL.includes("language")){
//     let splitUrlProjectType = currentURL.split('language=');
//     console.log(splitUrlProjectType);
//     var tokenSplit = splitUrlProjectType[1].split('&');
//     var  language = tokenSplit[0];
//     console.log(language);
//     localStorage.setItem("language", language);
//     console.log(localStorage.getItem("language"));
// }else if(localStorage.getItem("project") === 'vibharam'){
//     localStorage.setItem("language", 'th');
// }
// else {
//     localStorage.setItem("language", 'en');
// }



if(localStorage.getItem("tokenForm")){
    fetch('https://api.space-trax.com/v2/auth/user', {
        method: 'GET',
        headers: { 
            'Accept' : '*/*',
            'Content-Type' : 'application/x-www-form-urlencoded',
            'Authorization': "Bearer " + localStorage.getItem("tokenForm"),
        },
    })
    .then(res => res.json())
    .then((result)=>
    {
        
        if(result.data){
            // localStorage.setItem("project_type", result?.data?.project[0]?.type);
        }else{

        }

    },
    (error)=>{
        console.log("error");
    })
};

const CustomButtonRoot = styled('button')(`
  background-color: #7F51F5;
  padding: 15px 120px;
  border-radius: 500px;
  color: #fff;
  font-weight: 600;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  transition: all 200ms ease;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0);
  border: none;

  &:hover {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.active} {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
    box-shadow: 0 0 0 0 rgba(0, 127, 255, 0);
  }
`);

function CustomButton(props) {
    return <ButtonUnstyled {...props} component={CustomButtonRoot} />;
}  


function FirstPage() {
    const classes = useStyles();
    const history = useHistory();
    const [valuePeriod, setValuePeriod] = React.useState('1');
    const [currentSymptom, setCurretSymptom ] = React.useState('1');
    const { t, i18n } = useTranslation();
    const [lang, setLang] = React.useState(localStorage.getItem('language'));

    // i18n.changeLanguage(localStorage.getItem("language"));
    function changeLanguage(event) {
        i18n.changeLanguage(event.target.value);
    }

    const handleChangePeriod = (event) => {
        setValuePeriod(event.target.value);
    };

    const handleChangeSymptom = (event) => {
        if(event.target.value === '0') {
            if(localStorage.getItem('projectKeyForm') === 'a82b9f65c583921083e8ff0089ed9e44ed8eb8a3' || localStorage.getItem('projectKeyForm') === 'egoey4j6avjc5rv3jkxktthj6ovqkubmdhg2hrq6'){
                localStorage.setItem("totalPageDailyForm", "4");
            }else {
                localStorage.setItem("totalPageDailyForm", "5");
            }
        }else {
            if(localStorage.getItem('projectKeyForm') === 'a82b9f65c583921083e8ff0089ed9e44ed8eb8a3' || localStorage.getItem('projectKeyForm') === 'egoey4j6avjc5rv3jkxktthj6ovqkubmdhg2hrq6'){
                localStorage.setItem("totalPageDailyForm", "6");
            }else {
                localStorage.setItem("totalPageDailyForm", "7");
            }
        }
        setCurretSymptom(event.target.value);
    };

    const handleChangeLanguage = (event) => {
        setLang(event.target.value);
        localStorage.setItem('language', event.target.value);
        i18n.changeLanguage(localStorage.getItem("language"));
    };

    const controlProps = (item) => ({
        checked: valuePeriod === item,
        onChange: handleChangePeriod,
        value: item,
        name: 'color-radio-button',
        inputProps: { 'aria-label': item },
      });

    const controlPropsSymptoms = (item) => ({
        checked: currentSymptom === item,
        onChange: handleChangeSymptom,
        value: item,
        name: 'color-radio-button',
        inputProps: { 'aria-label': item },
    });

    const routeChange = () =>{ 
        let path = "";
        localStorage.setItem("period", valuePeriod);
        if(currentSymptom === '0') {
            localStorage.setItem("currentSymptom", false);
            path = `/oxygenConcentration`; 
            history.push(path, {symptoms: currentSymptom, period: valuePeriod });
            
        }else{
            localStorage.setItem("currentSymptom", true);
            path = `/SymptomsPage1`; 
            history.push(path, {symptoms: currentSymptom, period: valuePeriod });
        }
        
    }

    return (
        <ThemeProvider theme={THEME}>
        <br/>
        <div className={classes.body}>
            <div style={{float : 'right', marginRight: '18px'}}>
                <ToggleButtonGroup
                    color="primary"
                    value={lang}
                    exclusive
                    onChange={handleChangeLanguage}
                    size="small"
                >
                    <ToggleButton value="en">English</ToggleButton>
                    <ToggleButton value="th">ภาษาไทย</ToggleButton>
                </ToggleButtonGroup>
            </div>
            <br/>

            <Box sx={{ flexGrow: 1 }} pt={3}>
                <Grid container pl={2} pr={2}>
                    
                    <Grid item  md={12} xs={12}>
                        <Card sx={{ minWidth: 275 }} variant="outlined" style={{borderRadius: '15px', borderColor: '#D2C4F8'}}>
                            <CardContent style={{textAlign: 'center'}}>
                                <span className={classes.textColor}>
                                    <span className="font-face-pb">
                                        {/* รายงานอาการขณะพักรักษาตัว */}
                                        {t('healthReport')}
                                    </span>
                                      
                                </span>

                                <br/>
                                
                                <p className={classes.size12}>
                                    <span className="font-face-pr">
                                    {
                                        localStorage.getItem("project") === 'vibharam' ?
                                        <>
                                            {t('heatlhReportTextVibharam')}
                                        </>
                                        :
                                        <>
                                            {t('heatlhReportText')}
                                        </>
                                    }
                                    </span>  
                                </p>
                            </CardContent>
                        </Card>
                    </Grid>
                    
                </Grid>     
            </Box>
            
            <Box sx={{ flexGrow: 1 }} pt={3}>
                <Grid container pl={2} pr={2}>
                    
                    <Grid item  md={12} xs={12}>
                        <Card sx={{ minWidth: 275 }} variant="outlined" style={{borderRadius: '15px', borderColor: '#D2C4F8'}}>
                        <span className="font-face-ps"> 
                            <CardHeader
                                subheader={t('assessmentPeriod')}
                                subheaderTypographyProps={{ paddingLeft:'10px', color:'#fff', fontSize: '12px' }}
                                style={{ backgroundColor: '#7F51F5',color: '#fff'}}

                            />
                        </span>
                            <CardContent>
                            {
                                localStorage.getItem("project") === 'vibharam' ?

                                <FormGroup style={{ paddingLeft: '10px' }}>
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            aria-label="period"
                                            name="radio-buttons-group"
                                            value={valuePeriod}
                                            onChange={handleChangePeriod}
                                        >
                                            <FormControlLabel
                                            control={<Radio {...controlProps('1')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 18,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>} 
                                                        
                                                        label={<Typography style={{fontSize: '12px'}}>{t('morning')}</Typography>}/>
                                            <FormControlLabel
                                            control={<Radio 
                                                {...controlProps('2')}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 18,
                                                    },
                                                    color: '#7F51F5',
                                                    '&.Mui-checked': {
                                                        color: '#7F51F5',
                                                    },
                                                }}
                                            />} 
                                            label={<Typography className="font-face-ps" style={{fontSize: '12px'}}>{t('afternoon')}</Typography>}/>

                                            <FormControlLabel
                                            control={<Radio 
                                                {...controlProps('3')}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 18,
                                                    },
                                                    color: '#7F51F5',
                                                    '&.Mui-checked': {
                                                        color: '#7F51F5',
                                                    },
                                                }}
                                            />} 
                                            label={<Typography className="font-face-ps" style={{fontSize: '12px'}}>{t('evening')}</Typography>}/>
                                        </RadioGroup>
                                    </FormControl>
                                </FormGroup> 

                                :

                                <FormGroup style={{ paddingLeft: '10px' }}>
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            aria-label="period"
                                            name="radio-buttons-group"
                                            value={valuePeriod}
                                            onChange={handleChangePeriod}
                                        >
                                            <FormControlLabel
                                            control={<Radio {...controlProps('1')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 18,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>} 
                                                        
                                                        label={<Typography style={{fontSize: '12px'}}>{t('moringPeriod')}</Typography>}/>
                                            <FormControlLabel
                                            control={<Radio 
                                                {...controlProps('2')}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 18,
                                                    },
                                                    color: '#7F51F5',
                                                    '&.Mui-checked': {
                                                        color: '#7F51F5',
                                                    },
                                                }}
                                            />} 
                                            label={<Typography className="font-face-ps" style={{fontSize: '12px'}}>{t('afternoonPeriod')}</Typography>}/>
                                        </RadioGroup>
                                    </FormControl>
                                </FormGroup>
                                
                            }
                                
                            </CardContent>
                        </Card>
                    </Grid>
                    
                </Grid>     
            </Box>

            <Box sx={{ flexGrow: 1 }} pt={3}>
                <Grid container pl={2} pr={2}>
                    
                    <Grid item  md={12} xs={12}>
                        <Card sx={{ minWidth: 275 }} variant="outlined" style={{borderRadius: '15px', borderColor: '#D2C4F8'}}>
                        <CardHeader
                            subheader={t('symptoms')}
                            subheaderTypographyProps={{color:'#fff', fontSize: '12px' }}
                            style={{ paddingLeft: '24px', backgroundColor: '#7F51F5',color: '#fff' }}

                        />
                            <CardContent>
                                <FormGroup style={{ paddingLeft: '10px' }}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend" style={{color: 'black', fontSize: '13px'}}>
                                        {t('symptomsTitle')} 
                                            <span className={classes.redText}>*</span>
                                        </FormLabel>
                                        <RadioGroup
                                            aria-label="symptom"
                                            name="radio-symptom-group"
                                            value={currentSymptom}
                                            onChange={handleChangeSymptom}
                                        >
                                            <FormControlLabel
                                            control={<Radio {...controlPropsSymptoms('0')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 18,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>} 
                                                        
                                                        label={<Typography style={{fontSize: '12px'}}>{t('symptomsNo')}</Typography>}/>
                                            <FormControlLabel
                                            control={<Radio 
                                                {...controlPropsSymptoms('1')}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 18,
                                                    },
                                                    color: '#7F51F5',
                                                    '&.Mui-checked': {
                                                        color: '#7F51F5',
                                                    },
                                                }}
                                            />} 
                                            label={<Typography style={{fontSize: '12px'}}>{t('symptomsYes')}</Typography>}/>
                                        </RadioGroup>
                                    </FormControl>
                                </FormGroup>
                            </CardContent>
                        </Card>
                    </Grid>
                    
                </Grid>     
            </Box>

            <Box sx={{ flexGrow: 1 }} pt={3}>
                <Grid container pl={2} pr={2} direction="column"
                    alignItems="center"
                    justify="center">
                    
                        <Grid item  md={12} xs={12}>
                            <div className={classes.appbar}>
                                <CustomButton onClick={routeChange}>NEXT</CustomButton>
                            </div>
                        </Grid>
                </Grid>     
            </Box>
           
            
            <Box sx={{ flexGrow: 1 }} pt={3}>
                <Grid container pl={2} pr={2} direction="column">
                    
                        <Grid item  md={12} xs={12}>
                            <div className={classes.appbar} style={{fontSize: '11px', color: 'grey' , fontWeight: 'bold', float: 'right'}}>
                                { "Page |" + " 1/" + localStorage.getItem('totalPageDailyForm') }
                            </div>
                        </Grid>
                </Grid>     
            </Box>

            <br/>
        </div>
        </ThemeProvider>
    )
}

export default FirstPage
