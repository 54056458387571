import React, {useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Image from '../assets/bg.jpg'; 
import thanksImage from '../assets/Banner_docthank.png';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { deepPurple } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import {Grid, Box, Card, CardContent, CardHeader,
    Radio ,RadioGroup , Typography,
    FormGroup , FormControlLabel , FormControl , FormLabel
} from '@mui/material';
import { useLocation } from "react-router-dom";
import { ImHome3 } from 'react-icons/im';
import { textAlign } from '@mui/system';

const THEME = createTheme({
    typography: {
     "fontFamily": `"PromptMedium","PromptSemiBold", "PromptRegular","PromptBold"`
    }
 });

const styles = {
    container: {
        backgroundImage: `url(${Image})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100vw',
        height: '100vh',
        marginTop: "-3%"
    },
    newLine : {
        whiteSpace: 'pre-wrap',
        fontSize: '15px'
    }

    // center : {
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     height:'100vh'
    // }
};

const useStyles = makeStyles({
    appbar: {
        margin: 'auto',
        marginTop:'150px'

    },
    textColor: {
        color: '#7F51F5',
        textAlign: 'center'
    },
    greenText: {
        color:'#46C74B',
        textAlign: 'center',
        fontSize: '16px'
    },

    normalTextColor: {
        color: '#555555',
        textAlign: 'center'
    }
});

function Deny2ndAssessment() {
    const classes = useStyles();
    const location = useLocation();
    const alertText = location.state.alert;
    // const alertText = 'ยังไม่ครบกำหนดในการทำแบบประเมิน ครั้งที่ 3 \nท่านสามารถทำแบบประเมินได้ใน วันที่ 14 ของการรักษา'
    console.log('alert text:', alertText);
    const [showThankyou, setShowThankyou] = React.useState(false);

    useEffect(() => {
        checkAlertText();
    }, []); 

    const checkAlertText = () => {
        if(alertText === "ขอบคุณที่ทำแบบประเมิน\nท่านได้ทำแบบประเมินครบแล้ว"){
            setShowThankyou(true);
            console.log("true", alertText);
        }else {
            setShowThankyou(false);
            console.log("false", alertText);
        }
    }
    
    return (
        <>
            <ThemeProvider theme={THEME}>
                
                <Paper elevation={0} style={styles.container}>
                    {
                        showThankyou ? <>
                            <img src={thanksImage} alt="Thank you" width="100%"/>
                            <br/> 
                            <br/><br/>
                            <span className={classes.textColor}> 
                                <h3 className="font-face-pb">
                                    THANK YOU
                                </h3>
                                <br/>
                            </span>
                            <span className="font-face-pr">
                                <span className={classes.greenText}>
                                     <p>ขอบคุณที่ทำแบบประเมิน</p>
                                </span>
                                
                                <span style={{fontSize: '16px', textAlign:'center'}}>
                                    <p>ท่านได้ทำแบบประเมินครบแล้ว</p>
                                </span>
                            </span>
                        </>
                        :
                        <Grid container pl={2} pr={2}>
                                <div className={classes.appbar} style={{ alignItems: 'center'}}>

                                    <span style={{paddingLeft: '42%'}}>
                                        <ErrorOutlineIcon sx={{ fontSize: 50, color: deepPurple[300] }}/> 
                                    </span>
                                    <br/>
                                    <br/>     
                                    <span className={classes.normalTextColor}> 
                                        <h3 className="font-face-pr">
                                            ขออภัย
                                        </h3>
                                        <br/>
                                        <p className="font-face-pr" style={styles.newLine}>
                                            {alertText}
                                        </p>
                                    </span>                             
                                </div>

                               
                        </Grid> 
                    }
                    
                </Paper>
            </ThemeProvider>
        </>
    )
}

export default Deny2ndAssessment
