import React , {useEffect} from 'react';
import { useLocation } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles, withStyles } from '@mui/styles';
import {Grid, Box, Card, CardContent, CardHeader,
    Typography,Checkbox , Input,
    FormGroup , FormControlLabel , FormControl , FormLabel, ToggleButton, ToggleButtonGroup 
} from '@mui/material';
import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/core/ButtonUnstyled';
import { styled } from '@mui/system';
import { useHistory } from "react-router-dom";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import * as htmlToImage from 'html-to-image';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import TextField from '@mui/material/TextField';
import signature from '../assets/signature.png';
import sign from '../assets/sign.png';
import hospitalLogo from '../assets/hospitalLogo.png';
import axios from 'axios';
import sign_prison from '../assets/signature_prison.png';
import logo from '../assets/takuapaLogo.png';

const currentURL = window.location.href;
var splitUrl;
localStorage.removeItem("doctor_cert_id");
localStorage.removeItem("doctor_cert_date");
localStorage.removeItem("doctor_cert_date_eng");
localStorage.removeItem("fullname");
localStorage.removeItem("age_y");
localStorage.removeItem("hn");
localStorage.removeItem("an");
localStorage.removeItem("vstdate");
localStorage.removeItem("diag_text");
localStorage.removeItem("symptom_eng");
localStorage.removeItem("symptom");
localStorage.removeItem("note1");
localStorage.removeItem("note2");
localStorage.removeItem("date1");
localStorage.removeItem("date1_eng");
localStorage.removeItem("formatted_date");
localStorage.removeItem("date2");
localStorage.removeItem("date2_eng");
localStorage.removeItem("ill_date_count");
localStorage.removeItem("name");
localStorage.removeItem("licenseno");
localStorage.removeItem("confirm_illness");
localStorage.removeItem("confirm_hospital_visit");
 
if(currentURL.includes("cid=")){
    splitUrl = currentURL.split('cid=');
    var splitCid = splitUrl[1].split('&')
    console.log('splitcid:', splitCid);
    var  cId= splitCid[0];
    localStorage.setItem("cid", cId);    
}else{
    localStorage.setItem("cid", "");
}
console.log("cid:", localStorage.getItem("cid"));

if(currentURL.includes("projectKey=")){
    splitUrl = currentURL.split('projectKey=');
    var projectKeySplit = splitUrl[1].split('&');
    var projectKey = projectKeySplit[0];
    localStorage.setItem("projectKeyForm", projectKey);    
}else{
    localStorage.setItem("projectKeyForm", "xrsponolu71cznf0zr4pc9g3le5qghobbyigowog");
}

if(currentURL.includes("token=")){
    var splitUrlToken = currentURL.split('token=');
    var tokenSplit = splitUrlToken[1].split('&');
    var  token = tokenSplit[0];
    localStorage.setItem("tokenForm", token);
}
else{
    localStorage.setItem("tokenForm", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6Mjc2OTUsImlhdCI6MTY0MzA5MDA0NH0.5tcQ7gRFal7MeEuL0ktotX2GEV4bpAFUEhhRfcAFB1A");
}
const THEME = createTheme({
    // typography: {
    //  "fontFamily": `"PromptMedium","PromptSemiBold", "PromptRegular","PromptBold"`
    // },palette: {
    //     primary: {
    //       main: '#7f51f5',
    //     }
    //   },
 });

const useStyles = makeStyles({
    body : {
        marginTop: '-3%'
    },
    headerTitle : {
        fontWeight: 'bold',
    },
    appbar: {
        alignItems: 'center',
    },
    textColor: {
        fontSize: '18px',
        color: '#7F51F5'
    },
    redText: {
        color: 'red',
        textAlign: 'center',
        fontSize: '14px'
    },
    iconSize: {
        position: 'absolute',
        paddingLeft: '10px',
        marginTop: '15px'
    }
});

const label = { inputProps: { 'aria-label': 'Checkbox' } };

const CustomButtonRoot = styled('button')(`
  background-color: #7F51F5;
  padding: 15px 50px;
  border-radius: 20px;
  color: #fff;
  font-weight: 600;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  transition: all 200ms ease;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0);
  border: none;

  &:hover {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.active} {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }
`);

const CustomButtonRootBack = styled('button')(`
  background-color: #c7c7c7;
  padding: 15px 50px;
  border-radius: 20px;
  color: #fff;
  font-weight: 600;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  transition: all 200ms ease;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0);
  border: none;

  &:hover {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.active} {
    background-color: #c7c7c7;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }
`);

function CustomCloseBack(props) {
    return <ButtonUnstyled {...props} component={CustomButtonRootBack} />;
}  

function CustomButton(props) {
    return <ButtonUnstyled {...props} component={CustomButtonRoot} />;
}

function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    return null;
  }

function MedicalCertificate() {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    var choiceData = "";

    const [state, setState] = React.useState({
        checkBox1: false,
        checkBox2: false,
        checkBox3: false,
        checkBox4: false,
        checkBox5: false});
    const [checkBox6, setCheckBox6] = React.useState(false)
    const [disabled, setDisabled] = React.useState(false);
    const [checkInput, setCheckInput] = React.useState (false);
    const [lang, setLang] = React.useState('th');
    const [showPage, setShowPage ] = React.useState(false);
    // const [medicalData, setMedicalData ] = React.useState({});
    const {doctid, setDoctId } = React.useState('');
    // var medicalData = {};
    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };

    const changeBuddishtYear = (date) => {
        let splitDateFormat; 
        let returnDate = "";
        
        if(date){
            splitDateFormat= date.split("-");
            let changeYear = parseInt(splitDateFormat[0]) + 543 ;
            returnDate = splitDateFormat[2] + "/" + splitDateFormat[1] + "/" + changeYear ;
        }
        return returnDate;
    } 

    let medicalData = {};
    let doctor_cert_id ;
     const getMedicalData = () =>{
       // setLoading(true);
       const headers = {
        'Project-Key': localStorage.getItem("projectKeyForm"),
        'Authorization': "Bearer " + localStorage.getItem("tokenForm")
        };
        // axios.get('http://1.0.202.5:11111/sm/get_cid.php?cid=' + localStorage.getItem("cid"),{ headers })
    //    axios.all([axios.get('/medical'), axios.get('/sm/get_cid.php?cid=' + localStorage.getItem("cid"),{ headers })])
        axios.get('https://api.space-trax.com/v2/pocc/patient-isolation/medical-cert?cid=' + localStorage.getItem("cid"),{ headers })
       .then(response => {
           medicalData = response.data;
           if(response.data.success === false) {
               history.push("/noMedicalCertificate");
           }
           else{
            if(medicalData.doctor_cert_id){
                localStorage.setItem('doctor_cert_id', medicalData.doctor_cert_id);
               }
               
               if(medicalData.doctor_cert_date){
                let changeDate = changeBuddishtYear(medicalData.doctor_cert_date);
                localStorage.setItem('doctor_cert_date', changeDate);
                localStorage.setItem('doctor_cert_date_eng', medicalData.doctor_cert_date);
               }
               if(medicalData.fullname){
                   localStorage.setItem('fullname', medicalData.fullname);
               }
            //    localStorage.setItem('fullname', medicalData.fullname);
               if(medicalData.age_y){
                localStorage.setItem('age_y', medicalData.age_y);
               }
    
               if(medicalData.hn){
                localStorage.setItem('hn', medicalData.hn);
               }
               
               if(medicalData.an){
                localStorage.setItem('an', medicalData.an);
               }
    
               if(medicalData.vstdate) {
                let changeDate = changeBuddishtYear(medicalData.vstdate);
                localStorage.setItem('vstdate', changeDate);
               }
    
            //    if(medicalData.diag_text){
            //         localStorage.setItem('diag_text', medicalData.diag_text);
            //    }
               localStorage.setItem('diag_text',"ติดเชื้อโควิด 19");
               if(medicalData.symptom){
                localStorage.setItem('symptom_eng', medicalData.symptom);
               }

                localStorage.setItem('symptom', "ติดเชื้อโคโรนา ไวรัส 2019");
            //    }    
                if(medicalData.note1){
                    localStorage.setItem('note1', medicalData.note1);
                    // localStorage.setItem('note1', "ได้รับการดูแลรักษาจากโรงพยาบาลและกักตัวจริง");
                } 
    
                if(medicalData.note2){
                    localStorage.setItem('note2', medicalData.note2);
                }
    
                if(medicalData.date1){
                    let changeDate = changeBuddishtYear(medicalData.date1);
                    localStorage.setItem('date1', changeDate);
                    localStorage.setItem('date1_eng', medicalData.date1);
                    let date = new Date(medicalData.date1);
                    let infectedDate = date.setDate(date.getDate() - 1);
                    let formateDate = new Date(infectedDate);
                    let formatted_date;
                    if(formateDate.getMonth() + 1 < 10){
                        formatted_date = formateDate.getFullYear() + "-0" + (formateDate.getMonth() + 1) + "-" + formateDate.getDate();
                    }else{
                        formatted_date = formateDate.getFullYear() + "-" + (formateDate.getMonth() + 1) + "-" + formateDate.getDate();
                    }
                    
                    console.log("formatted date: ", formatted_date);
                    localStorage.setItem('formatted_date', formatted_date);
                }
    
                if(medicalData.date2){
                    let changeDate = changeBuddishtYear(medicalData.date2);
                    localStorage.setItem('date2', changeDate);
                    localStorage.setItem('date2_eng', medicalData.date2);
                }
               
                if(medicalData.ill_date_count){
                    localStorage.setItem('ill_date_count', medicalData.ill_date_count);
                }else{
                    const date1 = new Date(medicalData.date1);
                    const date2 = new Date(medicalData.date2);

                    // One day in milliseconds
                    const oneDay = 1000 * 60 * 60 * 24;

                    // Calculating the time difference between two dates
                    const diffInTime = date2.getTime() - date1.getTime() ;

                    // Calculating the no. of days between two dates
                    const diffInDays = Math.round(diffInTime / oneDay) + 1;

                    console.log("day diff:", diffInDays );

                    localStorage.setItem('ill_date_count', diffInDays);
                }

                if(medicalData.name){
                    localStorage.setItem('name', medicalData.name);
                }
    
                if(medicalData.licenseno){
                    localStorage.setItem('licenseno', medicalData.licenseno);
                }
    
                if(medicalData.confirm_illness) {
                    localStorage.setItem('confirm_illness', medicalData.confirm_illness);
                    setState({
                        ...state,
                        checkBox1: true,
                    });
                }
    
                if(medicalData.confirm_hospital_visit) {
                    localStorage.setItem('confirm_hospital_visit', medicalData.confirm_hospital_visit);
                    setState({
                        ...state,
                        checkBox2: true,
                    });
                }
    
                
           }
           console.log('medical data:', medicalData);
       })
       .catch(error => {
           // setLoading(false);
           // handleClickOpenError();
           localStorage.clear();
           console.log("error");        });
    }

    const getMedicalDataPrison = () =>{
        // setLoading(true);
        const headers = {
         'Project-Key': localStorage.getItem("projectKeyForm"),
         'Authorization': "Bearer " + localStorage.getItem("tokenForm")
         };
         // axios.get('http://1.0.202.5:11111/sm/get_cid.php?cid=' + localStorage.getItem("cid"),{ headers })
     //    axios.all([axios.get('/medical'), axios.get('/sm/get_cid.php?cid=' + localStorage.getItem("cid"),{ headers })])
         axios.get('https://api.space-trax.com/v2/dashboard/patient-admitted/' + localStorage.getItem("cid") + "/medical-cert",{ headers })
        .then(response => {
            medicalData = response.data.data;
            console.log('medical data in takuapa api :', medicalData);
            if(medicalData.date_finished){
                // let changeDate = changeBuddishtYear(medicalData.doctor_cert_date);
                localStorage.setItem('doctor_cert_date', medicalData.date_finished);
                localStorage.setItem('doctor_cert_date_eng', medicalData.date_finished);
                console.log("cert_date", medicalData.date_finished);
            }

            if(medicalData.name){
                localStorage.setItem('fullname', medicalData.name + " " + medicalData.surname);
            }

            if(medicalData.age){
                localStorage.setItem('age_y', medicalData.age);
            }

            if(medicalData.date_admitted) {
                localStorage.setItem('vstdate', medicalData.date_admitted);
               }

            if(medicalData.date_admitted){
                localStorage.setItem('date1', medicalData.date_admitted);
                localStorage.setItem('date1_eng', medicalData.date_admitted);
                let date = new Date(medicalData.date_admitted);
                let infectedDate = date.setDate(date.getDate() - 1);
                let formateDate = new Date(infectedDate);
                let formatted_date;
                if(formateDate.getMonth() + 1 < 10){
                    formatted_date = formateDate.getDate()+ "/0" + (formateDate.getMonth() + 1) + "/" + formateDate.getFullYear();
                }else{
                    formatted_date = formateDate.getDate() + "/" + (formateDate.getMonth() + 1) + "/" + formateDate.getFullYear();
                }
                
                console.log("formatted date: ", formatted_date);
                localStorage.setItem('formatted_date', formatted_date);
            }

            if(medicalData.date_finished){
                localStorage.setItem('date2', medicalData.date_finished);
                localStorage.setItem('date2_eng', medicalData.date_finished);
            }

            localStorage.setItem('diag_text',"ติดเชื้อโควิด 19");
            //    if(medicalData.symptom){
            //     localStorage.setItem('symptom_eng', medicalData.symptom);
            //    }

            localStorage.setItem('symptom', "ติดเชื้อโคโรนา ไวรัส 2019");

            const date1 = new Date(medicalData.date_admitted);
            const date2 = new Date(medicalData.date_finished);

            // One day in milliseconds
            const oneDay = 1000 * 60 * 60 * 24;

            // Calculating the time difference between two dates
            const diffInTime = date2.getTime() - date1.getTime() ;

            // Calculating the no. of days between two dates
            const diffInDays = Math.round(diffInTime / oneDay) + 1;

            console.log("day diff:", diffInDays );

            localStorage.setItem('ill_date_count', '10');
            localStorage.setItem('identify_number',  medicalData?.identify_number);

            setState({
                ...state,
                checkBox2: true,
            });
        })
        .catch(error => {
            // setLoading(false);
            // handleClickOpenError();
            console.log("error");        });
     }

    useEffect(() => {
        if(localStorage.getItem("projectKeyForm") === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23'){
            getMedicalDataPrison();
        }else {
            getMedicalData();
        }
        
    }, []); 


    const handleChangeDisable = (event) => {
        setCheckBox6(event.target.checked);
        console.log("check box 6:", event.target.checked);
        if (event.target.checked){
            setState({
                checkBox1: false,
                checkBox2: false,
                checkBox3: false,
                checkBox4: false,
                checkBox5: false});
            setDisabled(true);
        }else {
            setDisabled(false);
        }
    }

    const handleChangeLanguage = (event) => {
        console.log("language change:" , event.target.value);
        setLang(event.target.value);
        localStorage.setItem('language', event.target.value);
        i18n.changeLanguage(localStorage.getItem("language"));
    };

    const { checkBox1, checkBox2, checkBox3, 
        checkBox4, checkBox5} = state;

    

    const onCapture = () =>{
        htmlToImage.toPng(document.getElementById("savePNG"))
            .then(function (dataUrl) {
            // saveAs(dataUrl,  'Medical_Certificate.png');
                const pdf = new jsPDF();
                var link = document.createElement('a');
                link.download = 'my-image-name.png';
                const imgProps= pdf.getImageProperties(dataUrl);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                console.log("pdfHeight:", pdfHeight, imgProps.height, pdfWidth);
                if(window.innerWidth <= '600'){
                    pdf.addImage(dataUrl, 'PNG', 15, 0, 180, 299, undefined,'FAST');
                }else{
                    pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight, undefined,'FAST');
                }
                // pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight, undefined,'FAST');
                pdf.autoPrint({variant: 'non-conform'});
                window.open(pdf.output('bloburl'), '_blank');
                pdf.save("Medical Certificate.pdf");
            });
    }

        return (
            <ThemeProvider theme={THEME}>
            <br/> 
            <div className={classes.body}>
                <div style={{float : 'right', marginRight: '18px'}}>
                    <ToggleButtonGroup
                        color="primary"
                        value={lang}
                        exclusive
                        onChange={handleChangeLanguage}
                        size="small"
                    >
                        <ToggleButton value="en">English</ToggleButton>
                        <ToggleButton value="th">ภาษาไทย</ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </div>
            <div className={classes.body} id="savePNG">
                <ScrollToTopOnMount/>
                
                <br/> 
                <br/> 
                {
                    lang === 'th' ?
                    <Box sx={{ flexGrow: 1 }} pt={3}>
                    <Grid container pl={2} pr={2}>
                        <Grid item  md={12} xs={12}>
                            <div>
                                {
                                    localStorage.getItem('projectKeyForm') === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23' ?
                                    <></>
                                    :
                                    <>
                                        <span style={{fontSize: '2vw'}}>เลขที่ : {" "}</span>
                                        <span style={{fontSize: '2vw', fontWeight: 'bold'}}>{localStorage.getItem("doctor_cert_id")}</span>
                                    </>
                                }
                                
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div style={{textAlign: 'center', paddingTop: '20px'}}>
                                    <span style={{fontSize: '3vw', fontWeight:'bold'}}>
                                        ใบรับรองแพทย์
                                    </span>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{textAlign: 'center', paddingTop: '10px'}}>
                                {
                                    localStorage.getItem('projectKeyForm') === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23' ?
                                    <>
                                        <span style={{fontSize: '3vw', fontWeight:'bold'}}>
                                            TAKUAPA HOSPITAL, PHANGNGA
                                        </span>
                                    </>
                                    :
                                    <>
                                        <span style={{fontSize: '3vw', fontWeight:'bold'}}>
                                            โรงพยาบาลถลาง กระทรวงสาธารณสุข
                                        </span>
                                    </>
                                }
                                    
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{textAlign: 'center',paddingTop: '5px'}}>
                                {
                                    localStorage.getItem('projectKeyForm') === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23' ?
                                    <></>
                                    :
                                    <>
                                        <span style={{textAlign: 'center', fontSize: '2vw', color: '#343333'}}> 
                                            358 หมู่ 1ถนนเทพกษัตรี ตำบลเทพกระษัตรี อำเภอถลาง จังหวัดภูเก็ต
                                        </span> 
                                    </>
                                }
                                
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            
                            <div style={{textAlign: 'center', fontSize: '2vw', color: '#343333'}}>
                                {
                                    localStorage.getItem('projectKeyForm') === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23' ?
                                    <></>
                                    :
                                    <>
                                        <span style={{textAlign: 'center', fontSize: '2vw', color: '#343333'}}> 
                                            โทร 076-311033-4 โทรสาร. 076-311033
                                        </span> 
                                    </>
                                }
                                
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div>
                                <p style={{textAlign: 'right', fontSize: '2vw', paddingTop: '20px', paddingRight: '63px'}}>
                                วันที่: {" "}
                                <span style={{fontSize: '2vw', fontWeight: 'bold'}}>{ localStorage.getItem("doctor_cert_date")}</span>
                                </p>
                            </div>
                            
                        </Grid>
                        
                    </Grid> 
                    {
                        localStorage.getItem("projectKeyForm") === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23'?
                        <Grid container pl={2} pr={2}>
                            <Grid item xs={12}>
                                <div>
                                    <span style={{ fontSize: '2vw',color: '#343333'}}>
                                    ชื่อผู้ป่วย 
                                        <TextField 
                                        
                                        id="margin-none"
                                        InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold', color: 'black' } }}
                                        style={{marginRight: '10px', width: '59vw'}}
                                        variant='standard'
                                        value={localStorage.getItem('fullname')}
                                        />
                                        อายุ:
                                        <TextField 
                                        
                                        id="margin-none"
                                        InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' } }}
                                        variant='standard'
                                        style={{width: '17vw'}}
                                        value={localStorage.getItem('age_y')}
                                        />ปี
                                    </span>
                                </div>
                            </Grid>
                        </Grid>
                        :
                        <Grid container pl={2} pr={2}>
                            <Grid item xs={12}>
                                <div>
                                    <span style={{ fontSize: '2vw',color: '#343333'}}>
                                    ชื่อผู้ป่วย
                                        <TextField 
                                        
                                        id="margin-none"
                                        InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold', color: 'black' } }}
                                        style={{marginRight: '10px', width: '30vw'}}
                                        variant='standard'
                                        value={localStorage.getItem('fullname')}
                                        // name="other_complication"
                                        // onChange = {
                                        //   (event) => {
                                        //     set_other_complication(event.target.value);
                                        //     setVal(event.target.name, event.target.value);
                                        //   }
                                        // }
                                        />
                                        อายุ
                                        <TextField 
                                        
                                        id="margin-none"
                                        InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' } }}
                                        variant='standard'
                                        style={{width: '9vw'}}
                                        value={localStorage.getItem('age_y')}
                                        // value={other_complication}
                                        // name="other_complication"
                                        // onChange = {
                                        //   (event) => {
                                        //     set_other_complication(event.target.value);
                                        //     setVal(event.target.name, event.target.value);
                                        //   }
                                        // }
                                        />ปี

                                        เลขที่บัตร
                                        <TextField 
                                        id="margin-none"
                                        InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold', color: 'black' } }}
                                        style={{ width: '27vw'}}
                                        variant='standard'
                                        value={localStorage.getItem('hn')}
                                        // name="other_complication"
                                        // onChange = {
                                        //   (event) => {
                                        //     set_other_complication(event.target.value);
                                        //     setVal(event.target.name, event.target.value);
                                        //   }
                                        // }
                                        />
                                    </span>
                                </div>
                            </Grid>
                        </Grid>   
                    }
                        
                    {/* {/* <br/> */}  
                    <Grid container pl={2} pr={2}>
                        <Grid item xs={12}>
                            <div>
                                <span style={{ fontSize: '2vw',color: '#343333'}}>
                                    ได้มารับการตรวจ ณ โรงพยาบาลแห่งนี้ เมื่อวันที่
                                    <TextField 
                                    
                                    id="margin-none"
                                    InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' } }}
                                    style={{ width: '50vw'}}
                                    variant='standard'
                                    value={localStorage.getItem('vstdate')}
                                    // name="other_complication"
                                    // onChange = {
                                    //   (event) => {
                                    //     set_other_complication(event.target.value);
                                    //     setVal(event.target.name, event.target.value);
                                    //   }
                                    // }
                                    />
                                    
                                </span>
                            </div>
                        </Grid>
                    </Grid>  

                    {/* {/* <br/> */} 
                    <Grid container pl={2} pr={2}>
                        <Grid item xs={12}>
                            <div>
                                <span style={{ fontSize: '2vw',color: '#343333'}}>
                                    ให้การวินิจฉัยว่าเป็นโรค 
                                    <TextField 
                                    id="margin-none"
                                    InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' } }}
                                    style={{ width: '70vw'}}
                                    variant='standard'
                                    value={localStorage.getItem('diag_text')}
                                    // name="other_complication"
                                    // onChange = {
                                    //   (event) => {
                                    //     set_other_complication(event.target.value);
                                    //     setVal(event.target.name, event.target.value);
                                    //   }
                                    // }
                                    />
                                    
                                </span>
                            </div>
                        </Grid>
                    </Grid>  
                    {/* {/* <br/> */} 
                    <Grid container pl={2} pr={2}>
                        <Grid item xs={12}>
                            <div>
                                <span style={{ fontSize: '2vw',color: '#343333'}}> 
                                    <TextField 
                                        disabled
                                    id="margin-none"
                                    InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' } }}
                                    style={{ width: '89.2vw'}}
                                    variant='standard'
                                    // value={other_complication}
                                    // name="other_complication"
                                    // onChange = {
                                    //   (event) => {
                                    //     set_other_complication(event.target.value);
                                    //     setVal(event.target.name, event.target.value);
                                    //   }
                                    // }
                                    />
                                    
                                </span>
                            </div>
                        </Grid>
                    </Grid>  
                    
                    {/* {/* <br/> */}
                    <Grid container pl={2} pr={2}>
                        <Grid item xs={12}>
                            <div>
                                <span style={{ fontSize: '2vw',color: '#343333'}}> 
                                มีอาการ
                                    <TextField 
                                    
                                    id="margin-none"
                                    InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' } }}
                                    style={{ width: '83vw'}}
                                    variant='standard'
                                    value={localStorage.getItem('symptom')}
                                    // name="other_complication"
                                    // onChange = {
                                    //   (event) => {
                                    //     set_other_complication(event.target.value);
                                    //     setVal(event.target.name, event.target.value);
                                    //   }
                                    // }
                                    />
                                    
                                </span>
                            </div>
                        </Grid>
                    </Grid>  

                    {/* {/* <br/> */} 
                    <Grid container pl={2} pr={2}>
                        <Grid item xs={12}>
                            <div>
                                <span style={{ fontSize: '2vw',color: '#343333'}}> 
                                <TextField 
                                    disabled
                                    id="margin-none"
                                    InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' } }}
                                    style={{ width: '89.2vw'}}
                                    variant='standard'
                                    // value={localStorage.getItem('note1')}
                                    // name="other_complication"
                                    // onChange = {
                                    //   (event) => {
                                    //     set_other_complication(event.target.value);
                                    //     setVal(event.target.name, event.target.value);
                                    //   }
                                    // }
                                    />
                                    
                                    
                                </span>
                            </div>
                        </Grid>
                    </Grid> 

                    {/* {/* <br/> */} 
                    <Grid container pl={2} pr={2}>
                        <Grid item xs={12}>
                            <div>
                                <span style={{ fontSize: '2vw',color: '#343333'}}>
                                    ความเห็นแพทย์และการให้คำแนะนำ
                                </span> 
                            </div>
                            <div>
                                <span style={{ fontSize: '2vw',color: '#343333'}}> 
                                {
                                    localStorage.getItem('note1') ?
                                    <TextField 
                                        
                                        id="margin-none"
                                        InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold', paddingLeft:'80px' } }}
                                        style={{ width: '89.2vw'}}
                                        variant='standard'
                                        value={localStorage.getItem('note1')}
                                        // name="other_complication"
                                        // onChange = {
                                        //   (event) => {
                                        //     set_other_complication(event.target.value);
                                        //     setVal(event.target.name, event.target.value);
                                        //   }
                                        // }
                                        />
                                    :
                                    <TextField 
                                        disabled
                                        id="margin-none"
                                        InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold', paddingLeft:'80px' } }}
                                        style={{ width: '89.2vw'}}
                                        variant='standard'
                                        // value={localStorage.getItem('note1')}
                                        // name="other_complication"
                                        // onChange = {
                                        //   (event) => {
                                        //     set_other_complication(event.target.value);
                                        //     setVal(event.target.name, event.target.value);
                                        //   }
                                        // }
                                        />
                                }
                                    
                                    
                                    
                                    
                                </span>
                            </div>
                            <div>
                                <span style={{ fontSize: '2vw',color: '#343333'}}> 
                                    <TextField 
                                    disabled
                                    id="margin-none"
                                    InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' } }}
                                    style={{ width: '89.2vw'}}
                                    variant='standard'
                                    // value={other_complication}
                                    // name="other_complication"
                                    // onChange = {
                                    //   (event) => {
                                    //     set_other_complication(event.target.value);
                                    //     setVal(event.target.name, event.target.value);
                                    //   }
                                    // }
                                    />
                                    
                                </span>
                            </div>
                            <div>
                                <span style={{ fontSize: '2vw',color: '#343333'}}> 
                                {
                                    localStorage.getItem('note2') ?
                                    <TextField 
                                    
                                    id="margin-none"
                                    InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' } }}
                                    style={{ width: '89.2vw'}}
                                    variant='standard'
                                    value={localStorage.getItem('note2')}
                                    // name="other_complication"
                                    // onChange = {
                                    //   (event) => {
                                    //     set_other_complication(event.target.value);
                                    //     setVal(event.target.name, event.target.value);
                                    //   }
                                    // }
                                    />
                                    :
                                    <TextField 
                                    disabled
                                    id="margin-none"
                                    InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' } }}
                                    style={{ width: '89.2vw'}}
                                    variant='standard'
                                    // value={localStorage.getItem('note2')}
                                    // name="other_complication"
                                    // onChange = {
                                    //   (event) => {
                                    //     set_other_complication(event.target.value);
                                    //     setVal(event.target.name, event.target.value);
                                    //   }
                                    // }
                                    />
                                }
                                    
                                </span>
                            </div>
                            <div>
                                <span style={{ fontSize: '2vw',color: '#343333'}}> 
                                    <TextField 
                                    disabled
                                    id="margin-none"
                                    InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' } }}
                                    style={{ width: '89.2vw'}}
                                    variant='standard'
                                    // value={other_complication}
                                    // name="other_complication"
                                    // onChange = {
                                    //   (event) => {
                                    //     set_other_complication(event.target.value);
                                    //     setVal(event.target.name, event.target.value);
                                    //   }
                                    // }
                                    />
                                    
                                </span>
                            </div>
                            <div>
                                <span style={{ fontSize: '2vw',color: '#343333'}}> 
                                    <TextField 
                                    disabled
                                    id="margin-none"
                                    InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' } }}
                                    style={{ width: '89.2vw'}}
                                    variant='standard'
                                    // value={other_complication}
                                    // name="other_complication"
                                    // onChange = {
                                    //   (event) => {
                                    //     set_other_complication(event.target.value);
                                    //     setVal(event.target.name, event.target.value);
                                    //   }
                                    // }
                                    />
                                    
                                </span>
                            </div>
                        </Grid>
                    </Grid> 
                    <Grid container pl={2} pr={2} style={{paddingTop: '10px'}}>
                        <Grid item xs={12}>
                            <div>
                                <span style={{ fontSize: '2vw',color: '#343333'}}>
                                ตั้งแต่วันที่
                                    <TextField 

                                    id="margin-none"
                                    InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold', color: 'black' } }}
                                    style={{marginRight: '10px', width: '25vw'}}
                                    variant='standard'
                                    value={localStorage.getItem('date1') ? localStorage.getItem('date1') : ''}

                                    />
                                    ถึงวันที่
                                    <TextField 
                                    id="margin-none"
                                    InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' } }}
                                    variant='standard'
                                    style={{width: '22vw'}}
                                    value={localStorage.getItem('date2') ? localStorage.getItem('date2') : ''}
                                    />

                                    มีกำหนด 
                                    <TextField 
                                    id="margin-none"
                                    InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' } }}
                                    style={{ width: '16vw'}}
                                    variant='standard'
                                    value={localStorage.getItem('ill_date_count')}
                                    // name="other_complication"
                                    // onChange = {
                                    //   (event) => {
                                    //     set_other_complication(event.target.value);
                                    //     setVal(event.target.name, event.target.value);
                                    //   }
                                    // }
                                    />วัน
                                </span>
                            </div>
                        </Grid>
                    </Grid>  
                
                    <Grid container pl={2} pr={2} style={{paddingTop: '10px'}}>
                        <Grid item xs={6}>
                        <span style={{ fontSize: '2vw',color: '#343333'}}> 
                            ผู้ป่วยได้มารับการตรวจตามวันเวลาดังกล่าวจริง
                        </span>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                <Checkbox 
                                {...label}
                                // sx={{
                                // color: "#7F51F5",
                                // '&.Mui-checked': {
                                //     color: "#7F51F5",
                                // },
                                // }}
                                disabled
                                checked={checkBox1} onChange={handleChange} 
                                name="checkBox1" size="small" />
                                }
                                label={<Typography style={{fontSize: '2vw',color: '#343333'}}>ขอรับรองว่าขณะนี้ป่วยอยู่ที่ รพ. จริง</Typography>}/>
                        </Grid>
                    </Grid>

                    <Grid container pl={2} pr={2} style={{paddingTop: '10px'}}>
                        <Grid item xs={6}>
                        <span style={{ fontSize: '2vw',color: '#343333'}}> 
                            หมายเหตุ
                            <TextField 
                                disabled
                                id="margin-none"
                                InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' } }}
                                style={{ width: '30vw'}}
                                variant='standard'
                                // value={other_complication}
                                // name="other_complication"
                                // onChange = {
                                //   (event) => {
                                //     set_other_complication(event.target.value);
                                //     setVal(event.target.name, event.target.value);
                                //   }
                                // }
                                />
                        </span>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                <Checkbox 
                                {...label}
                                // sx={{
                                // color: "#7F51F5",
                                // '&.Mui-checked': {
                                //     color: "#7F51F5",
                                // },
                                // }}
                                disabled
                                checked={checkBox2} onChange={handleChange} 
                                name="checkBox2" size="small" />
                                }
                                label={<Typography style={{fontSize: '2vw',color: '#343333'}}>ขอรับรองว่ามารักษาที่ รพ. นี้จริง</Typography>}/>
                        </Grid>
                    </Grid>

                    <Grid container pl={2} pr={2} style={{paddingTop: '10px'}}>
                        <Grid item xs={6}>
                        <span style={{ fontSize: '2vw',color: '#343333'}}> 
                            ลงชื่อ
                            <TextField 
                            disabled
                                id="margin-none"
                                InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' } }}
                                style={{ width: '30vw'}}
                                variant='standard'
                                // value={other_complication}
                                // name="other_complication"
                                // onChange = {
                                //   (event) => {
                                //     set_other_complication(event.target.value);
                                //     setVal(event.target.name, event.target.value);
                                //   }
                                // }
                                />
                            {/* <br/> */}
                            <p style={{textAlign: 'center'}}>ผู้รับการตรวจ</p>
                        </span>
                        </Grid>
                        <Grid item xs={6}>
                            {
                                localStorage.getItem("projectKeyForm") === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23' ?
                                <>
                                     <span style={{ fontSize: '2vw',color: '#343333'}}> 
                                        (ลงชื่อ)
                                        <img src={sign_prison} alt="signature" width="60%" style={{marginLeft: '-10%'}}/>
                                    
                                    <p style={{fontWeight: 'bold', color: 'black', marginLeft: '60px'}}>

                                        (นพ.กชกร หะรารักษ์)
                                        <img src={logo} alt="" width="40%" style={{ marginTop: '-7vw',marginRight: '10%', float: 'right'}}/>
                                        
                                    </p>
                                    </span>
                                </>
                                :
                                <>
                                    <span style={{ fontSize: '2vw',color: '#343333'}}> 
                                        (ลงชื่อ)
                                        <img src={signature} alt="signature" width="40%" style={{paddingLeft: '20px'}}/>
                                        
                                    <p style={{fontWeight: 'bold', color: 'black', marginLeft: '20px'}}>
                                        {
                                            localStorage.getItem("name") ?
                                            <>
                                                ({ localStorage.getItem("name")})
                                            </>
                                            
                                            : ""
                                        }
                                    </p>
                                    <img src={hospitalLogo} alt="" width="40%" style={{ marginTop: '-27%', marginRight: '2vw', float:'right'}}/>
                                    </span>
                                </>
                            }
                            
                        </Grid>
                    </Grid>

                    <Grid container pl={2} pr={2} style={{paddingTop: '10px'}}>
                        <Grid item xs={6}>
                        <span style={{ fontSize: '2vw',color: '#343333'}}> 
                            {/* <img src={hospitalLogo} alt="" width="25%" style={{float: 'right', marginTop: '-18%', marginRight: '-10%'}}/> */}
                        </span>
                        </Grid>
                        <Grid item xs={6}>
                            {
                                localStorage.getItem('projectKeyForm') === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23' ?
                                <>
                                    <span style={{ fontSize: '2vw',color: '#343333'}}> 
                                        ใบอนุญาตประกอบวิชาชีพเลขที่ :
                                        <span style={{ fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' }}>
                                             ว.34436
                                        </span>
                                    </span>
                                </>
                                :
                                <>
                                    <span style={{ fontSize: '2vw',color: '#343333'}}> 
                                        ใบอนุญาตประกอบวิชาชีพเลขที่ :
                                        <span style={{ fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' }}>
                                        {localStorage.getItem('licenseno')}
                                        </span>
                                    </span>
                                </>
                            }
                            
                        </Grid>
                        {/* <br/> */}
                        {/* <br/> */}
                        <Grid item xs={12}>
                            <span style={{ fontSize: '2vw',color: '#343333'}}> 
                                <span style={{fontWeight: 'bold', color: 'black'}}><u>หมายเหตุ </u></span> หากมีรอยขีดฆ่าแก้ไขต้องมีลายมือแพทย์ลงชื่อกำกับ
                            {/* <br/> */}
                        </span>
                        </Grid>
                    </Grid>

                    <Grid container pl={2} pr={2} style={{paddingTop: '10px'}}>
                        
                    </Grid>
                    
                    </Box>
                :
                <Box sx={{ flexGrow: 1 }} pt={3}>
                    <br/>
                    {/* <Grid item xs={12}>
                        <div style={{textAlign: 'center',paddingTop: '5px'}}>
                            <span style={{textAlign: 'center', fontSize: '2vw', color: '#343333'}}> 
                                TAKUAPA HOSPITAL, PHANGNGA
                            </span> 
                        </div>
                    </Grid> */}
                    <Grid item xs={12}>
                        <div style={{textAlign: 'center', paddingTop: '10px'}}>
                                <span style={{fontSize: '3vw', fontWeight:'bold'}}>
                                Inpatient Medical Certificate
                                </span>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{textAlign: 'center', paddingTop: '10px'}}>
                            {
                                localStorage.getItem('projectKeyForm') === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23' ?
                                <>
                                    <span style={{fontSize: '3vw', fontWeight:'bold'}}>
                                        TAKUAPA HOSPITAL, PHANGNGA
                                    </span>
                                </>
                                :
                                <>
                                    <span style={{fontSize: '3vw', fontWeight:'bold'}}>
                                        Thalang Hospital
                                    </span>
                                </>
                            }
                                
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{textAlign: 'center',paddingTop: '5px'}}>
                            {
                                localStorage.getItem('projectKeyForm') === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23' ?
                                <>
                                    <span style={{textAlign: 'center', fontSize: '2vw', color: '#343333'}}> 
                                        
                                    </span> 
                                    
                                </>
                                :
                                <>
                                    <span style={{textAlign: 'center', fontSize: '2vw', color: '#343333'}}> 
                                        358 หมู่ 1ถนนเทพกษัตรี ตำบลเทพกระษัตรี อำเภอถลาง จังหวัดภูเก็ต
                                        
                                    </span> 
                                </>
                            }
                            
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                            <div>
                                <p style={{textAlign: 'right', fontSize: '2vw', paddingTop: '20px', paddingRight: '63px'}}>
                                Date: {" "}
                                <span style={{fontSize: '2vw', fontWeight: 'bold'}}>{ localStorage.getItem('doctor_cert_date_eng') }</span>
                                </p>
                            </div>
                            
                    </Grid>
                    {/* <br/> */}
                    <Grid container pl={2} pr={2}>
                        <Grid item xs={12}>
                            <div>
                                <span style={{ fontSize: '2vw',color: '#343333'}}>
                                    This is to certify that 
                                    <TextField 
                                    
                                    id="margin-none"
                                    InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold', color: 'black' } }}
                                    style={{marginRight: '10px', width: '40vw'}}
                                    variant='standard'
                                    value={localStorage.getItem('fullname')}
                                    />
                                    Age:
                                    <TextField 
                                    
                                    id="margin-none"
                                    InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' } }}
                                    variant='standard'
                                    style={{width: '17vw'}}
                                    value={localStorage.getItem('age_y')}
                                    />years old
                                </span>
                            </div>
                        </Grid>
                    </Grid>
                    {/* <br/> */}
                    <Grid container pl={2} pr={2}>
                        <Grid item xs={12}>
                            <div>
                                <span style={{ fontSize: '2vw',color: '#343333'}}>
                                    Identity Number: 
                                    <TextField 
                                    
                                    id="margin-none"
                                    InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold', color: 'black' } }}
                                    style={{marginRight: '10px', width: '40vw'}}
                                    variant='standard'
                                    value={
                                        localStorage.getItem("projectKeyForm") === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23' ?
                                        localStorage.getItem('identify_number')
                                        :
                                        localStorage.getItem('cid')}
                                    />
                                    HN:
                                    <TextField 
                                    
                                    id="margin-none"
                                    InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' } }}
                                    variant='standard'
                                    style={{width: '28vw'}}
                                    value={localStorage.getItem('hn')}
                                    />
                                    {/* {" AN:"} 
                                    <TextField 
                                    
                                    id="margin-none"
                                    InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' } }}
                                    variant='standard'
                                    style={{width: '19vw'}}
                                    value={localStorage.getItem('an')}
                                    /> */}
                                </span>
                            </div>
                        </Grid>
                    </Grid>
                    {/* <br/> */}
                    <Grid container pl={2} pr={2}>
                        <Grid item xs={12}>
                            <div>
                                <span style={{ fontSize: '2vw',color: '#343333'}}>
                                    Was hospitalized from: 
                                    <TextField 
                                    
                                    id="margin-none"
                                    InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold', color: 'black' } }}
                                    style={{marginRight: '10px', width: '40vw'}}
                                    variant='standard'
                                    value={localStorage.getItem('date1_eng')}
                                    />
                                    Until:
                                    <TextField 
                                    
                                    id="margin-none"
                                    InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' } }}
                                    variant='standard'
                                    style={{width: '22vw'}}
                                    value={localStorage.getItem('date2_eng')}
                                    />
                                </span>
                            </div>
                        </Grid>
                    </Grid>
                    {/* <br/> */}
                    <Grid container pl={2} pr={2}>
                        <Grid item xs={12}>
                            <div>
                                <span style={{ fontSize: '2vw',color: '#343333'}}>
                                    Presenting symptoms
                                </span> 
                            </div>
                            <div>
                            <TextField 
                                        
                            id="margin-none"
                            InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold', paddingLeft:'80px' } }}
                            style={{ width: '89.2vw'}}
                            variant='standard'
                            value={"asymptomatic"}
                            // name="other_complication"
                            // onChange = {
                            //   (event) => {
                            //     set_other_complication(event.target.value);
                            //     setVal(event.target.name, event.target.value);
                            //   }
                            // }
                            />
                                {/* <span style={{ fontSize: '2vw',color: '#343333'}}> 
                                {
                                    localStorage.getItem('symptom_eng') ?
                                    <TextField 
                                        
                                        id="margin-none"
                                        InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold', paddingLeft:'80px' } }}
                                        style={{ width: '89.2vw'}}
                                        variant='standard'
                                        value={localStorage.getItem('symptom_eng')}
                                        // name="other_complication"
                                        // onChange = {
                                        //   (event) => {
                                        //     set_other_complication(event.target.value);
                                        //     setVal(event.target.name, event.target.value);
                                        //   }
                                        // }
                                        />
                                    :
                                    <TextField 
                                        disabled
                                        id="margin-none"
                                        InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold', paddingLeft:'80px' } }}
                                        style={{ width: '89.2vw'}}
                                        variant='standard'
                                        // value={localStorage.getItem('note1')}
                                        // name="other_complication"
                                        // onChange = {
                                        //   (event) => {
                                        //     set_other_complication(event.target.value);
                                        //     setVal(event.target.name, event.target.value);
                                        //   }
                                        // }
                                        />
                                }
                                    
                                    
                                    
                                    
                                </span> */}
                            </div>
                            <div>
                                <span style={{ fontSize: '2vw',color: '#343333'}}> 
                                    <TextField 
                                    disabled
                                    id="margin-none"
                                    InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' } }}
                                    style={{ width: '89.2vw'}}
                                    variant='standard'
                                    // value={other_complication}
                                    // name="other_complication"
                                    // onChange = {
                                    //   (event) => {
                                    //     set_other_complication(event.target.value);
                                    //     setVal(event.target.name, event.target.value);
                                    //   }
                                    // }
                                    />
                                    
                                </span>
                            </div>
                            
                        </Grid>
                    </Grid> 

                    {/* <br/> */}
                    <Grid container pl={2} pr={2}>
                        <Grid item xs={12}>
                            <div>
                                <span style={{ fontSize: '2vw',color: '#343333'}}>
                                    Diagnosis
                                </span> 
                            </div>
                            <div>
                                <span style={{ fontSize: '2vw',color: '#343333'}}> 
                                {/* {
                                    localStorage.getItem('symptom_eng') ? */}
                                    <TextField 
                                        
                                        id="margin-none"
                                        InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold', paddingLeft:'80px' } }}
                                        style={{ width: '89.2vw'}}
                                        variant='standard'
                                        value={"COVID 19 infection"}
                                        />
                                    {/* :
                                    <TextField 
                                        disabled
                                        id="margin-none"
                                        InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold', paddingLeft:'80px' } }}
                                        style={{ width: '89.2vw'}}
                                        variant='standard'
                                        // value={localStorage.getItem('note1')}
                                        // name="other_complication"
                                        // onChange = {
                                        //   (event) => {
                                        //     set_other_complication(event.target.value);
                                        //     setVal(event.target.name, event.target.value);
                                        //   }
                                        // }
                                        /> */}
                                {/* } */}
                                    
                                    
                                    
                                    
                                </span>
                            </div>
                        </Grid>
                     </Grid>

                     {/* <br/> */}
                    <Grid container pl={2} pr={2}>
                        <Grid item xs={12}>
                            <div>
                                <span style={{ fontSize: '2vw',color: '#343333'}}>
                                    Treatment
                                </span> 
                            </div>
                            <div>
                                <span style={{ fontSize: '2vw',color: '#343333'}}> 
                                    <TextField 
                                        
                                        id="margin-none"
                                        InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' } }}
                                        style={{ width: '89.2vw'}}
                                        variant='standard'
                                        value={"Have received a treatment from the hospital and isolated for a specified period of time"}
                                        />
                                </span>
                            </div>

                            <div>
                                <span style={{ fontSize: '2vw',color: '#343333'}}> 
                                    <TextField 
                                    disabled
                                    id="margin-none"
                                    InputProps={{ style: { fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' } }}
                                    style={{ width: '89.2vw'}}
                                    variant='standard'
                                    // value={other_complication}
                                    // name="other_complication"
                                    // onChange = {
                                    //   (event) => {
                                    //     set_other_complication(event.target.value);
                                    //     setVal(event.target.name, event.target.value);
                                    //   }
                                    // }
                                    />
                                    
                                </span>
                            </div>
                        </Grid>
                     </Grid>
                    {/* <br/> */}
                     <Grid container pl={2} pr={2}>
                        <Grid item xs={12}>
                            <div>
                                <span style={{ fontSize: '2vw',color: '#343333'}}>
                                    Recommendation
                                </span> 
                            </div>
                            <div>
                                <span style={{ fontSize: '2vw',color: '#343333'}}> 
                                
                                    <TextField 
                                        
                                        id="margin-none"
                                        InputProps={{ style: { fontSize: '2vw', fontWeight: 'bold' } }}
                                        style={{ width: '89.2vw'}}
                                        variant='standard'
                                        value={'He/She has been found for COVID 19+ by RT PCR on ' + localStorage.getItem("formatted_date") + ' and stayed in hospital from '}
                                        />
                                   
                                    <TextField 
                                        id="margin-none"
                                        InputProps={{ style: { fontSize: '2vw', fontWeight: 'bold'} }}
                                        style={{ width: '89.2vw'}}
                                        variant='standard'
                                        value = { localStorage.getItem('date1_eng') +" to " + localStorage.getItem('date2_eng')+ '. He/She is currently well and no longer able to transmit the virus.'}
                                        /> 

                                        <TextField 
                                        id="margin-none"
                                        InputProps={{ style: { fontSize: '2vw', fontWeight: 'bold'} }}
                                        style={{ width: '89.2vw'}}
                                        variant='standard'
                                        value = { 'RT PCR test should not be repeated since the result may be positive during 50 days after'}
                                        /> 

                                        <TextField 
                                        id="margin-none"
                                        InputProps={{ style: { fontSize: '2vw', fontWeight: 'bold'} }}
                                        style={{ width: '89.2vw'}}
                                        variant='standard'
                                        value = { 'the infection.'}
                                        /> 
                                    
                                    
                                    
                                    
                                </span>
                            </div>
                        </Grid>
                     </Grid>

                     <br/>
                     <Grid container pl={2} pr={2} style={{paddingTop: '10px'}}>
                        <Grid item xs={6}>
                        </Grid>
                        <Grid item xs={6}>
                        {
                                localStorage.getItem("projectKeyForm") === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23' ?
                                <>
                                     <span style={{ fontSize: '2vw',color: '#343333'}}> 
                                        (Signature)
                                        <img src={sign_prison} alt="signature" width="60%" style={{marginLeft: '-10%'}}/>
                                    
                                    <p style={{fontWeight: 'bold', color: 'black', marginLeft: '60px'}}>
                                        (นพ.กชกร หะรารักษ์)
                                        <img src={logo} alt="" width="38%" style={{ marginTop: '-15%',marginRight: '10%', float: 'right'}}/>
                                        
                                    </p>
                                    </span>
                                </>
                                :
                                <>
                                    <span style={{ fontSize: '2vw',color: '#343333'}}> 
                                        (Signature)
                                        <img src={signature} alt="signature" width="40%" style={{paddingLeft: '20px'}}/>
                                        {/* <span> Doctor </span> */}
                                        {/* <br/> */}
                                        
                                        <p style={{fontWeight: 'bold', color: 'black', marginLeft: '50px'}}>
                                            {
                                                localStorage.getItem("name") ?
                                                <>
                                                    ({ localStorage.getItem("name")})
                                                </>
                                                
                                                : ""
                                            }    

                                            <img src={hospitalLogo} alt="" width="40%" style={{ marginTop: '-18%', marginRight: '-10%'}}/>                            
                                        </p>
                                    </span>
                                </>
                            }
                            
                        </Grid>
                    </Grid>

                    <Grid container pl={2} pr={2} style={{paddingTop: '10px'}}>
                        <Grid item xs={6}>
                        <span style={{ fontSize: '2vw',color: '#343333'}}> 
                            {/* <img src={hospitalLogo} alt="" width="25%" style={{float: 'right', marginTop: '-18%', marginRight: '-10%'}}/> */}
                        </span>
                        </Grid>
                        <Grid item xs={6}>
                            
                            {
                                localStorage.getItem('projectKeyForm') === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23' ?
                                <>
                                    <span style={{ fontSize: '2vw',color: '#343333'}}> 
                                        Professional License Number:
                                        <span style={{ fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' }}>
                                             ว.34436
                                        </span>
                                    </span>
                                </>
                                :
                                <>
                                    <span style={{ fontSize: '2vw',color: '#343333'}}> 
                                     Professional License Number:
                                        <span style={{ fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' }}>
                                        {localStorage.getItem('licenseno')}
                                        </span>
                                    </span>
                                </>
                            }
                        </Grid>
                        {/* <br/> */}
                    </Grid>

                    <Grid container pl={2} pr={2} style={{paddingTop: '10px'}}>

                            <Grid item xs={6}>
                            <span style={{ fontSize: '2vw',color: '#343333'}}> 
                            </span>
                            </Grid>
                            <Grid item xs={6}>
                            {
                                localStorage.getItem('projectKeyForm') === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23' ?
                                <>
                                    <span style={{ fontSize: '2vw',color: '#343333'}}> 
                                        Hospital Name :
                                            <span style={{ fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' }}>
                                            Takuapa Hospital 
                                            </span>
                                        <br/>
                                        <p style={{paddingTop : '10px'}}>
                                            Address : 
                                            <span style={{ fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' }}>
                                                39/2 petkaseam rd, bangnaisi, phangnga
                                            </span>
                                        </p>
                                        
                                        <br/>
                                    </span> 
                                </>
                                :
                                <>
                                    <span style={{ fontSize: '2vw',color: '#343333'}}> 
                                        Hospital Name :
                                            <span style={{ fontSize: '2vw', paddingLeft: '10px', fontWeight: 'bold' }}>
                                            Thalang Hospital 
                                            </span>
                                        {/* <br/> */}
                                    </span> 
                                </>
                            }
                                
                            </Grid>
                    </Grid>
                        
                </Box>
                }
                
              <br/>
            </div>
            <Box sx={{ flexGrow: 1 }} pt={3}>
            <Grid container pl={2} pr={2}>
                    <Grid item xs={12}>
                        <div className="d-flex align-items-center justify-content-center">
                            <CustomButton onClick={onCapture}>Save PDF</CustomButton>
                        </div>
                    </Grid>
            </Grid>     
            </Box>
            <br/>
            </ThemeProvider>

        )
}

export default MedicalCertificate
