import React, {Component, useState} from 'react';
import { Modal, Button, Row, Col, Form, Table, ResponsiveEmbed } from'react-bootstrap';
import './custom.css';
import QRpng from './QRcode.PNG';
import ReactToPrint from 'react-to-print';
import QRCode from 'qrcode.react';
import * as htmlToImage from 'html-to-image';
import { saveAs } from 'file-saver';
import axios from 'axios';

export class DownloadQRcode extends Component{
    constructor(props){
        super(props);
        this.wrapper = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.state = { userListById :[] };
    }

    handleInputChange(event) {
        this.setState({
            address: event.target.value
        });
    }

    handleSubmit(event){
        event.preventDefault();
    }

    onCapture = (name) =>{
        htmlToImage.toPng(document.getElementById("savePNG"))
            .then(function (dataUrl) {
            saveAs(dataUrl,  'QRcode.png');
            });
    }

    render(){
        if(!this.props.show){
            return null;
        }

        let qrData = [];
        let startDate = [];
        let dateOfBirth = [];
        let uploadedDate = [];
        const QrData  = this.props.userlist;
        let sortedData =[];
        if (this.props.userlist.length !== 0){
            console.log("userlist: ", this.props.userlist);
            if(this.props.loopFinish) {
                sortedData = this.props.userlist.slice().sort((a, b) => b.uploadedDate > a.uploadedDate ? 1: -1);
                console.log("sorted Data:", sortedData);

                for(let i=0; i<= sortedData.length; i++) {
                    if(sortedData[i]?.startDate){
                        let splitDate = sortedData[i]?.startDate?.split("T");
                        startDate.push(splitDate[0]);
                    }
        
                    if(sortedData[i]?.dateOfBirth){
                        let splitBirthDate = sortedData[i]?.dateOfBirth?.split("T");
                        dateOfBirth.push(splitBirthDate[0]);
                    }
        
                    if(sortedData[i]?.uploadedDate){
                        let splitCreatedAt = sortedData[i]?.uploadedDate?.split("T");
                        uploadedDate.push(splitCreatedAt[0]);
                    }

                }
            }
        }
        
        return(
            <div ref={this.wrapper}>
                    <Modal
                            {...this.props}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            animation={false}
                        >
                            <Modal.Header>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Download All QR Code
                            </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="container">
                                    <Row>
                                        <Col sm={12}>
                                            <Form onSubmit= {this.handleSubmit}>
                                                <Form.Group controlId="Address">
                                                    
                                                    <Row id="savePNG">
                                                    {sortedData.map((userListQr, index)=>
                                                        <Col sm={6} key = {userListQr.qrCode}>
                                                            <div>
                                                            
                                                                <br/>
                                                                <Table size='sm'> 
                                                                <div style={{border: "1px solid black", borderRadius: "20px", marginLeft:'2%', marginRight:'2%'}} >
                                                                    <tr>
                                                                        <td colSpan={2} style={{border: '0px solid white'}}>
                                                                            <br/>
                                                                            <h5 style={{paddingLeft: '30px', fontSize: '12px'}}>
                                                                                Name: {userListQr.name}
                                                                            </h5>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} style={{border: '0px solid white'}}>
                                                                            <h5 style={{paddingLeft: '30px', fontSize: '12px'}}>
                                                                                Phone Number: {userListQr.phone}
                                                                            </h5> 
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td width="300" style={{border: '0px solid white'}}>

                                                                        <h5 style={{paddingLeft: '30px' , fontSize: '12px'}}>
                                                                            Date of Birth: {
                                                                                dateOfBirth[index] ?  dateOfBirth[index] : "-"
                                                                            }
                                                                        </h5>
                                                                        <h5 style={{paddingLeft: '30px' , fontSize: '12px', paddingTop: '8px'}}>
                                                                            Start Date : {
                                                                                startDate[index] ?  startDate[index]: "-"
                                                                            }
                                                                        </h5>

                                                                        <h5 style={{paddingLeft: '30px' , fontSize: '12px', paddingTop: '8px'}}>
                                                                            Uploaded Date : {
                                                                                uploadedDate[index] ? uploadedDate[index] : "-"
                                                                            }
                                                                        </h5>
                                                                        <h5 style={{paddingLeft: '30px' , fontSize: '12px', paddingTop: '8px'}}>
                                                                            Hotel Name : {userListQr.hotel ? userListQr.hotel : "-" }
                                                                            
                                                                        </h5>                                                                       
                                                                        </td>
                                                                        <td width={100} style={{border: '0px solid white', marginTop: "-2%"}}>
                                                                            <br/>
                                                                            {/* &nbsp; */}
                                                                            {
                                                                                localStorage.getItem("projectKey") === 'qk6oyhtc7c9ptbnffkd90xdpji2iye11v5lhw4qy' ?
                                                                                <QRCode 
                                                                                    size = {70}
                                                                                    id='qrID' value={"https://liff.line.me/1656808843-k6y2Gj7P?code="+userListQr.qrCode}/>
                                                                                :
                                                                                localStorage.getItem("projectKey") === '7zwp8sxengxpq37stixi4za1asmtm3fvx8dvkrzj'?
                                                                                <QRCode 
                                                                                    size = {70}
                                                                                    id='qrID' value={"https://liff.line.me/1656808843-k6y2Gj7P?code="+userListQr.qrCode}/>
                                                                                :
                                                                                localStorage.getItem("projectKey") === 'r65nxurwl4rnoqlbv6n8jh2gxnddamhej11t6xml'?
                                                                                <QRCode 
                                                                                    size = {70}
                                                                                    id='qrID' value={"https://liff.line.me/1656808843-k6y2Gj7P?code="+userListQr.qrCode}/>
                                                                                :
                                                                                localStorage.getItem("projectKey") === 'xrsponolu71cznf0zr4pc9g3le5qghobbyigowog'?
                                                                                <QRCode 
                                                                                    size = {70}
                                                                                    id='qrID' value={"https://liff.line.me/1656808843-k6y2Gj7P?code="+userListQr.qrCode}/>
                                                                                :
                                                                                 localStorage.getItem("projectKey") === 'pi7iotuircb2x90nqqizi4v91x0whrd5xgm42cro'?
                                                                                 <QRCode 
                                                                                    size = {70}
                                                                                    id='qrID' value={"https://liff.line.me/1656808843-k6y2Gj7P?code="+userListQr.qrCode}/>
                                                                                :
                                                                                <QRCode 
                                                                                size = {70}
                                                                                id='qrID' value={userListQr.qrCode}/>
                                                                            }

:
                                                                                {/* localStorage.getItem("projectKey") === 'g9cntq4kj1ylkuc812i4bf4qrv4fmk90b2jpf7zl'?
                                                                                <QRCode 
                                                                                    size = {70}
                                                                                    id='qrID' value={"https://liff.line.me/1656808843-k6y2Gj7P?code="+userListQr.qrCode}/> */}
                                                                           
                                                                            <h6 style={{ fontSize:'8px'}}>Code : {userListQr.qrCode}</h6>
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td colSpan={2} style={{border: '0px solid white'}}>
                                                                            <p style={{textAlign: 'center', fontSize: '12px'}}>
                                                                                <b>Please return devices to hotel staff</b>
                                                                            </p>
                                                                        </td>
                                                                    </tr>
                                                                    
                                                                </div>  
                                                                
                                                                    
                                                                </Table>

                                                                <Row>
                                                                    <Col lg={6} md={12} sm={12}>
                                                                        <Form.Control 
                                                                            type="hidden"
                                                                            name="userId"
                                                                            required
                                                                            readOnly
                                                                            defaultValue = {this.props.userlist.id}
                                                                            placeholder="Enter Id"
                                            
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    )} 
                                                        
                                                    </Row>
                                                        
                                                      
                                                </Form.Group>

                                                <Form.Group className="buttonFloatRight">
                                                    <Button  className="r14" variant="light" onClick={this.props.onHide}>Close</Button>
                                                    <Button className= 'r14' variant="success" onClick={() => this.onCapture()}> Save PNG </Button>

                                                    {/* <ReactToPrint
                                                        trigger={() => {
                                                            return <Button variant="success">Print</Button>;
                                                        }}
                                                        content={() => this.componentRef}
                                                        onAfterPrint ={this.props.onHide}
                                                    /> */}
                                                    
                                                </Form.Group>
                                            </Form>   
                                        </Col>
                                    </Row>
                                </div>
                            </Modal.Body>
                    </Modal>
            </div>
            
        )
    }
}