import React, {Component} from 'react';
import { Table, Button, DropdownButton, Dropdown} from 'react-bootstrap';
import * as FiIcons from 'react-icons/fi';
import * as IoIcons from 'react-icons/io5';
import * as ImIcons from 'react-icons/im';
import * as AiIcons from 'react-icons/ai';
import * as FaIcons from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import  {UserFormModal}  from './UserFormModal';
import { QrModal } from './QrModal';
import { DownloadQRcode } from './DownloadQRcode';
import { UpdateProcessModal } from './UpdateProcessModal';
import { UploadFileModal } from './UploadFileModal';
import { Pagination } from './Pagination';
import './custom.css';
import axios from 'axios';
import Badge from '@material-ui/core/Badge';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Redirect, Switch, Route, Link } from "react-router-dom";
import { withRouter } from "react-router";
import ReactPaginate from 'react-paginate';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { BsSearch } from "react-icons/bs";

class Users extends Component {
    constructor(props){
        super(props);
        this.state = {userLists: [], userListById :[], userDataQR : [],
                        status : '', userid: '',
                    hospitalInfo:[], editModalShow : false,
                    QrModalShow: false, updateModalshow : false,
                    downloadQRShow: false,
                    uploadModalShow: false, currentPage: 0, elementPerPage: 8,
                    dropDownValue: "Status: Pending",
                    islogout: false,
                    loopFinish: false,
                    projects: [],
                    offset: 0,
                    data: [],
                    perPage: 8,
                    keyword: "",
                    uploadedDate: ''

                 };
        let userData = [];
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.clearSearch = this. clearSearch.bind(this);
    }

    componentDidMount(){
        if(localStorage.getItem("projectKey")){
            console.log("in users!");
            this.getUsersListByPending();
            this.getProjects();
        }
        
    }

    componentDidUpdate(prevProps, prevState){
        // if (prevState.QrModalShow !== this.state.QrModalShow) {
        //     this.getUsersListByPending();
        //   }
    }

    getUsersList(){
        const headers = { 
            'Project-Key': localStorage.getItem("projectKey"),
            'Authorization': "Bearer " + localStorage.getItem("token"),
        };

        axios.get('https://api.space-trax.com/v2/home-isolation/admin', { headers })
            .then(res => {
                this.setState({ userLists : res.data.data });
            })
    }

    getUsersListBySent(){
        const headers = { 
            'Project-Key': localStorage.getItem("projectKey"),
            'Authorization': "Bearer " + localStorage.getItem("token"),
        };

        axios.get('https://api.space-trax.com/v2/home-isolation/admin/sent', { headers })
            .then(res => {
                console.log("res:," ,  res);
                this.setState({ userLists : res.data.data });
            }).catch( (err) => {
                console.log(err);
                localStorage.removeItem("token");
                this.setState({
                    islogout: true
                });
            })
    }

    getProjects = () => {        
        fetch('https://api.space-trax.com/v2/dashboard/project', {
            method: 'GET',
            headers: { 
                'Accept' : '*/*',
                'Content-Type' : 'application/x-www-form-urlencoded',
                'Authorization': "Bearer " + localStorage.getItem("token"),
                'Project-Key': localStorage.getItem('projectKey')
            },
        })
        .then(res => res.json())
        .then((result)=>
        {
            let projectArray = [];
            for (let i = 0; i < result.data.length; i++ ){
                projectArray.push(result.data[i].project)
            }
            this.setState({projects: projectArray})
    
        },
        (error)=>{
            console.log("error project api");
        })
    };

    getUsersListByPending(){
        console.log("project key in users:", localStorage.getItem("projectKey"));
        const headers = { 
            'Project-Key': localStorage.getItem("projectKey"),
            'Authorization': "Bearer " + localStorage.getItem("token"),
        };

        axios.get('https://api.space-trax.com/v2/home-isolation/admin/pending', { headers })
            .then(res => {
                if(res.data.message === 'access_denied'){
                    localStorage.removeItem("token");
                    this.setState({
                        islogout: true
                    });
                }
                this.setState({ userLists : res.data.data });
            }).catch( (err) => {
                console.log(err);
                console.log(err);
                localStorage.removeItem("token");
                localStorage.removeItem("projectKey");
                this.setState({
                    islogout: true
                });
            })
    }

    

    signOut = () => {
        localStorage.removeItem("token");
        this.setState({
            islogout: true
        });
    };

    updateStatus(id, param){
        this.setState({status : param});
        this.setState({userid: id});
        this.setState({updateModalshow : true});
    }

    showDate = (date) =>{
        let startDate = "";
        if(date){
            let splitDate = date.split("T");
            startDate = splitDate[0];
        }
        return startDate;
    }

    changeStatus(text) {
        this.setState({dropDownValue: text});
        if(text === 'Status: All'){
            console.log('all users');
            this.getUsersList();
            this.setState({currentPage: 1});
        }
        else if(text === 'Status: Pending'){
            console.log('pending users');
            this.getUsersListByPending();
            this.setState({currentPage: 1});
        }else {
            console.log('sent users')
            this.getUsersListBySent();
            this.setState({currentPage: 1});
        }
    }

    getUserById(id, param){
        
        const headers = { 
            'Project-Key': localStorage.getItem("projectKey"),
            'Authorization': "Bearer " + localStorage.getItem("token"),
        };

        axios.get('https://api.space-trax.com/v2/home-isolation/admin/' + id , { headers })
            .then(res => {
                if(res.data.message === 'access_denied'){
                    localStorage.removeItem("token");
                    this.setState({
                        islogout: true
                    });
                }
                console.log("get user by id:" , res.data.data);
                this.setState({ userListById : res.data.data });
                this.setState({ hospitalInfo: res.data.data.hospital });
        });

        if (param === 'qr'){
            this.setState({QrModalShow : true});
        }
        if ( param === 'edit') {
            this.setState({editModalShow : true});
        }
    }

    getUserDataForQR(currentElementPage){
        const headers = { 
            'Project-Key': localStorage.getItem("projectKey"),
            'Authorization': "Bearer " + localStorage.getItem("token"),
        };
        let userData = [];
        console.log('userlist:', currentElementPage);
        
        for(let i=0; i< currentElementPage.length; i++) {
            console.log("id", currentElementPage[i].id);
            
            axios.get('https://api.space-trax.com/v2/home-isolation/admin/' + currentElementPage[i].id, { headers })
                .then(res => {

                userData.push(
                    {
                        "name" : res.data.data.name + " " + res.data.data.surname,
                        "phone": res.data.data.phone_number,
                        "dateOfBirth": res.data.data.date_of_birth,
                        "startDate" : res.data.data.date_admitted,
                        "uploadedDate" : res.data.data.created_at,
                        "hotel" : res.data.data.hotel?  res.data.data.hotel : "-" ,
                        "qrCode" : res.data.data.qr_code
                    }
                );
                this.setState({ userDataQR : userData });
                // console.log("userdata", this.props.userDataQR);
            });

            if(i === currentElementPage.length -1) {
                this.setState({loopFinish: true})
                this.setState({downloadQRShow: true});
                
            }
        }
            
        
        
    }

    handlePageClick = (e) => {
        console.log("e.selected", e.selected);
        const selectedPage = e.selected;
        const offset = selectedPage * 8;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        });
        
    };

    handleSubmit(event){
        event.preventDefault();
        this.setState({currentPage: 0, offset: 0});
        console.log("date:", event.target.date.value);
        console.log("keyword:", event.target.keyword.value);
        let startDate = "";
        let keyword = event.target.keyword.value;
        let date = event.target.date.value;
        if(event.target.date.value){
            let splitDate = date.split("-");
            startDate = splitDate[2] + '/' + splitDate[1] + '/' + splitDate[0];
            console.log("splitdate:", startDate);
        }

        const headers = { 
            'Project-Key': localStorage.getItem("projectKey"),
            'Authorization': "Bearer " + localStorage.getItem("token"),
        };

        axios.get('https://api.space-trax.com/v2/home-isolation/admin/pending?keyword='+ keyword + "&start_date=" + startDate, { headers })
            .then(res => {
                if(res.data.message === 'access_denied'){
                    localStorage.removeItem("token");
                    this.setState({
                        islogout: true
                    });
                }
                this.setState({ userLists : res.data.data });
            }).catch( (err) => {
                console.log(err);
                console.log(err);
                localStorage.removeItem("token");
                localStorage.removeItem("projectKey");
                this.setState({
                    islogout: true
                });
            })
    }

    clearSearch(){
        this.setState({currentPage: 0, offset: 0});
        this.setState({keyword:'', uploadedDate: ''});
        const headers = { 
            'Project-Key': localStorage.getItem("projectKey"),
            'Authorization': "Bearer " + localStorage.getItem("token"),
        };

        axios.get('https://api.space-trax.com/v2/home-isolation/admin/pending?keyword=&start_date=', { headers })
            .then(res => {
                if(res.data.message === 'access_denied'){
                    localStorage.removeItem("token");
                    this.setState({
                        islogout: true
                    });
                }
                this.setState({ userLists : res.data.data });
            }).catch( (err) => {
                console.log(err);
                console.log(err);
                localStorage.removeItem("token");
                localStorage.removeItem("projectKey");
                this.setState({
                    islogout: true
                });
            })
    }

    
    render(){
        if (this.state.islogout) {
            return <Redirect to="/" />;
          }
        const {userLists, userListById, hospitalInfo, 
            status, userid, elementPerPage, currentPage, dropDownValue, userDataQR, loopFinish, projects} = this.state;
        const pageCount = (userLists.length)/elementPerPage;

        const editModalClose = () => this.setState({editModalShow:false});
        const QrModalClose = () => this.setState({QrModalShow: false});
        const updateModalClose = () => this.setState({updateModalShow: false});
        const uploadModalClose = () => this.setState({uploadModalShow: false});
        const downloadQRClose =() => this.setState({downloadQRShow: false});
        const reverseUserlist = userLists.reverse();
        const indexOfLastPage = currentPage * elementPerPage;
        const indexofFirstPage = indexOfLastPage - elementPerPage;
        // const currentElementPage = reverseUserlist.slice(indexofFirstPage, indexOfLastPage);
        const currentElementPage = reverseUserlist.slice(this.state.offset, this.state.offset + this.state.perPage)
        const paginate = pageNum => this.setState({currentPage: pageNum});
        const nextPage = (last) => 
        {
            if(this.state.currentPage < last){
                this.setState({currentPage: currentPage + 1});
            }
            else this.setState({currentPage: last});

        }
        
        const prevPage = () => {
            if(this.state.currentPage > 1){
                this.setState({currentPage: currentPage - 1});
            }
            else this.setState({currentPage : 1})
        }

        return(
            <>
            <nav class="navbar fixed-top navbar-light" style = {{backgroundColor: '#2c504b'}}>
                    
                    <DropdownButton className=" prjBtn boldFont" variant='outline-light' id="dropdown-basic-button" title={localStorage.getItem("projectName")}>
                    {(projects || []).map((project) => {
                        const { name_th } = project;
                        return (
                            <Dropdown.Item 
                                onClick={() => {
                                    localStorage.setItem("projectKey", project.project_key); 
                                    localStorage.setItem("projectType", project.type);
                                    localStorage.setItem("projectName", project.name_th);
                                    if(localStorage.getItem("projectKey")){
                                        this.getUsersListByPending();
                                    }
                                }}
                            >
                                {name_th}
                            </Dropdown.Item>
                        );
                        })}
                        
                        {/* <Dropdown.Item onClick={(e) => this.changeStatus(e.target.textContent)}>
                            Status: Done
                        </Dropdown.Item> */}
                        
                    </DropdownButton>
                    
                    <Button className='floatRightButton'  variant="outline-light" onClick = {this.signOut}>
                            <FaIcons.FaSignOutAlt className="uploadIcon"/> SignOut
                            
                    </Button>
            </nav>
            <br/>
            <br/>
                <div className="container">
                         <div>
                            <DropdownButton className="dropDownBtn boldFont" variant='outline-success' id="dropdown-basic-button" title={this.state.dropDownValue}>
                                <Dropdown.Item onClick={(e) => this.changeStatus(e.target.textContent)}>
                                    Status: Pending
                                </Dropdown.Item>
                                <Dropdown.Item onClick={(e) => this.changeStatus(e.target.textContent)}>
                                    Status: Done
                                </Dropdown.Item>
                                
                            </DropdownButton>

                            <Button className='dropDownBtn' variant="outline-success" onClick = {() => this.setState({uploadModalShow: true})}>
                                <ImIcons.ImUpload3 className="uploadIcon"/>  Upload
                            </Button>

                            <Button className='dropDownBtn' variant="outline-success" onClick = {() => this.getUserDataForQR(currentElementPage) }>
                                <ImIcons.ImDownload3 className="uploadIcon"/>  Download All QR Code
                            </Button>

                            {/* <Button className='floatRightButton' variant="outline-success" onClick = {this.signOut}>
                                <FaIcons.FaSignOutAlt className="uploadIcon"/> SignOut
                                
                            </Button> */}
                            
                        </div>
                    <br/>
                    <br/>
                    <div>
                        <Container>
                        <Form onSubmit= {this.handleSubmit}>
                            <Form.Group controlId="id">
                            <Row>
                                
                                <Col md={3} xs={12} style={{marginLeft:'-10px'}}>
                                    <Form.Label className="labelTop">Keyword</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="keyword"
                                        value={this.state.keyword}
                                        onChange={e => this.setState({ keyword: e.target.value })}
                                        placeholder=""
                                        
                                    />
                                </Col>
                                <Col md={3} xs={12}>
                                    <Form.Label className="labelTop">Uploaded date</Form.Label>
                                    <Form.Control 
                                    value={this.state.uploadedDate}
                                    onChange={e => this.setState({ uploadedDate: e.target.value })}
                                    type="date" name="date"  placeholder="choose uploaded date" />
                                </Col>
                                <Col md={6} xs={12}>
                                    <Button className='searchButton' variant="outline-success" type="submit">
                                        <BsSearch className="uploadIcon"/>
                                    </Button>
                                    <Button className='searchButton' variant="outline-success"
                                        onClick={this.clearSearch}                                    >
                                        Reset Search
                                    </Button>
                                </Col>
                            </Row>
                            </Form.Group>
                            </Form>
                        </Container>
                    </div>

                    <br/>
                    
                    <Table striped bordered hover size='sm'>
                        <thead>
                            <tr>
                                <th colSpan="11" className="center" > 
                                    Users List 
                                </th >
                            </tr>
                            <tr>
                                <th>#</th>
                                {
                                    localStorage.getItem("projectType") === 'isolation' ?
                                    <></> :
                                    <th>IMEI</th>
                                }
                                {
                                    localStorage.getItem("projectType") === 'isolation' ?
                                    <></> :
                                    <th>Oximeter</th>
                                }
                               
                                {
                                    localStorage.getItem("projectType") === 'isolation' ?
                                    <th>Hospital</th> :
                                    <th>Hospital</th>
                                }
                                
                                <th>Name</th>
                                <th>SurName</th>
                                <th>Phone No.</th>
                                <th>Identity No.</th>
                                <th>Uploaded Date</th>
                                <th>Action</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                userLists.length === 0 ?
                                <>
                                  <tr>
                                      <td  colSpan="10">
                                        There is no data.
                                      </td>
                                       
                                  </tr>
                                </> :
                                <>
                                    {currentElementPage.map((userList, index)=>
                                            <tr key = {userList.id} > 
                                                <td>{index + 1 + (currentPage+1) * elementPerPage - 8}</td>
                                                {
                                                    localStorage.getItem("projectType") === 'isolation' ?
                                                    <></> :
                                                    <td>
                                                        {(() => {
                                                                if (userList.imei) {
                                                                return (
                                                                    <span>{userList.imei}</span>
                                                                )
                                                                } else {
                                                                return (
                                                                    <span>-</span>
                                                                )}
                                                        })()}
                                                            
                                                    </td>
                                                }
                                                
                                                {
                                                    localStorage.getItem("projectType") === 'isolation' ?
                                                    <></> :
                                                    <td>
                                                        {(() => {
                                                            if (userList.oximeter_id) {
                                                            return (
                                                                <span>{userList.oximeter_id}</span>
                                                            )
                                                            } else {
                                                            return (
                                                                <span>-</span>
                                                            )}
                                                        })()}
                                                            
                                                    </td>
                                                }

                                                {
                                                    localStorage.getItem("projectType") === 'isolation' ?
                                                    <td>{userList.hospital?.name_en}</td> :
                                                    <td>{userList.hospital.name_en}</td>
                                                }
                                               
                                                <td>{userList.name}</td>
                                                <td>{userList.surname}</td>
                                                <td>{userList.phone_number}</td>
                                                <td>{userList.identify_number}</td>
                                                <td>
                                                    {
                                                        userList.created_at ? this.showDate(userList.created_at) : "-"
                                                    }
                                                </td>
                                                <td>
                                                    <a className="iconSize">
                                                        <ReactTooltip />
                                                        <FiIcons.FiEdit data-tip="Edit" data-place="bottom" onClick = {() => this.getUserById(userList.id,'edit')}/>
                                                    </a>
                                                    <a className="iconSize">
                                                        <ReactTooltip />
                                                        <IoIcons.IoQrCodeOutline data-tip="QR" data-place="bottom"
                                                            onClick = {() =>this.getUserById(userList.id, 'qr')}/>
                                                    </a>
                                                </td>
                                                <td className="d-flex align-items-center justify-content-center">
                                                    
                                                {(() => {
                                                        if (userList.process_status === 1) {
                                                        return (
                                                            <Button variant="outline-primary" size="sm" onClick = {() => this.updateStatus(userList.id,userList.process_status)}>
                                                                Move to Done
                                                            </Button>
                                                        )
                                                        } else {
                                                        return (
                                                            <Button variant="outline-primary" size="sm" onClick = {() => this.updateStatus(userList.id,userList.process_status)}>
                                                                Done
                                                            </Button>

                                                            
                                                        )}
                                                    })()}
                                                </td>
                                            </tr>
                                        )} 
                                </>
                            }
                                      
                        </tbody>
                    </Table>
 
                    <br/>
                    <span className="d-flex align-items-center justify-content-center"> 
                        <ReactPaginate
                            previousLabel={"<prev"}
                            nextLabel={"next>"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                            renderOnZeroPageCount={null}
                            forcePage={this.state.currentPage}
                        />
                    </span>
                    


                    {/* <Pagination elementPerPage={elementPerPage} totalElements={userLists.length} 
                    paginate={paginate} nextPage={nextPage} prevPage={prevPage}/> */}
                    <UserFormModal
                        show = {this.state.editModalShow}
                        onHide={editModalClose}
                        userlist = {userListById}
                        hospital = {hospitalInfo}
                    />
                    <QrModal
                        
                        show = {this.state.QrModalShow}
                        onHide = {QrModalClose}
                        userlist = {userListById}
                        hospital = {hospitalInfo}
                    />

                    <DownloadQRcode
                        show = {this.state.downloadQRShow}
                        onHide = {downloadQRClose}
                        userlist = {userDataQR}
                        loopFinish = {loopFinish}
                    />

                    <UpdateProcessModal
                        show = {this.state.updateModalshow}
                        onHide = {updateModalClose}
                        status = {status}
                        userid = {userid}
                    />

                    <UploadFileModal
                        show = {this.state.uploadModalShow}
                        onHide = {uploadModalClose}
                    />
            </div>
            </>
                
                
            //     </Col>
            // </Row>
            

        )
    }
}

export default withRouter(Users);