import React , {useEffect} from 'react';
import { useLocation } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles, withStyles } from '@mui/styles';
import {Grid, Box, Card, CardContent, CardHeader,
    Typography,Checkbox , Input,
    FormGroup , FormControlLabel , FormControl , FormLabel, ToggleButton, ToggleButtonGroup 
} from '@mui/material';
import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/core/ButtonUnstyled';
import { styled } from '@mui/system';
import { useHistory } from "react-router-dom";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import * as htmlToImage from 'html-to-image';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import TextField from '@mui/material/TextField';
import poccLogo from '../assets/poccLogo.png';

const THEME = createTheme({
    typography: {
     "fontFamily": `"PromptMedium","PromptSemiBold", "PromptRegular","PromptBold"`
    },palette: {
        primary: {
          main: '#7f51f5',
        }
      },
 });

const useStyles = makeStyles({
    body : {
        marginTop: '-3%'
    },
    headerTitle : {
        fontWeight: 'bold',
    },
    appbar: {
        alignItems: 'center',
    },
    textColor: {
        fontSize: '18px',
        color: '#7F51F5'
    },
    redText: {
        color: 'red',
        textAlign: 'center',
        fontSize: '14px'
    },
    iconSize: {
        position: 'absolute',
        paddingLeft: '10px',
        marginTop: '15px'
    }
});

const label = { inputProps: { 'aria-label': 'Checkbox' } };

const CustomButtonRoot = styled('button')(`
  background-color: #7F51F5;
  padding: 15px 50px;
  border-radius: 20px;
  color: #fff;
  font-weight: 600;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  transition: all 200ms ease;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0);
  border: none;

  &:hover {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.active} {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }
`);

const CustomButtonRootBack = styled('button')(`
  background-color: #c7c7c7;
  padding: 15px 50px;
  border-radius: 20px;
  color: #fff;
  font-weight: 600;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  transition: all 200ms ease;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0);
  border: none;

  &:hover {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.active} {
    background-color: #c7c7c7;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }
`);

function CustomCloseBack(props) {
    return <ButtonUnstyled {...props} component={CustomButtonRootBack} />;
}  

function CustomButton(props) {
    return <ButtonUnstyled {...props} component={CustomButtonRoot} />;
}

function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    return null;
  }

function NoMedicalCertificate() {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    var choiceData = "";

    const [state, setState] = React.useState({
        checkBox1: false,
        checkBox2: false,
        checkBox3: false,
        checkBox4: false,
        checkBox5: false});
    const [checkBox6, setCheckBox6] = React.useState(false)
    const [disabled, setDisabled] = React.useState(false);
    const [checkInput, setCheckInput] = React.useState (false);
    const [lang, setLang] = React.useState(localStorage.getItem('language'));
    
    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };

    const handleChangeDisable = (event) => {
        setCheckBox6(event.target.checked);
        console.log("check box 6:", event.target.checked);
        if (event.target.checked){
            setState({
                checkBox1: false,
                checkBox2: false,
                checkBox3: false,
                checkBox4: false,
                checkBox5: false});
            setDisabled(true);
        }else {
            setDisabled(false);
        }
    }

    const handleChangeLanguage = (event) => {
        console.log("language change:" , event.target.value);
        setLang(event.target.value);
        localStorage.setItem('language', event.target.value);
        i18n.changeLanguage(localStorage.getItem("language"));
    };

    const { checkBox1, checkBox2, checkBox3, 
        checkBox4, checkBox5} = state;

    const routeChange = () =>{ 
        
    }

    return (
        <ThemeProvider theme={THEME}>
        {/* <br/>  */}
        <div className={classes.body}>
            <ScrollToTopOnMount/>
            
            {/* <br/>  */}

            <Box sx={{ flexGrow: 1 }} pt={3}>
            
                <Grid container pl={2} pr={2}>      
                    <Grid item md={4} xs={12}>

                    </Grid>
                    <Grid item md={4} xs={12}>
                        <br/>
                        <br/>
                        <div className="d-flex align-items-center justify-content-center">
                            <img src={poccLogo} alt="Thank you" width="30%"/>
                            
                        </div>
                        <div>
                            <br/>
                            <p style={{textAlign:'center', fontSize: '5vw', paddingLeft: '5px', fontWeight: 'bold'}}>Phuket-Isolation</p>
                        </div>

                        <div>
                            <br/>
                            <br/>
                            <p style={{textAlign:'center', fontSize: '5vw',}}>ยังไม่ครบกําหนด</p>
                            <p style={{textAlign:'center', fontSize: '5vw'}}>สอบถามข้อมูลเพิ่ม</p>
                            <p style={{textAlign:'center', fontSize: '5vw'}}>กรุณาติดต่อ 098-012-5565</p>
                        </div>
                        
                    </Grid>
                    <Grid item md={4} xs={12}>

                    </Grid>
                </Grid>
            </Box>
            {/* <br/>  */} 
        </div>
        </ThemeProvider>

    )
}

export default NoMedicalCertificate
