import React, {useEffect} from 'react';
import { useLocation } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles, withStyles } from '@mui/styles';
import {Grid, Box, Card, CardContent, CardHeader,
    Typography, MenuItem , TextField,
    FormGroup , FormControlLabel , FormControl , FormLabel, Select,
    Dialog, DialogActions, DialogContent , DialogContentText, DialogTitle, Slide, Button,
    ToggleButton, ToggleButtonGroup 
} from '@mui/material';
import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/core/ButtonUnstyled';
import { styled } from '@mui/system';
import { useHistory } from "react-router-dom";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import GifLoader from 'react-gif-loader';
import loadingGif from '../assets/loading.gif';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const THEME = createTheme({
    typography: {
     "fontFamily": `"PromptMedium","PromptSemiBold", "PromptRegular","PromptBold"`
    },
    palette: {
        primary: {
          main: '#7f51f5',
        }
      },
 });


const useStyles = makeStyles({
    body : {
        marginTop: '-3%'
    },
    headerTitle : {
        fontWeight: 'bold',
    },
    appbar: {
        alignItems: 'center',
    },
    textColor: {
        fontSize: '18px',
        color: '#7F51F5'
    },
    size12 :{
        fontSize : '13px'
    },
    redText: {
        color: 'red'
    },
    textField : {
        [`& fieldset`] :{
            borderRadius: '40px',
            borderColor: '#D2C4F8',
        }
    },
    iconSize: {
        position: 'absolute',
        paddingLeft: '10px',
        marginTop: '15px'
    }
});

const CustomButtonRoot = styled('button')(`
  background-color: #7F51F5;
  padding: 15px 50px;
  border-radius: 20px;
  color: #fff;
  font-weight: 600;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  transition: all 200ms ease;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0);
  border: none;

  &:hover {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.active} {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }
`);

const CustomButtonRootBack = styled('button')(`
  background-color: #c7c7c7;
  padding: 15px 50px;
  border-radius: 20px;
  color: #fff;
  font-weight: 600;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  transition: all 200ms ease;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0);
  border: none;

  &:hover {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.active} {
    background-color: #c7c7c7;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }
`);

const CustomButtonClose = styled('button')(`
  background-color: #7F51F5;
  padding: 15px 60px;
  border-radius: 500px;
  color: #fff;
  font-weight: 600;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  transition: all 200ms ease;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0);
  border: none;

  &:hover {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.active} {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }
`);

function CustomButton(props) {
    return <ButtonUnstyled {...props} component={CustomButtonRoot} />;
}  

function CustomCloseButton(props) {
    return <ButtonUnstyled {...props} component={CustomButtonClose} />;
}  

function CustomCloseBack(props) {
    return <ButtonUnstyled {...props} component={CustomButtonRootBack} />;
}  

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    return null;
}

function ChooseTimes() {

    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [lang, setLang] = React.useState(localStorage.getItem('language'));

    const routeChange = () =>{ 
        localStorage.removeItem("symptomsTwo");
        localStorage.removeItem("symptomsOne");
        // let path = `/daily-form?projectKey=`+ localStorage.getItem("projectKeyForm") + `&userId=` + localStorage.getItem("userId")
        //         +`&projectType=` + localStorage.getItem('project_type') + `&language=` + localStorage.getItem('language'); 
        // history.push(path);
    }

    const [value, setValue] = React.useState(0);
    const [urinateValue, setUrinateValue] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const handleChange = (event) => {
        setValue(event.target.value)
    };

    const handleChangeUrinate = (event) => {
        setUrinateValue(event.target.value);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        routeChange();
    };

    const handleClickOpenError = () => {
        setOpenError(true);
    };

    const handleCloseError = () => {
        setOpenError(false);
        routeChange();
    };

    const handleChangeLanguage = (event) => {
        console.log("language change:" , event.target.value);
        setLang(event.target.value);
        localStorage.setItem('language', event.target.value);
        i18n.changeLanguage(localStorage.getItem("language"));
    };


    const handleSubmit = (event) =>{
        event.preventDefault();
        setLoading(true);
        var details;
        var choiceData = "";
        var symptomOne = "";
        var symptomTwo = "";
        let abnormal;
        console.log("localstorage", localStorage.getItem("bodyTemp"));
        if(!localStorage.getItem("symptomsTwo")){
            localStorage.setItem("symptomsTwo", choiceData);
            symptomTwo = localStorage.getItem("symptomsTwo");
        }else {
            symptomTwo = localStorage.getItem("symptomsTwo");
        }

        if(!localStorage.getItem("symptomsOne")){
            localStorage.setItem("symptomsOne", choiceData);
            symptomOne = localStorage.getItem("symptomsOne");

        }else{
            symptomOne = localStorage.getItem("symptomsOne");
        }

        if(localStorage.getItem("currentSymptom") === 'true'){
            abnormal = true;
        }else {
            abnormal = false;
        }

        details = {
            'is_abnormal': abnormal,
            'assesment_period' : parseFloat(localStorage.getItem("period")),
            'urination_among' : urinateValue,
            'defecation_among' : value,
            'symptoms_one' : symptomOne.toString(),
            'symptoms_two' : symptomTwo.toString(),
            'oxygen_before' : localStorage.getItem("oxygenBefore") ? parseFloat(localStorage.getItem("oxygenBefore")) : 0,
            'oxygen_after' : localStorage.getItem("oxygenAfter") ? parseFloat(localStorage.getItem("oxygenAfter")) : 0,
            'heart_rate' : localStorage.getItem("heartRate") ? parseFloat(localStorage.getItem("heartRate")) : 0,
            'body_temp' : localStorage.getItem("bodyTemp")? parseFloat(localStorage.getItem("bodyTemp")) : 0,
            'blood_diastolic' : localStorage.getItem("bloodDiastolic") ? parseFloat(localStorage.getItem("bloodDiastolic")) : 0,
            "blood_systolic" : localStorage.getItem("bloodSystolic") ? parseFloat(localStorage.getItem("bloodSystolic")) : 0,
            "blood_sugar" : localStorage.getItem("bloodSugar") ? parseFloat(localStorage.getItem("bloodSugar")) : 0,
            "breath" : localStorage.getItem("breath") ? parseFloat(localStorage.getItem("breath")) : 0
        };
        
        console.log("details:", details);
        var formBody = [];
        for (var property in details) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        // console.log("formbody: ", formBody);

        const headers = {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
            'Project-Key': localStorage.getItem("projectKeyForm"),
            // 'Authorization': "Bearer " + localStorage.getItem("tokenForm") localStorage.setItem("userId"
            };
        // axios.post('/v2/home-isolation/hospital/symptom/', formBody, { headers })
        axios.post('https://api.space-trax.com/v2/home-isolation/web/' + localStorage.getItem("userId") + '/symptom/daily', formBody, { headers })
        .then(response => {
            if(response.data.data){
                setLoading(false);
                handleClickOpen();
            }else{
                setLoading(false);
                handleClickOpenError();
                console.log("error");
            }
        }).catch(error => {
            setLoading(false);
            handleClickOpenError();
            console.log("error");        });
    }

    return (
        <ThemeProvider theme={THEME}>
        <br/>
        <div className={classes.body}>
            <ScrollToTopOnMount/>
            <div style={{float : 'right', marginRight: '18px'}}>
                <ToggleButtonGroup
                    color="primary"
                    value={lang}
                    exclusive
                    onChange={handleChangeLanguage}
                    size="small"
                >
                    <ToggleButton value="en">English</ToggleButton>
                    <ToggleButton value="th">ภาษาไทย</ToggleButton>
                </ToggleButtonGroup>
            </div>
            <br/>
            
            <GifLoader className="margin0Auto"
                loading={loading}
                imageSrc={loadingGif}
                imageStyle={{marginTop:'200px', width: "20%"}}
                overlayBackground="rgba(0,0,0,0.1)"
            />
            {/* <ArrowBackIosNewIcon className={classes.iconSize} onClick={() => {history.goBack();}}/>

            <AppBar color="inherit" position="static" style={{marginTop : "-3%"}}  className={classes.appbar}>
                <Toolbar className={classes.headerTitle} >

                    DAILY FORMS
                </Toolbar>
            </AppBar> */}

            <Box sx={{ flexGrow: 1 }} pt={3}>
                <Grid container pl={2} pr={2}>
                    
                    <Grid item  md={12} xs={12}>
                        <Card sx={{ minWidth: 275 }} variant="outlined" style={{borderRadius: '15px', borderColor: '#D2C4F8'}}>
                        <CardHeader
                            subheader={t('urinationTime')}
                            subheaderTypographyProps={{color:'#fff', fontSize: '12px' }}
                            style={{ paddingLeft: '33px', backgroundColor: '#7F51F5',color: '#fff' }}

                        />
                            <CardContent>
                                <FormGroup style={{paddingLeft: '20px'}}>
                                    <FormControl component="fieldset">
                                    <FormLabel component="legend" style={{color: 'black', fontSize: '13px'}}>
                                        {t('fillFrequency')}
                                    </FormLabel>
                                    <br/>
                                    <TextField className={classes.textField}
                                     value = {urinateValue}
                                     onChange = {handleChangeUrinate}
                                     id="outlined-basic"  variant="outlined" size="small"
                                     select>
                                         <MenuItem value={0} dense>{t('0time')}</MenuItem>
                                         <MenuItem value={1} dense>{t('1time')}</MenuItem>
                                         <MenuItem value={2} dense>{t('2time')}</MenuItem>
                                         <MenuItem value={3} dense>{t('3time')}</MenuItem>
                                         <MenuItem value={4} dense>{t('4time')}</MenuItem>
                                         <MenuItem value={5} dense>{t('5time')}</MenuItem>
                                         <MenuItem value={6} dense>{t('6time')}</MenuItem>
                                         <MenuItem value={7} dense>{t('7time')}</MenuItem>
                                         <MenuItem value={8} dense>{t('8time')}</MenuItem>
                                         <MenuItem value={9} dense>{t('9time')}</MenuItem>
                                         <MenuItem value={10} dense>{t('10time')}</MenuItem>
                                         <MenuItem value={11} dense>{t('morethan10')}</MenuItem>

                                    </TextField>
                                        
                                    </FormControl>
                                </FormGroup>
                            </CardContent>
                        </Card>
                    </Grid>
                    
                </Grid>     
            </Box>

            <Box sx={{ flexGrow: 1 }} pt={3}>
                <Grid container pl={2} pr={2}>
                    
                    <Grid item  md={12} xs={12}>
                        <Card sx={{ minWidth: 275 }} variant="outlined" style={{borderRadius: '15px', borderColor: '#D2C4F8'}}>
                        <CardHeader
                            subheader={t('stoolTime')}
                            subheaderTypographyProps={{color:'#fff', fontSize: '12px' }}
                            style={{ paddingLeft: '33px', backgroundColor: '#7F51F5',color: '#fff' }}

                        />
                            <CardContent>
                                <FormGroup style={{paddingLeft: '20px'}}>
                                    <FormControl component="fieldset">
                                    <FormLabel component="legend" style={{color: 'black', fontSize: '13px'}}>
                                        {t('fillFrequency')}

                                    </FormLabel>
                                    <br/>
                                    
                                    <TextField className={classes.textField}
                                     value = {value}
                                     onChange = {handleChange}
                                     id="outlined-basic"  variant="outlined" size="small"
                                     select>
                                         <MenuItem value={0} dense>{t('0time')}</MenuItem>
                                         <MenuItem value={1} dense>{t('1time')}</MenuItem>
                                         <MenuItem value={2} dense>{t('2time')}</MenuItem>
                                         <MenuItem value={3} dense>{t('3time')}</MenuItem>
                                         <MenuItem value={4} dense>{t('4time')}</MenuItem>
                                         <MenuItem value={5} dense>{t('5time')}</MenuItem>
                                         <MenuItem value={6} dense>{t('6time')}</MenuItem>
                                         <MenuItem value={7} dense>{t('7time')}</MenuItem>
                                         <MenuItem value={8} dense>{t('8time')}</MenuItem>
                                         <MenuItem value={9} dense>{t('9time')}</MenuItem>
                                         <MenuItem value={10} dense>{t('10time')}</MenuItem>
                                         <MenuItem value={11} dense>{t('morethan10')}</MenuItem>

                                    </TextField>
                                        
                                    </FormControl>
                                </FormGroup>
                            </CardContent>
                        </Card>
                    </Grid>
                    
                </Grid>     
            </Box>

            <Box sx={{ flexGrow: 1 }} pt={3}>
            <Grid container pl={2} pr={2}>
                
                    <Grid item  md={6} xs={6} style={{float: 'left'}}>
                        <div>
                        <CustomCloseBack onClick={() => {history.goBack();}}>BACK</CustomCloseBack>
                        </div>
                    </Grid>
                    <Grid item  md={6} xs={6}>
                        <div style={{float: 'right'}}>
                            <CustomButton onClick={handleSubmit}>SEND</CustomButton>
                        </div>
                    </Grid>
            </Grid>     
            </Box>

            <Box sx={{ flexGrow: 1 }} pt={3}>
                <Grid container pl={2} pr={2} direction="column">
                        {
                            localStorage.getItem('totalPageDailyForm') === "4" ?
                                <Grid item  md={12} xs={12}>
                                    <div className={classes.appbar} style={{fontSize: '11px', color: 'grey' , fontWeight: 'bold', float: 'right'}}>
                                        { "Page |" + " 4/" + localStorage.getItem('totalPageDailyForm') }
                                    </div>
                                </Grid>
                            :
                            localStorage.getItem('totalPageDailyForm') === "6" ?
                                <Grid item  md={12} xs={12}>
                                    <div className={classes.appbar} style={{fontSize: '11px', color: 'grey' , fontWeight: 'bold', float: 'right'}}>
                                        { "Page |" + " 6/" + localStorage.getItem('totalPageDailyForm') }
                                    </div>
                                </Grid>
                            :
                            localStorage.getItem('totalPageDailyForm') === "5" ?
                                <Grid item  md={12} xs={12}>
                                    <div className={classes.appbar} style={{fontSize: '11px', color: 'grey' , fontWeight: 'bold', float: 'right'}}>
                                        { "Page |" + " 5/" + localStorage.getItem('totalPageDailyForm') }
                                    </div>
                                </Grid>
                            :
                            <Grid item  md={12} xs={12}>
                                <div className={classes.appbar} style={{fontSize: '11px', color: 'grey' , fontWeight: 'bold', float: 'right'}}>
                                    { "Page |" + " 7/" + localStorage.getItem('totalPageDailyForm') }
                                </div>
                            </Grid>

                        }
                        
                </Grid>     
            </Box>

            <br/><br/><br/>


            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{color: '#83E3BD', fontSize: "30px", alignItems: 'center'}}>
                    
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" style={{textAlign: 'center'}}>
                            <CheckCircleOutlineIcon color="success" sx={{ fontSize: 40 }}/>
                            <br/>
                             {t('complete')}
                            <br/>
                            <br/>
                              {t('thanksText')}
                            <br/>
                                {t('thanksText1')}
                </DialogContentText>
                <Box sx={{ flexGrow: 1 }} pt={3}>
                    <Grid container pl={2} pr={2} direction="column"
                        alignItems="center"
                        justify="center">
                        
                            <Grid item  md={12} xs={12}>
                                <div className={classes.appbar}>
                                    <a href={localStorage.getItem('redirectUrl')}>
                                        <CustomCloseButton onClick={handleClose}>CLOSE</CustomCloseButton>
                                    </a>
                                </div>
                            </Grid>
                    </Grid>     
                    </Box>
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>

            <Dialog
                open={openError}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseError}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{color: '#83E3BD', fontSize: "30px", alignItems: 'center'}}>
                    
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" style={{textAlign: 'center'}}>
                            <CancelIcon sx={{ fontSize: 40, color: pink[500] }}/>
                            <br/>
                                Error!
                </DialogContentText>
                <Box sx={{ flexGrow: 1 }} pt={3}>
                    <Grid container pl={2} pr={2} direction="column"
                        alignItems="center"
                        justify="center">
                        
                            <Grid item  md={12} xs={12}>
                            <a href={localStorage.getItem('redirectUrl')}>
                                <div className={classes.appbar}>
                                    <CustomCloseButton onClick={handleCloseError}>CLOSE</CustomCloseButton>
                                </div>
                            </a>
                            </Grid>
                    </Grid>     
                    </Box>
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>

        </div>

        </ThemeProvider>
    )
}

export default ChooseTimes
