import React,{useEffect} from 'react';
import { useLocation } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles, withStyles } from '@mui/styles';
import {Grid, Box, Card, CardContent, CardHeader,
    Typography,Checkbox , TextField,
    FormGroup , FormControlLabel , FormControl , FormLabel , ToggleButton, ToggleButtonGroup 
} from '@mui/material';
import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/core/ButtonUnstyled';
import { styled } from '@mui/system';
import { useHistory } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';


const THEME = createTheme({
    typography: {
     "fontFamily": `"PromptMedium","PromptSemiBold", "PromptRegular","PromptBold"`
    },
    palette: {
        primary: {
          main: '#7f51f5',
        }
      },
 });

const useStyles = makeStyles({
    body : {
        marginTop: '-3%'
    },
    headerTitle : {
        fontWeight: 'bold'
    },
    appbar: {
        alignItems: 'center',
    },
    textColor: {
        fontSize: '18px',
        color: '#7F51F5'
    },
    size12 :{
        fontSize : '13px'
    },
    redText: {
        color: 'red',
        textAlign: 'center',
        fontSize: '14px'
    },
    textField : {
        [`& fieldset`] :{
            borderRadius: '40px',
            borderColor: '#D2C4F8',
            width: '90%'
        }
    },
    iconSize: {
        position: 'absolute',
        paddingLeft: '10px',
        marginTop: '15px'
    }
});

const CustomButtonRoot = styled('button')(`
  background-color: #7F51F5;
  padding: 15px 50px;
  border-radius: 20px;
  color: #fff;
  font-weight: 600;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  transition: all 200ms ease;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0);
  border: none;

  &:hover {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.active} {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }
`);

const CustomButtonRootBack = styled('button')(`
  background-color: #c7c7c7;
  padding: 15px 50px;
  border-radius: 20px;
  color: #fff;
  font-weight: 600;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  transition: all 200ms ease;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0);
  border: none;

  &:hover {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.active} {
    background-color: #c7c7c7;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }
`);

function CustomCloseBack(props) {
    return <ButtonUnstyled {...props} component={CustomButtonRootBack} />;
}  

function CustomButton(props) {
    return <ButtonUnstyled {...props} component={CustomButtonRoot} />;
}

function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    return null;
}

function Oxygen() {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { t, i18n } = useTranslation();

    const [beforeOxygen, setBeforeOxygen] = React.useState();
    const [afterOxygen, setAfterOxygen ] = React.useState();
    const [checkInput, setCheckInput] = React.useState(false);
    const [lang, setLang] = React.useState(localStorage.getItem('language'));

    const routeChange = () =>{ 
        if(beforeOxygen){
            setCheckInput(false);
        if(beforeOxygen){
            localStorage.setItem("oxygenBefore", beforeOxygen);
        }else{
            localStorage.setItem("oxygenBefore", 0);
        }

        if(afterOxygen){
            localStorage.setItem("oxygenAfter", afterOxygen);
        }else{
            localStorage.setItem("oxygenAfter", 0);
        }
            let path = "";
            // if(localStorage.getItem("project_type") === 'isolation'){
            //     path = `/tempAndHeartRate`;
            // }
            // else { path = `/chooseNumberOfTimes`; }
            path = `/temperature`;
            history.push(path);
        }else{
            setCheckInput(true);
        }
        
    }

    const handleChangeLanguage = (event) => {
        console.log("language change:" , event.target.value);
        setLang(event.target.value);
        localStorage.setItem('language', event.target.value);
        i18n.changeLanguage(localStorage.getItem("language"));
    };

    return (
        <ThemeProvider theme={THEME}>
        <br/>
        <div className={classes.body}>
            <ScrollToTopOnMount/>
            <div style={{float : 'right', marginRight: '18px'}}>
                <ToggleButtonGroup
                    color="primary"
                    value={lang}
                    exclusive
                    onChange={handleChangeLanguage}
                    size="small"
                >
                    <ToggleButton value="en">English</ToggleButton>
                    <ToggleButton value="th">ภาษาไทย</ToggleButton>
                </ToggleButtonGroup>
            </div>
            <br/>

            <Box sx={{ flexGrow: 1 }} pt={3}>
                <Grid container pl={2} pr={2}>
                    
                    <Grid item  md={12} xs={12}>
                        <Card sx={{ minWidth: 275 }} variant="outlined" style={{borderRadius: '15px', borderColor: '#D2C4F8'}}>
                        {
                            localStorage.getItem('projectKeyForm') === 'xrsponolu71cznf0zr4pc9g3le5qghobbyigowog' 
                            || localStorage.getItem('projectKeyForm') === 'g9cntq4kj1ylkuc812i4bf4qrv4fmk90b2jpf7zl' ?
                            <CardHeader
                                subheader={t('oxygenTitle')}
                                subheaderTypographyProps={{color:'#fff', fontSize: '12px' }}
                                style={{ paddingLeft:'33px', backgroundColor: '#7F51F5',color: '#fff' }}
                            />
                            :
                            <CardHeader
                                subheader={t('beforeOxygenTitle')}
                                subheaderTypographyProps={{color:'#fff', fontSize: '12px' }}
                                style={{ paddingLeft:'33px', backgroundColor: '#7F51F5',color: '#fff' }}

                            />
                        }
                        
                            <CardContent>
                                <FormGroup style={{paddingLeft: '20px'}}>
                                    <FormControl component="fieldset">
                                    <FormLabel component="legend" style={{color: 'black', fontSize: '13px'}}>
                                        {t('fillNumber')}
                                    </FormLabel>
                                    <br/>
                                    <TextField 
                                    className={classes.textField}
                                    value = {beforeOxygen}
                                    onChange={event => setBeforeOxygen(event.target.value)}
                                     id="outlined-basic" label="" variant="outlined" size="small"
                                     type="number"
                                     InputProps={{
                                        inputProps: { 
                                            max: 100, min: 0,
                                            style: { paddingLeft: '12%' }
                                        },
                                    }}
                                    error={beforeOxygen> 100 || beforeOxygen<0}
                                    helperText={beforeOxygen> 100 || beforeOxygen<0 ? 'Please fill value between 0 and 100' : ''}
                                    />
                                        
                                    </FormControl>
                                </FormGroup>
                            </CardContent>
                        </Card>
                    </Grid>
                    
                </Grid>     
            </Box>


            <Box sx={{ flexGrow: 1 }} pt={3}>
                {
                    localStorage.getItem('projectKeyForm') === 'xrsponolu71cznf0zr4pc9g3le5qghobbyigowog' 
                    || localStorage.getItem('projectKeyForm') === 'g9cntq4kj1ylkuc812i4bf4qrv4fmk90b2jpf7zl' ?
                    <></>
                    :
                    <Grid container pl={2} pr={2}>
                        <Grid item  md={12} xs={12}>
                            <Card sx={{ minWidth: 275 }} variant="outlined" style={{borderRadius: '15px', borderColor: '#D2C4F8'}}>
                            <CardHeader
                                subheader={t('afterOxgenTitle')}
                                subheaderTypographyProps={{color:'#fff', fontSize: '12px' }}
                                style={{ paddingLeft: '33px', backgroundColor: '#7F51F5',color: '#fff' }}

                            />
                                <CardContent>
                                    <FormGroup style={{ paddingLeft: '20px' }}>
                                        <FormControl component="fieldset">
                                        <FormLabel component="legend" style={{color: 'black', fontSize: '13px'}}>
                                        {t('fillNumber')}
                                        </FormLabel>
                                        <br/>
                                        <TextField 
                                        className={classes.textField}
                                        value = {afterOxygen}
                                        onChange={event => setAfterOxygen(event.target.value)}
                                        id="outlined-basic" label="" variant="outlined" size="small"
                                        type="number"
                                        InputProps={{
                                            inputProps: { 
                                                max: 100, min: 0,
                                                style: { paddingLeft: '12%' }
                                            },
                                        }}
                                        error={afterOxygen> 100}
                                        helperText={afterOxygen > 100 ? 'Please fill value between 0 and 100' : ''}
                                        />
                                            
                                        </FormControl>
                                    </FormGroup>
                                </CardContent>
                            </Card>
                        </Grid>
                        
                    </Grid>  
                }
                   
            </Box>
            {
                checkInput ? 
                    <><br/> <p className={classes.redText}>
                        <span className="font-face-pm">
                            {t('specifyAnswer')}
                        </span>
                    </p></> 
                : <></>
            }
            <Box sx={{ flexGrow: 1 }} pt={3}>
            <Grid container pl={2} pr={2}>
                
                    <Grid item  md={6} xs={6} style={{float: 'left'}}>
                        <div>
                        <CustomCloseBack 
                            onClick={() => { 
                                if(localStorage.getItem('totalPageDailyForm') === "4" || localStorage.getItem('totalPageDailyForm') === "6"){
                                    localStorage.setItem('totalPageDailyForm', "6");
                                }else {
                                    localStorage.setItem('totalPageDailyForm', "7");
                                }
                                history.goBack();
                            }}>BACK</CustomCloseBack>
                        </div>
                    </Grid>
                    <Grid item  md={6} xs={6}>
                        <div style={{float: 'right'}}>
                            <CustomButton onClick={routeChange}>NEXT</CustomButton>
                        </div>
                    </Grid>
            </Grid>     
            </Box>

            <Box sx={{ flexGrow: 1 }} pt={3}>
                <Grid container pl={2} pr={2} direction="column">
                        {
                            localStorage.getItem('totalPageDailyForm') === "4" ?
                                <Grid item  md={12} xs={12}>
                                    <div className={classes.appbar} style={{fontSize: '11px', color: 'grey' , fontWeight: 'bold', float: 'right'}}>
                                        { "Page |" + " 2/" + localStorage.getItem('totalPageDailyForm') }
                                    </div>
                                </Grid>
                            :
                            localStorage.getItem('totalPageDailyForm') === "5" ?
                                <Grid item  md={12} xs={12}>
                                    <div className={classes.appbar} style={{fontSize: '11px', color: 'grey' , fontWeight: 'bold', float: 'right'}}>
                                        { "Page |" + " 2/" + localStorage.getItem('totalPageDailyForm') }
                                    </div>
                                </Grid>
                            :
                            <Grid item  md={12} xs={12}>
                                <div className={classes.appbar} style={{fontSize: '11px', color: 'grey' , fontWeight: 'bold', float: 'right'}}>
                                    { "Page |" + " 4/" + localStorage.getItem('totalPageDailyForm') }
                                </div>
                            </Grid>

                        }
                        
                </Grid>     
            </Box>
            <br/> 
            <br/> 
            <br/> 
        </div>
        </ThemeProvider>
    )
}

export default Oxygen
