import React, { Component } from 'react';
import './custom.css';
import ReactPaginate from 'react-paginate';

export class Pagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            data: [],
            perPage: 8,
            currentPage: 0
      };
      this.handlePageClick = this.handlePageClick.bind(this);
      console.log("props:", props);
      
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        });
        
    };

    render() {
        const {elementPerPage, totalElements, paginate, nextPage, prevPage} = this.props;
        const pageNumber = [];
        const pageCount = totalElements/elementPerPage;
        let last = 0;

        for(let i = 1; i <= Math.ceil(totalElements / elementPerPage); i++){
            pageNumber.push(i);
            last = i;
            console.log("total elements:", totalElements);
        }
        return (
            <>
            

                <nav>
                    <ul className="pagination justify-content-center">
                        <li className="page-item">
                            <a onClick={() => prevPage()} className="page-link">
                            Prev
                            </a>
                            
                        </li>
                        
                        {pageNumber.map(num=>(
                            <li className="page-item" key={num}>
                                <a onClick={()=> paginate(num)} className="page-link">{num}</a>
                            </li>
                        ))
                        }

                        <li className="page-item">
                            <a onClick={() => nextPage(last)} className="page-link">Next</a>
                        </li>
                    </ul>
                </nav>
            </>
            
        )
    }
}

export default Pagination
