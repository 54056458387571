import React, {useEffect } from 'react';
import { useLocation } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles, withStyles } from '@mui/styles';
import {Grid, Box, Card, CardContent, CardHeader,
    Typography,Checkbox , Input,
    FormGroup , FormControlLabel , FormControl , FormLabel, ToggleButton, ToggleButtonGroup 
} from '@mui/material';
import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/core/ButtonUnstyled';
import { styled } from '@mui/system';
import { useHistory } from "react-router-dom";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const THEME = createTheme({
    typography: {
     "fontFamily": `"PromptMedium","PromptSemiBold", "PromptRegular","PromptBold"`
    },palette: {
        primary: {
          main: '#7f51f5',
        }
      },
 });

const useStyles = makeStyles({
    body : {
        marginTop: '-3%'
    },
    headerTitle : {
        fontWeight: 'bold',
    },
    appbar: {
        alignItems: 'center',
    },
    textColor: {
        fontSize: '18px',
        color: '#7F51F5'
    },
    size12 :{
        fontSize : '13px'
    },
    redText: {
        color: 'red',
        textAlign: 'center',
        fontSize: '14px'
    },
    lineHeight: {
        marginTop: '-2% !important'
    },
    iconSize: {
        position: 'absolute',
        paddingLeft: '10px',
        marginTop: '15px'
    }
});

const label = { inputProps: { 'aria-label': 'Checkbox' } };

const CustomButtonRoot = styled('button')(`
  background-color: #7F51F5;
  padding: 15px 50px;
  border-radius: 20px;
  color: #fff;
  font-weight: 600;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  transition: all 200ms ease;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0);
  border: none;

  &:hover {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.active} {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }
`);

const CustomButtonRootBack = styled('button')(`
  background-color: #c7c7c7;
  padding: 15px 50px;
  border-radius: 20px;
  color: #fff;
  font-weight: 600;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  transition: all 200ms ease;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0);
  border: none;

  &:hover {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.active} {
    background-color: #c7c7c7;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }
`);

function CustomCloseBack(props) {
    return <ButtonUnstyled {...props} component={CustomButtonRootBack} />;
}  

function CustomButton(props) {
    return <ButtonUnstyled {...props} component={CustomButtonRoot} />;
}

function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    return null;
  }

function SymptomOnePage() {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const symptomChoice = location.state.symptoms;
    const periodChoice = location.state.period;
    var choiceData = "";

    

    const [state, setState] = React.useState({
        checkBox1: false,
        checkBox2: false,
        checkBox3: false,
        checkBox4: false,
        checkBox5: false,
        checkBox6: false,
        checkBox7: false,
        checkBox8: false,
        checkBox9: false,
      });
    
    const [textValue, setTextValue] = React.useState("");
    const [disabled, setDisabled] = React.useState(false);
    const [checkInput, setCheckInput] = React.useState (false);
    const [lang, setLang] = React.useState(localStorage.getItem('language'));

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
        if(state.checkBox9) {
            setTextValue("");
            setDisabled(true);
        }
        else{
            setTextValue("");
            setDisabled(false);
        }
    };

    const handleChangeText = (event) => {
        if(state.checkBox9){
            setTextValue(event.target.value);
        }else {
            setTextValue("");
        }
        
    }

    const handleChangeLanguage = (event) => {
        console.log("language change:" , event.target.value);
        setLang(event.target.value);
        localStorage.setItem('language', event.target.value);
        i18n.changeLanguage(localStorage.getItem("language"));
    };

    const { checkBox1, checkBox2, checkBox3, 
        checkBox4, checkBox5, checkBox6,
        checkBox7, checkBox8, checkBox9 } = state;
    
    const routeChange = () =>{ 
        if (checkBox1){
            // choiceData = choiceData + "มีไข้ หรือ วัดอุณหภูมิได้ 37.5 oC ขึ้นไป, ";
            choiceData = choiceData + t('fever')+ ", ";
        }
        if (checkBox2){
            choiceData = choiceData + t('cough') + ", ";
        }
        if (checkBox3){
            choiceData = choiceData + t('soreThroat') +", ";
        }
        if (checkBox4){
            choiceData = choiceData + t('lossOfSmell') + ", ";
        }
        if (checkBox5){
            choiceData = choiceData  + t('lossOfTaste') + ", ";
        }
        if (checkBox6){
            choiceData = choiceData + t('redEyes') + ", ";
        }
        if (checkBox7){
            choiceData = choiceData + t('rashSkin') + ", " ;
        }
        if (checkBox8){
            choiceData = choiceData + t('diarrhea') + ", ";
        }
        if (checkBox9){
            choiceData = choiceData + textValue;
        }

        if(!checkBox1 && !checkBox2 && !checkBox3 && !checkBox4 && !checkBox5 && !checkBox6 && !checkBox7 && !checkBox8 && !checkBox9){
            setCheckInput(true);
        }else{
            setCheckInput(false);
            localStorage.setItem("symptomsOne", choiceData);
            let path = `/symptomTwopage`; 
            history.push(path);
        }
        
    }

    return (
        <ThemeProvider theme={THEME}>
        <br/>
        <div className={classes.body}>
            <div style={{float : 'right', marginRight: '18px'}}>
                <ToggleButtonGroup
                    color="primary"
                    value={lang}
                    exclusive
                    onChange={handleChangeLanguage}
                    size="small"
                >
                    <ToggleButton value="en">English</ToggleButton>
                    <ToggleButton value="th">ภาษาไทย</ToggleButton>
                </ToggleButtonGroup>
            </div>
            <br/>
            <ScrollToTopOnMount/>

            <Box sx={{ flexGrow: 1 }} pt={3}>
                <Grid container pl={2} pr={2}>
                    
                    <Grid item  md={12} xs={12}>
                        <Card sx={{ minWidth: 275 }} variant="outlined" style={{borderRadius: '15px', borderColor: '#D2C4F8'}}>
                        <CardHeader
                            subheader={t('currentSymptoms1')}
                            subheaderTypographyProps={{color:'#fff', fontSize: '12px' }}
                            style={{ paddingLeft: '37px', backgroundColor: '#7F51F5',color: '#fff' }}

                        />
                            <CardContent>
                                <FormGroup style={{ paddingLeft:'20px' }}>
                                    <FormControl component="fieldset">
                                    <FormLabel component="legend" style={{color: 'black', fontSize: '13px'}}>
                                        {t('symptoms1Title')}
                                    </FormLabel>
                                        <FormControlLabel
                                            control={
                                            <Checkbox 
                                            {...label}
                                                sx={{
                                                color: "#7F51F5",
                                                '&.Mui-checked': {
                                                    color: "#7F51F5",
                                                },
                                                }}
                                            checked={checkBox1} onChange={handleChange} name="checkBox1" size="small"/>
                                            }
                                            label={<Typography style={{fontSize: '12px'}}>{t('fever')}</Typography>}/>
                                        
                                        <FormControlLabel
                                            control={
                                            <Checkbox 
                                            {...label}
                                            sx={{
                                            color: "#7F51F5",
                                            '&.Mui-checked': {
                                                color: "#7F51F5",
                                            },
                                            }}
                                            checked={checkBox2} onChange={handleChange} name="checkBox2" size="small" />
                                            }
                                            label={<Typography style={{fontSize: '12px'}}>{t('cough')}</Typography>}/>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                            {...label}
                                            sx={{
                                            color: "#7F51F5",
                                            '&.Mui-checked': {
                                                color: "#7F51F5",
                                            },
                                            }}
                                            checked={checkBox3} onChange={handleChange} name="checkBox3" size="small"/>
                                            }
                                            label={<Typography style={{fontSize: '12px'}}>{t('soreThroat')}</Typography>}/>
                                        
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                            {...label}
                                            sx={{
                                            color: "#7F51F5",
                                            '&.Mui-checked': {
                                                color: "#7F51F5",
                                            },
                                            }}
                                            checked={checkBox4} onChange={handleChange} name="checkBox4" size="small"/>
                                            }
                                            label={<Typography style={{fontSize: '12px'}}>{t('lossOfSmell')}</Typography>}/>

                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                            {...label}
                                            sx={{
                                            color: "#7F51F5",
                                            '&.Mui-checked': {
                                                color: "#7F51F5",
                                            },
                                            }}
                                            checked={checkBox5} onChange={handleChange} name="checkBox5" size="small"/>
                                            }
                                            label={<Typography style={{fontSize: '12px'}}>{t('lossOfTaste')}</Typography>}/>
                                        
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                            {...label}
                                            sx={{
                                            color: "#7F51F5",
                                            '&.Mui-checked': {
                                                color: "#7F51F5",
                                            },
                                            }}
                                            checked={checkBox6} onChange={handleChange} name="checkBox6" size="small"/>
                                            }
                                            label={<Typography style={{fontSize: '12px'}}>{t('redEyes')}</Typography>}/>
                                        
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                            {...label}
                                            sx={{
                                            color: "#7F51F5",
                                            '&.Mui-checked': {
                                                color: "#7F51F5",
                                            },
                                            }}
                                            checked={checkBox7} onChange={handleChange} name="checkBox7" size="small"/>
                                            }
                                            label={<Typography style={{fontSize: '12px'}}>{t('rashSkin')}</Typography>}/>
                                        
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                            {...label}
                                            sx={{
                                            color: "#7F51F5",
                                            '&.Mui-checked': {
                                                color: "#7F51F5",
                                            },
                                            }}
                                            checked={checkBox8} onChange={handleChange} name="checkBox8" size="small"/>
                                            }
                                            label={<Typography style={{fontSize: '12px'}}>{t('diarrhea')}</Typography>}/>

                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                            {...label}
                                            sx={{
                                            color: "#7F51F5",
                                            '&.Mui-checked': {
                                                color: "#7F51F5",
                                            },
                                            }}
                                            checked={checkBox9} onChange={handleChange} name="checkBox9" size="small"/>
                                            }
                                            label={<Typography style={{fontSize: '12px'}}>{t('otherSymptoms')} :</Typography>}/>

                                        <Input value={textValue} 
                                        onChange ={handleChangeText}
                                        disabled = {disabled}
                                        className={classes.lineHeight} 
                                        inputProps={{'aria-label': 'description'}} />
                                    </FormControl>
                                </FormGroup>
                            </CardContent>
                        </Card>
                    </Grid>
                    
                </Grid>     
            </Box>

            {
                checkInput ? 
                    <><br/> <p className={classes.redText}>
                        <span className="font-face-pm">
                            {t('showRequiredChoice')}
                        </span>
                    </p></> 
                : <></>
            }

            <Box sx={{ flexGrow: 1 }} pt={3}>
            <Grid container pl={2} pr={2}>
                
                    <Grid item  md={6} xs={6} style={{float: 'left'}}>
                        <div>
                        <CustomCloseBack onClick={() => {history.goBack();}}>BACK</CustomCloseBack>
                        </div>
                    </Grid>
                    <Grid item  md={6} xs={6}>
                        <div style={{float: 'right'}}>
                            <CustomButton onClick={routeChange}>NEXT</CustomButton>
                        </div>
                    </Grid>
            </Grid>     
            </Box>

            <Box sx={{ flexGrow: 1 }} pt={3}>
                <Grid container pl={2} pr={2} direction="column">
                    
                        <Grid item  md={12} xs={12}>
                            <div className={classes.appbar} style={{fontSize: '11px', color: 'grey' , fontWeight: 'bold', float: 'right'}}>
                                { "Page |" + " 2/" + localStorage.getItem('totalPageDailyForm') }
                            </div>
                        </Grid>
                </Grid>     
            </Box>

            <br/> 
            <br/> 
            <br/> 
        </div>

        </ThemeProvider>
    )
}

export default SymptomOnePage
