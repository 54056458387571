import React, {Component, useState} from 'react';
import { Modal, Button, Row, Col, Form, Table } from'react-bootstrap';
import './custom.css';
import QRpng from './QRcode.PNG';
import ReactToPrint from 'react-to-print';
import QRCode from 'qrcode.react';
import * as htmlToImage from 'html-to-image';
import { saveAs } from 'file-saver';

export class QrModal extends Component{
    constructor(props){
        super(props);
        this.wrapper = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        this.setState({
            address: event.target.value
        });
    }

    handleSubmit(event){
        event.preventDefault();
    }

    onCapture = (name) =>{
        htmlToImage.toPng(document.getElementById("savePNG"))
            .then(function (dataUrl) {
            saveAs(dataUrl, name + '_QRcode.png');
            });
    }

    render(){
        if(!this.props.show){
            return null;
        }

        let qrData;
        let name;
        let phoneNumber;
        let address;
        let hotel;
        let startDate = "";
        let birthDate = "";
        let uploadedDate = ""
        if (this.props.userlist.length !== 0){
            const isQrData = this.props.userlist.qr_code;
            name = this.props.userlist.name + ' ' + this.props.userlist.surname;
            phoneNumber = this.props.userlist.phone_number;
            address = this.props.userlist.address;
            hotel = this.props.userlist.hotel;
            if(this.props.userlist?.date_admitted){
                let splitDate = this.props.userlist?.date_admitted?.split("T");
                startDate = splitDate[0];
            }

            if(this.props.userlist?.date_of_birth){
                let splitBirthDate = this.props.userlist?.date_of_birth?.split("T");
                birthDate = splitBirthDate[0];
            }

            if(this.props.userlist?.created_at){
                let splitCreatedAt = this.props.userlist?.created_at?.split("T");
                uploadedDate = splitCreatedAt[0];
            }
            
            
            if (isQrData) {
                if(localStorage.getItem("projectKey") === 'qk6oyhtc7c9ptbnffkd90xdpji2iye11v5lhw4qy' || localStorage.getItem("projectKey") === '7zwp8sxengxpq37stixi4za1asmtm3fvx8dvkrzj' 
                    || localStorage.getItem("projectKey") === 'r65nxurwl4rnoqlbv6n8jh2gxnddamhej11t6xml' || localStorage.getItem("projectKey") === 'xrsponolu71cznf0zr4pc9g3le5qghobbyigowog'
                    || localStorage.getItem("projectKey") === 'pi7iotuircb2x90nqqizi4v91x0whrd5xgm42cro'
                ){
                    qrData = <QRCode 
                            size = {110}
                            id='qrID' value={"https://liff.line.me/1656808843-k6y2Gj7P?code="+this.props.userlist.qr_code}/>;
                }else {
                    qrData = <QRCode 
                            size = {110}
                            id='qrID' value={this.props.userlist.qr_code}/>;
                }
                
            } else {
                qrData = <img src={QRpng} />;
            }
        }

        
        
        return(
            <div ref={this.wrapper}>
                    <Modal
                            {...this.props}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            animation={false}
                        >
                            <Modal.Header>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Download QR 
                            </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="container" key={this.props.userlist.id}>
                                    
                                    <Row>
                                        <Col sm={12}>
                                            <Form onSubmit= {this.handleSubmit}>
                                                <Form.Group controlId="Address">
                                                    
                                                        <div ref={ el => (this.componentRef = el)}>
                                                        
                                                        <br/>
                                                        <Table size='sm' id="savePNG"> 
                                                        <div style={{border: "1px solid black", borderRadius: "20px", marginLeft:'13%', marginRight:'20%'}} >
                                                            <tr>
                                                                <td colSpan={2} style={{border: '0px solid white'}}>
                                                                    <br/>
                                                                    <h5 style={{paddingLeft: '30px', fontSize: '18px'}}>
                                                                        Name: {name}
                                                                    </h5>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2} style={{border: '0px solid white'}}>
                                                                    <h5 style={{paddingLeft: '30px', fontSize: '18px'}}>
                                                                        Phone Number: {phoneNumber}
                                                                    </h5> 
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="300" style={{border: '0px solid white'}}>

                                                                <h5 style={{paddingLeft: '30px' , fontSize: '18px'}}>
                                                                    Date of Birth: {
                                                                        birthDate ? birthDate : "-"
                                                                    }
                                                                </h5>
                                                                {/* <br/> */}
                                                                <h5 style={{paddingLeft: '30px' , fontSize: '18px', paddingTop: '8px'}}>
                                                                    Start Date : {
                                                                        startDate ? startDate : "-"
                                                                    }
                                                                </h5>
                                                                {/* <br/> */}

                                                                <h5 style={{paddingLeft: '30px' , fontSize: '18px', paddingTop: '8px'}}>
                                                                    Uploaded Date : {
                                                                        uploadedDate ? uploadedDate : "-"
                                                                    }
                                                                </h5>
                                                                {/* <br/> */}

                                                                <h5 style={{paddingLeft: '30px' , fontSize: '18px', paddingTop: '8px'}}>
                                                                    Hotel Name : {hotel ? hotel : "-" }
                                                                    
                                                                </h5>
                                                                <br/>
                                                                
                                                                </td>
                                                                <td style={{border: '0px solid white', marginTop: "-2%"}}>
                                                                    <br/>
                                                                    &nbsp;
                                                                    {qrData}
                                                                    <h6 style={{ fontSize:'12px', paddingLeft: '10px'}}>Code : {this.props.userlist.qr_code}</h6>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2} style={{border: '0px solid white'}}>
                                                                    <p style={{textAlign: 'center'}}>
                                                                        <b>Please return devices to hotel staff</b>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        </div>  
                                                        
                                                        </Table>
                                                        <Row>
                                                            <Col lg={6} md={12} sm={12}>
                                                                <Form.Control 
                                                                    type="hidden"
                                                                    name="userId"
                                                                    required
                                                                    readOnly
                                                                    defaultValue = {this.props.userlist.id}
                                                                    placeholder="Enter Id"
                                    
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                      
                                                </Form.Group>

                                                <Form.Group className="buttonFloatRight">
                                                    <Button  className="r14" variant="light" onClick={this.props.onHide}>Close</Button>
                                                    <Button className= 'r14' variant="success" onClick={() => this.onCapture(name)}> Save PNG </Button>

                                                    {/* <ReactToPrint
                                                        trigger={() => {
                                                            return <Button variant="success">Print</Button>;
                                                        }}
                                                        content={() => this.componentRef}
                                                        onAfterPrint ={this.props.onHide}
                                                    /> */}
                                                    
                                                </Form.Group>
                                            </Form>   
                                        </Col>
                                    </Row>
                                </div>
                            </Modal.Body>
                    </Modal>
            </div>
            
        )
    }
}