import React, {Component, useState, useEffect} from 'react';
import { Modal, Button, Row, Col, Form } from'react-bootstrap';
import './custom.css';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import ReactNotification from 'react-notifications-component';
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

export class UpdateProcessModal extends Component{
    constructor(props){
        super(props);

        this.wrapper = React.createRef();

        this.state = {
            snackbarOpen: false, 
            snackbarMsg: '',
            processStatus : ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);    
               
    }

    snackbarClose = (event) => {
        this.setState({snackbarOpen:false});
    }

    handleChange = (event) =>{
        this.setState({ processStatus: event.target.value })
    }

    

    handleSubmit(event){
        event.preventDefault();
        var details;

        if(!this.state.processStatus){
            details = {
                'process_status': (this.props.status).toString()
            };    
        }else {
            details = {
                'process_status': this.state.processStatus
            };
        }
        
        console.log("details:", details);
        var formBody = [];
        for (var property in details) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        
        

        fetch('https://api.space-trax.com/v2/home-isolation/admin/' + event.target.userId.value, {
            method: 'PATCH',
            headers: { 
                'Accept' : '*/*',
                'Content-Type' : 'application/x-www-form-urlencoded',
                'Project-Key': localStorage.getItem("projectKey"),
                'Authorization': "Bearer " + localStorage.getItem("token")
            },
            body: formBody
        })
        .then(res => res.json())
        .then((result)=>
        {   
            store.addNotification({
                title: "Success!",
                message: "Successfully Updated",
                type: "success",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    showIcon: true,
                    }
            });
            this.setState({snackbarOpen: true, snackbarMsg : 'Successfully Updated'});
            setTimeout(function(){ window.location.reload(false); }, 100);
        },
        (error)=>{
            store.addNotification({
                title: "Failed",
                message: "Update Failed",
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    showIcon: true,
                  }
            });
            this.setState({snackbarOpen: true, snackbarMsg : 'Update Failed!'});
        }

        )
    }

    closeModal(){
        setTimeout(function(){ window.location.reload(false); }, 100);
    }

    render(){
        if(!this.props.show){
            return null;
        }

        let {processStatus} = this.state;
        
        return(
            <div ref={this.wrapper}>
                <ReactNotification />
                {/* <Snackbar
                    anchorOrigin= {{vertical:'top', horizontal:'right'}}
                    open = {this.state.snackbarOpen}
                    autoHideDuration = {3000}
                    severity="success"
                    onClose= {this.snackbarClose}
                    message = {<span id="message.id">{this.state.snackbarMsg}</span>}
                    action= {
                        [
                            <IconButton
                                key = "close"
                                arial-label = "Close"
                                color = "inherit" 
                                onClick = {this.snackbarClose} 
                            >
                                x
                            </IconButton>
                        ]
                    }
                /> */}
                    <Modal
                            {...this.props}
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            animation={false}
                        >
                            <Modal.Body>
                                {/* <div className="container"> */}
                                    <Row key={Math.random()}>
                                        <Col sm={12}>
                                            <Form onSubmit= {this.handleSubmit}>
                                                <Form.Group controlId="id">
                                                    <Row>
                                                        <Col lg={12} md={12} sm={12}>
                                                            <Form.Label className="labelPaddingTop">
                                                                <b>
                                                                    Move to Done
                                                                </b>
                                                            </Form.Label>
                                                            <Form.Control 
                                        
                                                                value={processStatus}
                                                                as="select"
                                                                onChange = {this.handleChange}
                                                                >
                                                                    <option>Select Process Status</option>
                                                                    <option value="1">Pending</option>
                                                                    <option value="2">Done</option>
                                                            </Form.Control>

        
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col lg={6} md={12} sm={12}>
                                                            
                                                            <Form.Control 
                                                                type="hidden"
                                                                name="userId"
                                                                required
                                                                readOnly
                                                                defaultValue = {this.props.userid}
                                                                placeholder="Enter Id"
                                
                                                            />
                                                        </Col>

                                                    </Row>

                                                </Form.Group>

                                                <Form.Group className="buttonFloatRight">
                                                    <Button  className="r14" variant="light" onClick={()=>this.closeModal()}>Cancel</Button>
                                                    <Button variant="success" 
                                                        type="submit">Submit</Button>
                                                </Form.Group>
                                            </Form>   
                                        </Col>
                                    </Row>
                                {/* </div> */}
                            </Modal.Body>
                    </Modal>

            </div>
            
        )
    }
}