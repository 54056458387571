import React, {useEffect} from 'react';
import { useLocation } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles, withStyles } from '@mui/styles';
import {Grid, Box, Card, CardContent, CardHeader,
    Radio ,RadioGroup , Typography,
    FormGroup , FormControlLabel , FormControl , FormLabel, Select,
    Dialog, DialogActions, DialogContent , DialogContentText, DialogTitle, Slide, Button,ToggleButton, ToggleButtonGroup 
} from '@mui/material';
import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/core/ButtonUnstyled';
import { styled } from '@mui/system';
import { useHistory } from "react-router-dom";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import GifLoader from 'react-gif-loader';
import loadingGif from '../assets/loading.gif';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const THEME = createTheme({
    typography: {
     "fontFamily": `"PromptMedium","PromptSemiBold", "PromptRegular","PromptBold"`
    },
    palette: {
        primary: {
          main: '#7f51f5',
        }
      },
 });

const useStyles = makeStyles({
    body : {
        marginTop: '-3%'
    },

    headerTitle : {
        fontWeight: 'bold',
    },
    appbar: {
        alignItems: 'center',
    },
    textColor: {
        fontSize: '18px',
        color: '#7F51F5'
    },
    size12 :{
        fontSize : '13px',
        float: 'right',
        marginRight: '35%'
    },
    redText: {
        color: 'red'
    },
    textField : {
        [`& fieldset`] :{
            borderRadius: '40px',
            borderColor: '#D2C4F8',
            width: '200px',
            marginLeft: '-75%'
        }
    },
    iconSize: {
        position: 'absolute',
        paddingLeft: '10px',
        marginTop: '15px'
    }
});

const CustomButtonRoot = styled('button')(`
  background-color: #7F51F5;
  padding: 15px 50px;
  border-radius: 20px;
  color: #fff;
  font-weight: 600;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  transition: all 200ms ease;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0);
  border: none;

  &:hover {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.active} {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }
`);

const CustomButtonRootBack = styled('button')(`
  background-color: #c7c7c7;
  padding: 15px 50px;
  border-radius: 20px;
  color: #fff;
  font-weight: 600;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  transition: all 200ms ease;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0);
  border: none;

  &:hover {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.active} {
    background-color: #c7c7c7;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }
`);

const CustomButtonClose = styled('button')(`
  background-color: #7F51F5;
  padding: 15px 60px;
  border-radius: 500px;
  color: #fff;
  font-weight: 600;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  transition: all 200ms ease;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0);
  border: none;

  &:hover {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.active} {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }
`);

function CustomButton(props) {
    return <ButtonUnstyled {...props} component={CustomButtonRoot} />;
}

function CustomCloseBack(props) {
    return <ButtonUnstyled {...props} component={CustomButtonRootBack} />;
}  

function CustomCloseButton(props) {
    return <ButtonUnstyled {...props} component={CustomButtonClose} />;
}  

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const CreateLine =  () =>{
    return <hr style={{
        color: 'grey',
        backgroundColor: 'grey',
        height: '5',
        width: '100%'
    }} />;
}

function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    return null;
}

function ChooseMentalHealth() {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const [open, setOpen] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [currentSymptom, setCurretSymptom ] = React.useState('0');
    const [mentalTwo , setMentalTwo] = React.useState('0');
    const [mentalThree , setMentalThree] = React.useState('0');
    const [mentalFour , setMentalFour] = React.useState('0');
    const [mentalFive , setMentalFive] = React.useState('0');
    const [mentalSix , setMentalSix] = React.useState('0');
    const [mentalSeven , setMentalSeven] = React.useState('0');
    const [mentalEight , setMentalEight] = React.useState('0');
    const [mentalNine , setMentalNine] = React.useState('0');
    const [mentalTen , setMentalTen] = React.useState('0');
    const [loading, setLoading] = React.useState(false);
    const { t, i18n } = useTranslation();
    const [lang, setLang] = React.useState(localStorage.getItem('language'));

    const handleChangeSymptom = (event) => {
        setCurretSymptom(event.target.value);
    };

    const handleChangeMentalTwo = (event) => {
        setMentalTwo(event.target.value);
    };

    const handleChangeMentalThree = (event) => {
        setMentalThree(event.target.value);
    };

    const handleChangeMentalFour = (event) => {
        setMentalFour(event.target.value);
    };

    const handleChangeMentalFive = (event) => {
        setMentalFive(event.target.value);
    };

    const handleChangeMentalSix = (event) => {
        setMentalSix(event.target.value);
    };

    const handleChangeMentalSeven = (event) => {
        setMentalSeven(event.target.value);
    };

    const handleChangeMentalEight = (event) => {
        setMentalEight(event.target.value);
    };

    const handleChangeMentalNine = (event) => {
        setMentalNine(event.target.value);
    };

    const handleChangeMentalTen = (event) => {
        setMentalTen(event.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        routeChange();
    };

    const handleClickOpenError = () => {
        setOpenError(true);
    };

    const handleCloseError = () => {
        setOpenError(false);
        routeChange();
    };

    const controlPropsSymptoms = (item) => ({
        checked: currentSymptom === item,
        onChange: handleChangeSymptom,
        value: item,
        name: 'color-radio-button',
        inputProps: { 'aria-label': item },
    });

    const controlPropsMentalTwo = (item) => ({
        checked: mentalTwo === item,
        onChange: handleChangeMentalTwo,
        value: item,
        name: 'color-radio-button',
        inputProps: { 'aria-label': item },
    });

    const controlPropsMentalThree = (item) => ({
        checked: mentalThree === item,
        onChange: handleChangeMentalThree,
        value: item,
        name: 'color-radio-button',
        inputProps: { 'aria-label': item },
    });

    const controlPropsMentalFour = (item) => ({
        checked: mentalFour === item,
        onChange: handleChangeMentalFour,
        value: item,
        name: 'color-radio-button',
        inputProps: { 'aria-label': item },
    });

    const controlPropsMentalFive = (item) => ({
        checked: mentalFive === item,
        onChange: handleChangeMentalFive,
        value: item,
        name: 'color-radio-button',
        inputProps: { 'aria-label': item },
    });

    const controlPropsMentalSix = (item) => ({
        checked: mentalSix === item,
        onChange: handleChangeMentalSix,
        value: item,
        name: 'color-radio-button',
        inputProps: { 'aria-label': item },
    });

    const controlPropsMentalSeven = (item) => ({
        checked: mentalSeven === item,
        onChange: handleChangeMentalSeven,
        value: item,
        name: 'color-radio-button',
        inputProps: { 'aria-label': item },
    });

    const controlPropsMentalEight = (item) => ({
        checked: mentalEight === item,
        onChange: handleChangeMentalEight,
        value: item,
        name: 'color-radio-button',
        inputProps: { 'aria-label': item },
    });

    const controlPropsMentalNine = (item) => ({
        checked: mentalNine === item,
        onChange: handleChangeMentalNine,
        value: item,
        name: 'color-radio-button',
        inputProps: { 'aria-label': item },
    });

    const controlPropsMentalTen = (item) => ({
        checked: mentalTen === item,
        onChange: handleChangeMentalTen,
        value: item,
        name: 'color-radio-button',
        inputProps: { 'aria-label': item },
    });

    const handleSubmit = (event) =>{
        event.preventDefault();
        setLoading(true);
        var details;
        details = {
            'assesment_number': parseInt(localStorage.getItem("assessmentTime")),
            'symptoms_mental' : localStorage.getItem("mentalSymptom"),
            'mental_one' : parseInt(currentSymptom),
            'mental_two' : parseInt(mentalTwo),
            'mental_three' : parseInt(mentalThree),
            'mental_four' : parseInt(mentalFour),
            'mental_five' : parseInt(mentalFive),
            'mental_six' : parseInt(mentalSix),
            'mental_seven' : parseInt(mentalSeven),
            'mental_eight' : parseInt(mentalEight),
            'mental_nine' : parseInt(mentalNine),
            'mental_ten' : parseInt(mentalTen),

        };
        
        console.log("details mental:" , details);
        var formBody = [];
        for (var property in details) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        console.log("formbody mental: ", formBody);

        const headers = {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
                'Project-Key': localStorage.getItem("projectKeyForm"),
                'Authorization': "Bearer " + localStorage.getItem("tokenForm")
        };
        axios.post('https://api.space-trax.com/v2/home-isolation/hospital/symptom/mental', formBody, { headers })
        .then(response => {
            console.log(response);
            if(response.data.data){
                setLoading(false);
                handleClickOpen();
            }else{
                setLoading(false);
                handleClickOpenError();
                console.log("error");
            }
        }).catch(error => {
            setLoading(false);
            handleClickOpenError();
            console.log("error");        });
        
        // fetch('/v2/home-isolation/hospital/symptom/mental/', {
        //     method: 'POST',
        //     headers: { 
        //         'Content-Type' : 'application/x-www-form-urlencoded',
        //         'Project-Key': localStorage.getItem("projectKeyForm"),
        //         'Authorization': "Bearer " + localStorage.getItem("tokenForm")
        //     },
        //     body: formBody
        // })
        // .then(res => res.json())
        // .then((result)=>
        // {   
        //     if(result.data){
        //         setLoading(false);
        //         handleClickOpen();
        //     }else{
        //         setLoading(false);
        //         handleClickOpenError();
        //         console.log("error");
        //     }
        // },
        // (error)=>{
        //     handleClickOpenError();
        //     setLoading(false);
        //     console.log("erorr", error);
        // }

        // )
    }

    const handleChangeLanguage = (event) => {
        console.log("language change:" , event.target.value);
        setLang(event.target.value);
        localStorage.setItem('language', event.target.value);
        i18n.changeLanguage(localStorage.getItem("language"));
    };

    const routeChange = () =>{ 
        localStorage.removeItem("symptomsTwo");
        localStorage.removeItem("symptomsOne");

        // let path = `/mental-health?projectKey=` + localStorage.getItem("projectKeyForm") + `&token=` + localStorage.getItem("tokenForm"); 
        // history.push(path);
    }

    return (
        <ThemeProvider theme={THEME}>
        <br/>
        <div className={classes.body}>
            <div style={{float : 'right', marginRight: '18px'}}>
                <ToggleButtonGroup
                    color="primary"
                    value={lang}
                    exclusive
                    onChange={handleChangeLanguage}
                    size="small"
                >
                    <ToggleButton value="en">English</ToggleButton>
                    <ToggleButton value="th">ภาษาไทย</ToggleButton>
                </ToggleButtonGroup>
            </div>
            <br/>
            <ScrollToTopOnMount/>
            <GifLoader className="margin0Auto"
                loading={loading}
                imageSrc={loadingGif}
                imageStyle={{marginTop:'200px', width: "20%"}}
                overlayBackground="rgba(0,0,0,0.1)"
            />

            <Box sx={{ flexGrow: 1 }} pt={3}>
                <Grid container pl={2} pr={2}>
                    
                    <Grid item  md={12} xs={12}>
                        <Card sx={{ minWidth: 275 }} variant="outlined" style={{borderRadius: '15px', borderColor: '#D2C4F8'}}>
                        <CardHeader
                            subheader={t('assessmentAdult')}
                            subheaderTypographyProps={{color:'#fff', fontSize: '12px' }}
                            style={{ paddingLeft:'20px', backgroundColor: '#7F51F5',color: '#fff' }}

                        />
                            <CardContent>
                                <p className={classes.size12}>
                                <span className="font-face-pm">
                                    {t('categoryTitle')}
                                    <br/>
                                    <br/>
                                    0 = {t('never')} <br/>
                                    1 = {t('sometimes')} <br/>
                                    2 = {t('often')} <br/>
                                    3 = {t('frequently')} <br/>
                                </span>
                                </p>
                            </CardContent>
                        </Card>
                    </Grid>
                    
                </Grid>     
            </Box>

            <Box sx={{ flexGrow: 1 }} pt={3}>
                <Grid container pl={2} pr={2}>
                    
                    <Grid item  md={12} xs={12}>
                        <Card sx={{ minWidth: 275 }} variant="outlined" style={{borderRadius: '15px', borderColor: '#D2C4F8'}}>
                        <CardHeader
                            subheader={t('mentalTitle')}
                            subheaderTypographyProps={{color:'#fff', fontSize: '12px' }}
                            style={{ paddingLeft:'20px', backgroundColor: '#7F51F5',color: '#fff' }}

                        />
                            <CardContent>
                                <FormGroup style={{paddingLeft:'10px'}}>
                                    <FormControl component="fieldset">
                                    <FormLabel component="legend" style={{color: 'black', fontSize: '12px'}}>
                                        {t('mentalChoice1')}
                                    </FormLabel>
                                    {/* value={currentSymptom}
                                            onChange={handleChangeSymptom} */}
                                        <RadioGroup
                                            row
                                            aria-label="symptom"
                                            name="radio-symptom-group"
                                        >

                                        <FormControlLabel
                                            control={<Radio {...controlPropsSymptoms('0')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>} 
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                       0 <br/> {t('0')}
                                                    </Typography>}/>
                                        <FormControlLabel
                                            control={<Radio 
                                                {...controlPropsSymptoms('1')}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 14,
                                                    },
                                                    color: '#7F51F5',
                                                    '&.Mui-checked': {
                                                        color: '#7F51F5',
                                                    },
                                                }}
                                            />} 
                                            labelPlacement ="bottom"
                                            label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                               1 <br/> {t('1')}
                                            </Typography>}/>

                                        <FormControlLabel
                                            control={<Radio {...controlPropsSymptoms('2')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>} 
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                        2 <br/> {t('2')}
                                                    </Typography>}/>

                                        <FormControlLabel
                                            control={<Radio {...controlPropsSymptoms('3')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>}
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                        3 <br/> {t('3')} <br/> {t('31')}
                                                    </Typography>}/>
                                            
                                        </RadioGroup>

                                        <br/>
                                        <CreateLine/>
                                        <br/>
                                        
                                        <FormLabel component="legend" style={{color: 'black', fontSize: '12px'}}>
                                            {t('mentalChoice2')} 
                                         </FormLabel>
                                    
                                        <RadioGroup
                                            row
                                            aria-label="symptom"
                                            name="radio-symptom-group"
                                        >

                                        <FormControlLabel
                                            control={<Radio {...controlPropsMentalTwo('0')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>} 
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                       0 <br/> {t('0')}
                                                    </Typography>}/>
                                        <FormControlLabel
                                            control={<Radio 
                                                {...controlPropsMentalTwo('1')}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 14,
                                                    },
                                                    color: '#7F51F5',
                                                    '&.Mui-checked': {
                                                        color: '#7F51F5',
                                                    },
                                                }}
                                            />} 
                                            labelPlacement ="bottom"
                                            label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                               1 <br/> {t('1')}
                                            </Typography>}/>

                                        <FormControlLabel
                                            control={<Radio {...controlPropsMentalTwo('2')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>} 
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                        2 <br/> {t('2')}
                                                    </Typography>}/>

                                        <FormControlLabel
                                            control={<Radio {...controlPropsMentalTwo('3')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>}
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                         3 <br/> {t('3')} <br/> {t('31')}
                                                    </Typography>}/>
                                            
                                        </RadioGroup>

                                        <br/>
                                        <CreateLine/>
                                        <br/>
                                        
                                        <FormLabel component="legend" style={{color: 'black', fontSize: '12px'}}>
                                            {t('mentalChoice3')}   
                                         </FormLabel>
                                    
                                        <RadioGroup
                                            row
                                            aria-label="symptom"
                                            name="radio-symptom-group"
                                        >

                                        <FormControlLabel
                                            control={<Radio {...controlPropsMentalThree('0')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>} 
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                       0 <br/> {t('0')}
                                                    </Typography>}/>
                                        <FormControlLabel
                                            control={<Radio 
                                                {...controlPropsMentalThree('1')}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 14,
                                                    },
                                                    color: '#7F51F5',
                                                    '&.Mui-checked': {
                                                        color: '#7F51F5',
                                                    },
                                                }}
                                            />} 
                                            labelPlacement ="bottom"
                                            label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                               1 <br/> {t('1')}
                                            </Typography>}/>

                                        <FormControlLabel
                                            control={<Radio {...controlPropsMentalThree('2')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>} 
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '12px', textAlign:'center'}}>
                                                        2 <br/> {t('2')}
                                                    </Typography>}/>

                                        <FormControlLabel
                                            control={<Radio {...controlPropsMentalThree('3')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>}
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                         3 <br/> {t('3')} <br/> {t('31')}
                                                    </Typography>}/>
                                            
                                        </RadioGroup>

                                        <br/>
                                        <CreateLine/>
                                        <br/>
                                        
                                        <FormLabel component="legend" style={{color: 'black', fontSize: '12px'}}>
                                            {t('mentalChoice4')}    
                                         </FormLabel>
                                    
                                        <RadioGroup
                                            row
                                            aria-label="symptom"
                                            name="radio-symptom-group"
                                        >

                                        <FormControlLabel
                                            control={<Radio {...controlPropsMentalFour('0')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>} 
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                       0 <br/> {t('0')}
                                                    </Typography>}/>
                                        <FormControlLabel
                                            control={<Radio 
                                                {...controlPropsMentalFour('1')}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 14,
                                                    },
                                                    color: '#7F51F5',
                                                    '&.Mui-checked': {
                                                        color: '#7F51F5',
                                                    },
                                                }}
                                            />} 
                                            labelPlacement ="bottom"
                                            label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                               1 <br/> {t('1')}
                                            </Typography>}/>

                                        <FormControlLabel
                                            control={<Radio {...controlPropsMentalFour('2')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>} 
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                        2 <br/> {t('2')}
                                                    </Typography>}/>

                                        <FormControlLabel
                                            control={<Radio {...controlPropsMentalFour('3')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>}
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                         3 <br/> {t('3')} <br/> {t('31')}
                                                    </Typography>}/>
                                            
                                        </RadioGroup>

                                        <br/>
                                        <CreateLine/>
                                        <br/>
                                        
                                        <FormLabel component="legend" style={{color: 'black', fontSize: '12px'}}>
                                            {t('mentalChoice5')}
                                         </FormLabel>
                                    
                                        <RadioGroup
                                            row
                                            aria-label="symptom"
                                            name="radio-symptom-group"
                                        >

                                        <FormControlLabel
                                            control={<Radio {...controlPropsMentalFive('0')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>} 
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                       0 <br/> {t('0')}
                                                    </Typography>}/>
                                        <FormControlLabel
                                            control={<Radio 
                                                {...controlPropsMentalFive('1')}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 14,
                                                    },
                                                    color: '#7F51F5',
                                                    '&.Mui-checked': {
                                                        color: '#7F51F5',
                                                    },
                                                }}
                                            />} 
                                            labelPlacement ="bottom"
                                            label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                               1 <br/> {t('1')}
                                            </Typography>}/>

                                        <FormControlLabel
                                            control={<Radio {...controlPropsMentalFive('2')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>} 
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                        2 <br/> {t('2')}
                                                    </Typography>}/>

                                        <FormControlLabel
                                            control={<Radio {...controlPropsMentalFive('3')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>}
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                        3 <br/> {t('3')} <br/> {t('31')}
                                                    </Typography>}/>
                                            
                                        </RadioGroup>

                                        <br/>
                                        <CreateLine/>
                                        <br/>
                                        
                                        <FormLabel component="legend" style={{color: 'black', fontSize: '12px'}}>
                                            {t('mentalChoice6')} <br/>
                                            {t('mentalChoice61')}
                                         </FormLabel>
                                    
                                        <RadioGroup
                                            row
                                            aria-label="symptom"
                                            name="radio-symptom-group"
                                        >

                                        <FormControlLabel
                                            control={<Radio {...controlPropsMentalSix('0')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>} 
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                       0 <br/> {t('0')}
                                                    </Typography>}/>
                                        <FormControlLabel
                                            control={<Radio 
                                                {...controlPropsMentalSix('1')}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 14,
                                                    },
                                                    color: '#7F51F5',
                                                    '&.Mui-checked': {
                                                        color: '#7F51F5',
                                                    },
                                                }}
                                            />} 
                                            labelPlacement ="bottom"
                                            label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                               1 <br/> {t('1')}
                                            </Typography>}/>

                                        <FormControlLabel
                                            control={<Radio {...controlPropsMentalSix('2')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>} 
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                        2 <br/> {t('2')}
                                                    </Typography>}/>

                                        <FormControlLabel
                                            control={<Radio {...controlPropsMentalSix('3')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>}
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                         3 <br/> {t('3')} <br/> {t('31')}
                                                    </Typography>}/>
                                            
                                        </RadioGroup>

                                        <br/>
                                        <CreateLine/>
                                        <br/>
                                        
                                        {/* <FormLabel component="legend" style={{color: 'black', fontSize: '12px'}}>
                                            สมาธิไม่ดีเวลาทำอะไร เช่น ดูโทรทัศน์ ฟังวิทยุ<br/>
                                            หรือทำงานที่ต้องใช้ความตั้งใจ
                                         </FormLabel>
                                    
                                        <RadioGroup
                                            row
                                            aria-label="symptom"
                                            name="radio-symptom-group"
                                        >

                                        <FormControlLabel
                                            control={<Radio {...controlPropsMentalSeven('0')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>} 
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                       0 <br/> {t('0')}
                                                    </Typography>}/>
                                        <FormControlLabel
                                            control={<Radio 
                                                {...controlPropsMentalSeven('1')}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 14,
                                                    },
                                                    color: '#7F51F5',
                                                    '&.Mui-checked': {
                                                        color: '#7F51F5',
                                                    },
                                                }}
                                            />} 
                                            labelPlacement ="bottom"
                                            label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                               1 <br/> {t('1')}
                                            </Typography>}/>

                                        <FormControlLabel
                                            control={<Radio {...controlPropsMentalSeven('2')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>} 
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                        2 <br/> {t('2')}
                                                    </Typography>}/>

                                        <FormControlLabel
                                            control={<Radio {...controlPropsMentalSeven('3')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>}
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                         3 <br/> {t('3')} <br/> {t('31')}
                                                    </Typography>}/>
                                            
                                        </RadioGroup>

                                        <br/>
                                        <CreateLine/>
                                        <br/> */}
                                        
                                        <FormLabel component="legend" style={{color: 'black', fontSize: '12px'}}>
                                             {t('mentalChoice7')} <br/>
                                             {t('mentalChoice71')} 
                                         </FormLabel>
                                    
                                        <RadioGroup
                                            row
                                            aria-label="symptom"
                                            name="radio-symptom-group"
                                        >

                                        <FormControlLabel
                                            control={<Radio {...controlPropsMentalSeven('0')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>} 
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                       0 <br/> {t('0')}
                                                    </Typography>}/>
                                        <FormControlLabel
                                            control={<Radio 
                                                {...controlPropsMentalSeven('1')}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 14,
                                                    },
                                                    color: '#7F51F5',
                                                    '&.Mui-checked': {
                                                        color: '#7F51F5',
                                                    },
                                                }}
                                            />} 
                                            labelPlacement ="bottom"
                                            label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                               1 <br/> {t('1')}
                                            </Typography>}/>

                                        <FormControlLabel
                                            control={<Radio {...controlPropsMentalSeven('2')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>} 
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                        2 <br/> {t('2')}
                                                    </Typography>}/>

                                        <FormControlLabel
                                            control={<Radio {...controlPropsMentalSeven('3')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>}
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                        3 <br/> {t('3')} <br/> {t('31')}
                                                    </Typography>}/>
                                            
                                        </RadioGroup>

                                        <br/>
                                        <CreateLine/>
                                        <br/>
                                        
                                        <FormLabel component="legend" style={{color: 'black', fontSize: '12px'}}>
                                            {t('mentalChoice8')}<br/>
                                            {t('mentalChoice81')}
                                         </FormLabel>
                                    
                                        <RadioGroup
                                            row
                                            aria-label="symptom"
                                            name="radio-symptom-group"
                                        >

                                        <FormControlLabel
                                            control={<Radio {...controlPropsMentalEight('0')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>} 
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                       0 <br/> {t('0')}
                                                    </Typography>}/>
                                        <FormControlLabel
                                            control={<Radio 
                                                {...controlPropsMentalEight('1')}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 14,
                                                    },
                                                    color: '#7F51F5',
                                                    '&.Mui-checked': {
                                                        color: '#7F51F5',
                                                    },
                                                }}
                                            />} 
                                            labelPlacement ="bottom"
                                            label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                               1 <br/> {t('1')}
                                            </Typography>}/>

                                        <FormControlLabel
                                            control={<Radio {...controlPropsMentalEight('2')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>} 
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                        2 <br/> {t('2')}
                                                    </Typography>}/>

                                        <FormControlLabel
                                            control={<Radio {...controlPropsMentalEight('3')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>}
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                         3 <br/> {t('3')} <br/> {t('31')}
                                                    </Typography>}/>
                                            
                                        </RadioGroup>

                                        <br/>
                                        <CreateLine/>
                                        <br/>
                                        
                                        <FormLabel component="legend" style={{color: 'black', fontSize: '12px'}}>
                                            {t('mentalChoice9')}
                                         </FormLabel>
                                    
                                        <RadioGroup
                                            row
                                            aria-label="symptom"
                                            name="radio-symptom-group"
                                        >

                                        <FormControlLabel
                                            control={<Radio {...controlPropsMentalNine('0')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>} 
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                       0 <br/> {t('0')}
                                                    </Typography>}/>
                                        <FormControlLabel
                                            control={<Radio 
                                                {...controlPropsMentalNine('1')}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 14,
                                                    },
                                                    color: '#7F51F5',
                                                    '&.Mui-checked': {
                                                        color: '#7F51F5',
                                                    },
                                                }}
                                            />} 
                                            labelPlacement ="bottom"
                                            label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                               1 <br/> {t('1')}
                                            </Typography>}/>

                                        <FormControlLabel
                                            control={<Radio {...controlPropsMentalNine('2')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>} 
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                        2 <br/> {t('2')}
                                                    </Typography>}/>

                                        <FormControlLabel
                                            control={<Radio {...controlPropsMentalNine('3')}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 14,
                                                            },
                                                            color: '#7F51F5',
                                                            '&.Mui-checked': {
                                                                color: '#7F51F5',
                                                            },
                                                        }}/>}
                                                        labelPlacement ="bottom"
                                                        label={<Typography style={{fontSize: '10px', textAlign:'center'}}>
                                                         3 <br/> {t('3')} <br/> {t('31')}
                                                    </Typography>}/>
                                            
                                        </RadioGroup>
                                        <br/>
                                    </FormControl>
                                </FormGroup>
                            </CardContent>
                        </Card>
                    </Grid>
                    
                </Grid>     
            </Box>
            <Box sx={{ flexGrow: 1 }} pt={3}>
            <Grid container pl={2} pr={2}>
                
                    <Grid item  md={6} xs={6} style={{float: 'left'}}>
                        <div>
                        <CustomCloseBack onClick={() => {history.goBack();}}>BACK</CustomCloseBack>
                        </div>
                    </Grid>
                    <Grid item  md={6} xs={6}>
                        <div style={{float: 'right'}}>
                            <CustomButton onClick={handleSubmit}>SEND</CustomButton>
                        </div>
                    </Grid>
            </Grid>     
            </Box>
            
            <Box sx={{ flexGrow: 1 }} pt={3}>
                <Grid container pl={2} pr={2} direction="column">
                    
                        <Grid item  md={12} xs={12}>
                            <div className={classes.appbar} style={{fontSize: '11px', color: 'grey' , fontWeight: 'bold', float: 'right'}}>
                                { "Page |" + " 2/2" }
                            </div>
                        </Grid>
                </Grid>     
            </Box>  
            <br/><br/><br/>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{color: '#83E3BD', fontSize: "30px", alignItems: 'center'}}>
                    
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" style={{textAlign: 'center'}}>
                            <CheckCircleOutlineIcon color="success" sx={{ fontSize: 40 }}/>
                            <br/>
                            {t('complete')}
                            <br/>
                            <br/>
                            {t('thanksText')}
                            <br/>
                            {t('thanksText1')}
                </DialogContentText>
                <Box sx={{ flexGrow: 1 }} pt={3}>
                    <Grid container pl={2} pr={2} direction="column"
                        alignItems="center"
                        justify="center">
                        
                            <Grid item  md={12} xs={12}>
                                <div className={classes.appbar}>
                                <a href={localStorage.getItem('redirectUrl')}>
                                    <CustomCloseButton onClick={handleClose}>CLOSE</CustomCloseButton>
                                </a>
                                </div>
                            </Grid>
                    </Grid>     
                    </Box>
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>

            <Dialog
                open={openError}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseError}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{color: '#83E3BD', fontSize: "30px", alignItems: 'center'}}>
                    
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" style={{textAlign: 'center'}}>
                            <CancelIcon sx={{ fontSize: 40, color: pink[500] }}/>
                            <br/>
                                Error!
                </DialogContentText>
                <Box sx={{ flexGrow: 1 }} pt={3}>
                    <Grid container pl={2} pr={2} direction="column"
                        alignItems="center"
                        justify="center">
                        
                            <Grid item  md={12} xs={12}>
                                <div className={classes.appbar}>
                                <a href={localStorage.getItem('redirectUrl')}>
                                    <CustomCloseButton onClick={handleCloseError}>CLOSE</CustomCloseButton>
                                </a>
                                </div>
                            </Grid>
                    </Grid>     
                    </Box>
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>

            
        </div>
        </ThemeProvider>
    )
}

export default ChooseMentalHealth
