import './App.css';
import Users from './components/Users';
import Login from './container/Login';
import FirstPage from './components/FirstPage';
import SymptomsPage1 from './components/SymptomsPage1';
import SymptomsPage2 from './components/SymptomsPage2';
import OxygenConcentration from './components/OxygenConcentration';
import ChooseNumberOfTimes from './components/ChooseNumberOfTimes';
import MentalHealth from './components/MentalHealth';
import ChooseMentalHealth from './components/ChooseMentalHealth';
import Deny2ndAssessment from './components/Deny2ndAssessment';
import TempAndHeartRate from './components/TempAndHeartRate';

import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import ProtectedRoute from "./container/ProtectedRoute";
import 'bootstrap/dist/css/bootstrap.min.css';
import BloodPressure from './components/BloodPressure';
import Breath from './components/Breath';
import FormPage from './components/FormPage';
import SymptomOnePage from './components/SymptomOnePage';
import SymptomTwoPage from './components/SymptomTwoPage';
import Oxygen from './components/Oxygen';
import Temperature from './components/Temperature';
import BloodPressureAndBreath from './components/BloodPressureAndBreath';
import ChooseTimes from './components/ChooseTimes';
import MentalHealthForm from './components/MentalHealthForm';
import ChooseMental from './components/ChooseMental';
import ChooseProject from './container/ChooseProject';
import MedicalCertificate from './components/MedicalCertificate';
import NoMedicalCertificate from './components/NoMedicalCertificate';

function App() {
  return (
    <BrowserRouter>
        <Switch>
        
          <Route path="/" component={Login} exact />
          <Route path="/login" component={Login} exact />
          <Route path= "/chooseProject" component={ ChooseProject }/>
          <Route path="/homepage" component={Users} exact/>
          <Route path="/daily" component={FirstPage}/>
          <Route path="/SymptomsPage1" component={SymptomsPage1}/>
          <Route path="/SymptomsPage2" component={SymptomsPage2}/>
          <Route path="/oxygenConcentration" component={OxygenConcentration}/>
          <Route path="/chooseNumberOfTimes" component={ChooseNumberOfTimes}/>
          <Route path="/mental-health" component={MentalHealth} exact/>
          <Route path="/chooseMentalHealth" component={ChooseMentalHealth}/>
          <Route path="/deny2ndAssessment" component={ Deny2ndAssessment }/> 
          <Route path="/tempAndHeartRate" component={ TempAndHeartRate }/>
          <Route path="/bloodPressure" component={ BloodPressure } />
          <Route path="/breath" component={ Breath } />
          <Route path="/daily-form" component = { FormPage } />
          <Route path = "/symptomOnePage" component={ SymptomOnePage } />
          <Route path = '/symptomTwopage' component={ SymptomTwoPage }/>
          <Route path ="/oxygen" component={ Oxygen }/>
          <Route path = '/temperature' component={ Temperature } />
          <Route path="/blood_pressure" component={ BloodPressureAndBreath } />
          <Route path ="/chooseTimes" component={ ChooseTimes } />
          <Route path ='/mentalHealth' component={ MentalHealthForm } />
          <Route path = '/chooseMental' component={ ChooseMental }/>
          <Route path = '/medical-cert' component={ MedicalCertificate } />
          <Route path = '/noMedicalCertificate' component = { NoMedicalCertificate }/>
       </Switch>
    </BrowserRouter>
    
  );
}

export default App;


{/* <BrowserRouter>
          <Switch>
          <Route path="/">
            <Login />
          </Route>
          <ProtectedRoute path="/homepage">
            <Users />
          </ProtectedRoute>
          
          </Switch>
    </BrowserRouter> */}