import React,{useEffect} from 'react';
import { useLocation } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles, withStyles } from '@mui/styles';
import {Grid, Box, Card, CardContent, CardHeader,
    Typography,Checkbox , TextField,
    FormGroup , FormControlLabel , FormControl , FormLabel
} from '@mui/material';
import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/core/ButtonUnstyled';
import { styled } from '@mui/system';
import { useHistory } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';


const THEME = createTheme({
    typography: {
     "fontFamily": `"PromptMedium","PromptSemiBold", "PromptRegular","PromptBold"`
    }
 });

const useStyles = makeStyles({
    body : {
        marginTop: '-3%'
    },
    headerTitle : {
        fontWeight: 'bold'
    },
    appbar: {
        alignItems: 'center',
    },
    textColor: {
        fontSize: '18px',
        color: '#7F51F5'
    },
    size12 :{
        fontSize : '13px'
    },
    redText: {
        color: 'red',
        textAlign: 'center',
        fontSize: '14px'
    },
    textField : {
        [`& fieldset`] :{
            borderRadius: '40px',
            borderColor: '#D2C4F8',
            width: '90%'
        }
    },
    iconSize: {
        position: 'absolute',
        paddingLeft: '10px',
        marginTop: '15px'
    }
});

const CustomButtonRoot = styled('button')(`
  background-color: #7F51F5;
  padding: 15px 50px;
  border-radius: 20px;
  color: #fff;
  font-weight: 600;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  transition: all 200ms ease;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0);
  border: none;

  &:hover {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.active} {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }
`);

const CustomButtonRootBack = styled('button')(`
  background-color: #c7c7c7;
  padding: 15px 50px;
  border-radius: 20px;
  color: #fff;
  font-weight: 600;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  transition: all 200ms ease;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0);
  border: none;

  &:hover {
    background-color: #7F51F5;
  }

  &.${buttonUnstyledClasses.active} {
    background-color: #c7c7c7;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }
`);

function CustomCloseBack(props) {
    return <ButtonUnstyled {...props} component={CustomButtonRootBack} />;
}  

function CustomButton(props) {
    return <ButtonUnstyled {...props} component={CustomButtonRoot} />;
}

function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    return null;
}

function Breath() {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { t, i18n } = useTranslation();

    const [breath, setBreath] = React.useState();
    const [bloodSugar, setBloodSugar ] = React.useState();
    const [checkInput, setCheckInput] = React.useState(false);

    const routeChange = () =>{ 
        // if( bloodDiastolic && bloodSystolic){
            setCheckInput(false);
            localStorage.setItem("bloodSugar", bloodSugar);
            localStorage.setItem("breath", breath);
            let path = `/chooseNumberOfTimes`; 
            history.push(path);
        // }else{
        //     setCheckInput(true);
        // }
        
    }

    return (
        <ThemeProvider theme={THEME}>
        <div className={classes.body}>
            <ScrollToTopOnMount/>
            

            <Box sx={{ flexGrow: 1 }} pt={3}>
                <Grid container pl={2} pr={2}>
                    
                    <Grid item  md={12} xs={12}>
                        <Card sx={{ minWidth: 275 }} variant="outlined" style={{borderRadius: '15px', borderColor: '#D2C4F8'}}>
                        <CardHeader
                            subheader="Blood Sugar"
                            subheaderTypographyProps={{color:'#fff', fontSize: '12px' }}
                            style={{ paddingLeft:'33px', backgroundColor: '#7F51F5',color: '#fff' }}

                        />
                            <CardContent>
                                <FormGroup style={{paddingLeft: '20px'}}>
                                    <FormControl component="fieldset">
                                    <FormLabel component="legend" style={{color: 'black', fontSize: '13px'}}>
                                        {t('fillNumber')}
                                    </FormLabel>
                                    <br/>
                                    <TextField 
                                    className={classes.textField}
                                    value = {bloodSugar}
                                    onChange={event => setBloodSugar(event.target.value)}
                                     id="outlined-basic" label="" variant="outlined" size="small"
                                     type="number"
                                     InputProps={{
                                        inputProps: { 
                                            max: 100, min: 0,
                                            style: { paddingLeft: '12%' }
                                        },
                                    }}
                                    error={bloodSugar<=0}
                                    helperText={bloodSugar<=0 ? 'Please fill value greater than 0' : ''}
                                    />
                                        
                                    </FormControl>
                                </FormGroup>
                            </CardContent>
                        </Card>
                    </Grid>
                    
                </Grid>     
            </Box>


            <Box sx={{ flexGrow: 1 }} pt={3}>
                <Grid container pl={2} pr={2}>
                    
                    <Grid item  md={12} xs={12}>
                        <Card sx={{ minWidth: 275 }} variant="outlined" style={{borderRadius: '15px', borderColor: '#D2C4F8'}}>
                        <CardHeader
                            subheader="Respiration"
                            subheaderTypographyProps={{color:'#fff', fontSize: '12px' }}
                            style={{ paddingLeft: '33px', backgroundColor: '#7F51F5',color: '#fff' }}

                        />
                            <CardContent>
                                <FormGroup style={{ paddingLeft: '20px' }}>
                                    <FormControl component="fieldset">
                                    <FormLabel component="legend" style={{color: 'black', fontSize: '13px'}}>
                                        {t('fillNumber')}
                                    </FormLabel>
                                    <br/>
                                    <TextField 
                                    className={classes.textField}
                                    value = {breath}
                                    onChange={event => setBreath(event.target.value)}
                                     id="outlined-basic" label="" variant="outlined" size="small"
                                     type="number"
                                     InputProps={{
                                        inputProps: { 
                                            max: 100, min: 0,
                                            style: { paddingLeft: '12%' }
                                        },
                                    }}
                                    error={breath<=0}
                                    helperText={breath <=0 ? 'Please fill value greater than 0' : ''}
                                    />
                                        
                                    </FormControl>
                                </FormGroup>
                            </CardContent>
                        </Card>
                    </Grid>
                    
                </Grid>     
            </Box>

            
            {
                checkInput ? 
                    <><br/> <p className={classes.redText}>
                        <span className="font-face-pm">
                            Please fill the values of Blood sugar and Respiration.
                        </span>
                    </p></> 
                : <></>
            }
            <Box sx={{ flexGrow: 1 }} pt={3}>
            <Grid container pl={2} pr={2}>
                
                    <Grid item  md={6} xs={6} style={{float: 'left'}}>
                        <div>
                        <CustomCloseBack onClick={() => {history.goBack();}}>BACK</CustomCloseBack>
                        </div>
                    </Grid>
                    <Grid item  md={6} xs={6}>
                        <div style={{float: 'right'}}>
                            <CustomButton onClick={routeChange}>NEXT</CustomButton>
                        </div>
                    </Grid>
            </Grid>     
            </Box>
            <br/> 
            <br/> 
            <br/> 
        </div>
        </ThemeProvider>
    )
}

export default Breath
