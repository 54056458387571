import React, { Component } from 'react';
import OtpInput from 'react-otp-input';
import { Redirect, Switch, Route, Link } from "react-router-dom";
import { withRouter } from "react-router";
import {Button} from 'react-bootstrap';
import 
    {
        Box,
        Container,
        Grid,
        Typography,
        CardActionArea,
        Paper
    }

from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { store } from 'react-notifications-component';
import "./Login.css";

class ChooseProject extends Component {

constructor(props) {
    super(props);
    this.state = {
        projects: [],
        routeToHomepage: false
    };
}

componentDidMount(){
    if(localStorage.getItem("projectKey")){
        this.getProjects();
    }
    
}

  getProjects = () => {        
    fetch('https://api.space-trax.com/v2/dashboard/project', {
        method: 'GET',
        headers: { 
            'Accept' : '*/*',
            'Content-Type' : 'application/x-www-form-urlencoded',
            'Authorization': "Bearer " + localStorage.getItem("token"),
            'Project-Key': localStorage.getItem('projectKey')
        },
    })
    .then(res => res.json())
    .then((result)=>
    {
        let projectArray = [];
        for (let i = 0; i < result.data.length; i++ ){
            projectArray.push(result.data[i].project)
        }
        this.setState({projects: projectArray})
        // if(result.data.length === 1){
            
        // }else{
        //     this.setState({routeToHomepage: true});
        // }

    },
    (error)=>{
            store.addNotification({
                title: "Something went Wrong from getting projects ",
                message: "",
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    showIcon: true,
                  }
            });
        console.log("error");
    })
};
  

  render() {

    let {projects} = this.state;
    if(this.state.routeToHomepage){
        return <Redirect to="/homepage" />;
    }
    return (
    <>
      <Box m={5}>
        <Container maxWidth="sm">
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <h5>กรุณา เลือก Project</h5>
            </Grid>
            {(projects || []).map((project) => {
              const { name_th } = project;
              return (
                <Grid item>
                  <CardActionArea
                    onClick={() => {
                        localStorage.setItem("projectKey", project.project_key); 
                        localStorage.setItem("projectType", project.type);
                        localStorage.setItem("projectName", project.name_th);
                        if(localStorage.getItem("projectKey")){
                            this.setState({routeToHomepage: true});
                        }
                    }}
                  >
                    <Paper>
                      <Box p={6} fontSize="large" fontWeight="bold">
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          justify="space-between"
                          
                        >
                          <Grid item xs={10}>
                            <div>{name_th}</div>
                          </Grid>
                          <Grid item xs={2} >
                            <ChevronRightIcon className='floatRight'/>
                          </Grid>
                          
                        </Grid>
                      </Box>
                    </Paper>
                  </CardActionArea>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Box>
    </>
    );
  }
}

export default withRouter(ChooseProject);